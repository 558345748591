<template>
  <div class="flex items-center">
    <input
      v-if="showMaxText"
      :value="maxText"
      type="text"
      disabled
      class="!w-[50px] !h-[32px] mr-3 pr-0 pl-0 text-center appearance-none bg-mine-700 focus:outline-none text-xs px-3 py-1.5 rounded-sm"
    />
    <number-field
      v-else
      v-model="value"
      :step="step"
      class="!w-[50px] mr-3 pr-0 pl-2 text-center"
      @change="updateValue"
    />
    <div ref="slider" class="flex-1 flex justify-center relative">
      <input
        v-model="value"
        type="range"
        :min="min"
        :max="max"
        :step="step"
        id="range-slider"
        class="w-full h-[6px] bg-mine-700 rounded-lg appearance-none cursor-pointer"
      />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      modelValue: {
        type: [Number, String],
        required: true,
      },
      min: {
        type: Number,
        default: 0,
      },
      max: {
        type: Number,
        default: 100,
      },
      step: {
        type: Number,
        default: 1,
      },
      maxText: {
        type: String,
        default: null,
      },
    },
    emits: ['update:modelValue'],
    computed: {
      value: {
        get() {
          return this.modelValue
        },
        set(value) {
          this.$emit('update:modelValue', value)
        },
      },
      showMaxText() {
        return this.maxText != null && this.value == this.max
      },
    },
    methods: {
      updateValue() {
        if (this.value > this.max) {
          this.value = this.max
        }
        if (this.value < this.min) {
          this.value = this.min
        }
      },
    },
  }
</script>

<style>
  #range-slider::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    -webkit-appearance: none;
    cursor: grab;
    background: theme('colors.mine.300');
  }
  #range-slider::-webkit-slider-thumb:active {
    cursor: grabbing;
    background: theme('colors.white');
  }
</style>
