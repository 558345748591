<template>
  <Transition>
    <div
      v-if="savingMedia"
      class="fixed inset-0 z-50 min-h-screen flex items-center justify-center bg-mine-1100/70"
    >
      <div
        class="flex h-12 w-12 items-center justify-center rounded-full bg-gradient-to-tr from-mine-1100 to-cerise-600 animate-spin"
      >
        <div class="h-10 w-10 rounded-full bg-mine-1000"></div>
      </div>
    </div>
  </Transition>

  <div
    class="w-full mx-auto md:px-6 lg:px-8 xl:px-10 pt-8 flex-1 flex flex-col"
  >
    <div
      class="lg:max-w-fit lg:mx-auto md:px-6 lg:px-8 xl:px-10 flex-1 flex flex-col items-center"
    >
      <div class="px-4 lg:px-0 w-full flex items-center">
        <flat-icon-toggle
          icon="menu"
          class="lg:hidden mr-4"
          :active="!showPlaylist"
          @click="() => (showPlaylist = !showPlaylist)"
        />
        <h1 class="text-2xl font-bold text-mine-300">Graphics</h1>
        <flat-button
          v-tooltip="{
            delay: { show: 1000 },
            content:
              'Remove all uploaded Graphic image files and clear all Graphic playlists.',
          }"
          class="hidden lg:block ml-auto"
          type="dark"
          @click="() => (showClearModal = true)"
        >
          Clear All
        </flat-button>
        <flat-icon-button
          class="lg:hidden ml-auto"
          icon="broom"
          @click="() => (showClearModal = true)"
        />
      </div>

      <div class="w-full lg:flex-1 flex mt-8">
        <div
          class="w-full lg:w-[450px] bg-mine-900 rounded pb-12 flex flex-col space-y-4"
          :class="{
            'hidden lg:block': !showPlaylist,
          }"
        >
          <expansion-panel
            ref="heroCta"
            class="billboard-panel"
            title="Hero / CTA"
            :active="expandedPanel == 'heroCta'"
            @open="expandedPanel = 'heroCta'"
          >
            <div class="py-12 flex justify-center items-start space-x-4">
              <billboard-thumb
                type="Side Tower Left"
                class="w-12"
                ref="sideTowerLeft"
                :active="activeBillboard == 'sideTowerLeft'"
                :checked="hasMediaForBillboard('sideTowerLeft')"
                @on-pressed="() => (activeBillboard = 'sideTowerLeft')"
              />
              <billboard-thumb
                type="Side Tower Right"
                class="w-12"
                ref="sideTowerRight"
                :active="activeBillboard == 'sideTowerRight'"
                :checked="hasMediaForBillboard('sideTowerRight')"
                @on-pressed="() => (activeBillboard = 'sideTowerRight')"
              />
              <billboard-thumb
                type="Main Screen"
                ref="mainScreen"
                :active="activeBillboard == 'mainScreen'"
                :checked="hasMediaForBillboard('mainScreen')"
                @on-pressed="() => (activeBillboard = 'mainScreen')"
              />
            </div>
          </expansion-panel>

          <expansion-panel
            ref="socialGrid"
            class="billboard-panel"
            title="Moderation"
            :active="expandedPanel == 'socialGrid'"
            @open="expandedPanel = 'socialGrid'"
          >
            <div class="py-12 px-11 grid grid-cols-4 gap-7">
              <billboard-thumb
                type="Social Upper 1"
                ref="socialUpper1"
                :active="activeBillboard == 'socialUpper1'"
                :checked="hasMediaForBillboard('socialUpper1')"
                @on-pressed="() => (activeBillboard = 'socialUpper1')"
              />
              <billboard-thumb
                type="Social Upper 2"
                ref="socialUpper2"
                :active="activeBillboard == 'socialUpper2'"
                :checked="hasMediaForBillboard('socialUpper2')"
                @on-pressed="() => (activeBillboard = 'socialUpper2')"
              />
              <billboard-thumb
                type="Social Upper 3"
                ref="socialUpper3"
                :active="activeBillboard == 'socialUpper3'"
                :checked="hasMediaForBillboard('socialUpper3')"
                @on-pressed="() => (activeBillboard = 'socialUpper3')"
              />
              <billboard-thumb
                type="Social Upper 4"
                ref="socialUpper4"
                :active="activeBillboard == 'socialUpper4'"
                :checked="hasMediaForBillboard('socialUpper4')"
                @on-pressed="() => (activeBillboard = 'socialUpper4')"
              />
              <billboard-thumb
                type="Social Lower 1"
                ref="socialLower1"
                :active="activeBillboard == 'socialLower1'"
                :checked="hasMediaForBillboard('socialLower1')"
                @on-pressed="() => (activeBillboard = 'socialLower1')"
              />
              <billboard-thumb
                type="Social Lower 2"
                ref="socialLower2"
                :active="activeBillboard == 'socialLower2'"
                :checked="hasMediaForBillboard('socialLower2')"
                @on-pressed="() => (activeBillboard = 'socialLower2')"
              />
              <billboard-thumb
                type="Social Lower 3"
                ref="socialLower3"
                :active="activeBillboard == 'socialLower3'"
                :checked="hasMediaForBillboard('socialLower3')"
                @on-pressed="() => (activeBillboard = 'socialLower3')"
              />
              <billboard-thumb
                type="Social Lower 4"
                ref="socialLower4"
                :active="activeBillboard == 'socialLower4'"
                :checked="hasMediaForBillboard('socialLower4')"
                @on-pressed="() => (activeBillboard = 'socialLower4')"
              />
              <div class="col-span-4">
                <billboard-thumb
                  type="Social Popup"
                  ref="socialPopup"
                  :active="activeBillboard == 'socialPopup'"
                  :checked="hasMediaForBillboard('socialPopup')"
                  @on-pressed="() => (activeBillboard = 'socialPopup')"
                />
              </div>
            </div>
          </expansion-panel>

          <expansion-panel
            ref="highlightPlaylist"
            class="billboard-panel"
            title="Highlight Playlist"
            :active="expandedPanel == 'highlightPlaylist'"
            @open="expandedPanel = 'highlightPlaylist'"
          >
            <div class="py-12 flex flex-col space-y-10">
              <billboard-thumb
                type="Playlist BG"
                ref="playlistBackground"
                :active="activeBillboard == 'playlistBackground'"
                :checked="hasMediaForBillboard('playlistBackground')"
                @on-pressed="() => (activeBillboard = 'playlistBackground')"
              />
            </div>
          </expansion-panel>

          <expansion-panel
            ref="highlightPost"
            class="billboard-panel"
            title="Highlight Post"
            :active="expandedPanel == 'highlightPost'"
            @open="expandedPanel = 'highlightPost'"
          >
            <div class="py-12 px-8 grid grid-cols-3 gap-10">
              <billboard-thumb
                type="Highlight Upper 1"
                ref="highlightUpper1"
                :active="activeBillboard == 'highlightUpper1'"
                :checked="hasMediaForBillboard('highlightUpper1')"
                @on-pressed="() => (activeBillboard = 'highlightUpper1')"
              />
              <billboard-thumb
                type="Post BG"
                ref="postBackground"
                :active="activeBillboard == 'postBackground'"
                :checked="hasMediaForBillboard('postBackground')"
                @on-pressed="() => (activeBillboard = 'postBackground')"
              />
              <billboard-thumb
                type="Highlight Upper 2"
                ref="highlightUpper2"
                :active="activeBillboard == 'highlightUpper2'"
                :checked="hasMediaForBillboard('highlightUpper2')"
                @on-pressed="() => (activeBillboard = 'highlightUpper2')"
              />
              <billboard-thumb
                type="Highlight Lower 1"
                ref="highlightLower1"
                :active="activeBillboard == 'highlightLower1'"
                :checked="hasMediaForBillboard('highlightLower1')"
                @on-pressed="() => (activeBillboard = 'highlightLower1')"
              />
              <div></div>
              <billboard-thumb
                type="Highlight Lower 2"
                ref="highlightLower2"
                :active="activeBillboard == 'highlightLower2'"
                :checked="hasMediaForBillboard('highlightLower2')"
                @on-pressed="() => (activeBillboard = 'highlightLower2')"
              />
            </div>
          </expansion-panel>

          <expansion-panel
            v-if="displayEvent && displayEvent?.props.length"
            ref="eventProps"
            class="billboard-panel"
            title="Event Props"
            :active="expandedPanel == 'eventProps'"
            @open="expandedPanel = 'eventProps'"
          >
            <div class="py-12 grid grid-cols-2 gap-10">
              <div
                v-if="displayContainsProp('ribbonBanner')"
                class="col-span-2"
              >
                <billboard-thumb
                  ref="ribbonBanner"
                  type="Ribbon Banner"
                  :active="activeBillboard == 'ribbonBanner'"
                  :checked="hasMediaForBillboard('ribbonBanner')"
                  @on-pressed="() => (activeBillboard = 'ribbonBanner')"
                />
              </div>

              <billboard-thumb
                v-if="displayContainsProp('flag')"
                ref="flag"
                type="Flag"
                :active="activeBillboard == 'flag'"
                :checked="hasMediaForBillboard('flag')"
                @on-pressed="() => (activeBillboard = 'flag')"
              />

              <billboard-thumb
                v-if="displayContainsProp('basketballRack')"
                ref="basketballRack"
                type="BB Rack"
                :active="activeBillboard == 'basketballRack'"
                :checked="hasMediaForBillboard('basketballRack')"
                @on-pressed="() => (activeBillboard = 'basketballRack')"
              />

              <billboard-thumb
                v-if="displayContainsProp('ledBillboard')"
                ref="ledBillboard"
                type="LED Billboard"
                :active="activeBillboard == 'ledBillboard'"
                :checked="hasMediaForBillboard('ledBillboard')"
                @on-pressed="() => (activeBillboard = 'ledBillboard')"
              />
            </div>
          </expansion-panel>

          <expansion-panel
            ref="defaultSocialPosts"
            class="billboard-panel"
            title="Default Social Posts"
            :active="expandedPanel == 'defaultSocialPosts'"
            @open="expandedPanel = 'defaultSocialPosts'"
          >
            <div class="py-12 flex justify-center items-center space-x-10">
              <billboard-thumb
                type="Image Posts"
                ref="imagePosts"
                :active="activeBillboard == 'imagePosts'"
                :checked="hasMediaForBillboard('imagePosts')"
                @on-pressed="() => (activeBillboard = 'imagePosts')"
              />
              <billboard-thumb
                type="Video Posts"
                ref="videoPosts"
                :active="activeBillboard == 'videoPosts'"
                :checked="hasMediaForBillboard('videoPosts')"
                @on-pressed="() => (activeBillboard = 'videoPosts')"
              />
            </div>
          </expansion-panel>
        </div>

        <div
          class="w-full lg:w-[480px] xl:w-[632px] bg-mine-1000 pb-12"
          :class="{
            'hidden lg:block': showPlaylist,
          }"
        >
          <div class="flex items-center content-center px-6 py-3">
            <div class="flex-1 text-xs uppercase tracking-widest">
              {{ playlistLabel }}
            </div>
            <div class="md:flex-1 text-xs uppercase text-mine-400 text-center">
              {{ resolution }}
            </div>
            <div class="md:flex-1 text-right hidden md:block">
              <flat-button @click="addMedia">Add Media</flat-button>
            </div>
          </div>
          <div class="md:px-6 mt-2">
            <vue-draggable
              :list="playlist"
              handle=".handle"
              item-key="id"
              @end="onReorderPlaylist"
            >
              <template #item="{ element, index }">
                <div>
                  <billboard-media-item
                    :key="index"
                    :media="element"
                    :model="mediaItemModel"
                    :display-collection="displayCollection"
                    @saving="() => (savingMedia = true)"
                    @deleting="() => (savingMedia = true)"
                    @saved="(media) => onSaveMedia(index, media)"
                    @deleted="() => onDeleteMedia(index)"
                    @error="() => (savingMedia = false)"
                  />
                </div>
              </template>
            </vue-draggable>
            <div class="px-6 lg:px-0">
              <skeleton-add-button
                height-class="h-36"
                width-class="w-full"
                @click="addMedia"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Transition>
    <destructive-modal
      v-if="showClearModal"
      title="Clear All"
      body="Are you sure you want to clear all graphic playlists and media? This cannot be undone."
      :has-keyword="true"
      confirm-text="Confirm"
      keyword="CLEAR ALL"
      :loading="loading"
      @confirmed="clearPlaylist"
      @close="() => (showClearModal = false)"
    />
  </Transition>
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'

  export default {
    data() {
      return {
        loading: false,
        savingMedia: false,
        showPlaylist: true,
        displayCollection: null,
        showClearModal: false,
        activeBillboard: null,
        expandedPanel: null,
        playlist: [],
        availableEventProps: [
          'flag',
          'ribbonBanner',
          'basketballRack',
          'ledBillboard',
        ],
      }
    },
    computed: {
      ...mapState('display', ['display']),
      ...mapGetters('display', ['displayEvent']),

      playlistLabel() {
        return (
          this.activeBillboard
            .replace(/([a-z])([A-Z0-9])/g, '$1 $2')
            .replace(/\b\w/g, (l) => l.toUpperCase())
            .replaceAll('_', ' ') + ' Playlist'
        )
      },

      mediaItemModel() {
        if (
          this.activeBillboard == 'imagePosts' ||
          this.activeBillboard == 'videoPosts' ||
          this.availableEventProps.includes(this.activeBillboard)
        ) {
          return 'display'
        }
        return 'billboard'
      },

      resolution() {
        let width = null
        let height = null
        switch (this.activeBillboard) {
          case 'mainScreen':
            width = 3840
            height = 2160
            break
          case 'sideTowerLeft':
          case 'sideTowerRight':
            width = 512
            height = 2160
            break
          case 'socialUpper1':
          case 'socialUpper2':
          case 'socialUpper3':
          case 'socialUpper4':
          case 'socialLower1':
          case 'socialLower2':
          case 'socialLower3':
          case 'socialLower4':
          case 'highlightUpper1':
          case 'highlightUpper2':
          case 'highlightLower1':
          case 'highlightLower2':
            width = 960
            height = 1256
            break
          case 'playlistBackground':
          case 'postBackground':
            width = 3840
            height = 1920
            break
          case 'socialPopup':
          case 'flag':
            width = 1024
            height = 1024
            break
          case 'ribbonBanner':
            width = 3072
            height = 512
            break
          case 'basketballRack':
            width = 512
            height = 512
            break
          case 'ledBillboard':
            width = 1920
            height = 1080
            break
        }
        return width && height ? `${width} W x ${height} H` : ''
      },
    },

    watch: {
      activeBillboard(value) {
        this.setMedia(value)
        localStorage.setItem('selectedBillboard', value)

        // Always show at least 1 media item in a playlist.
        if (this.playlist.length < 1) {
          this.addMedia()
        }
      },
    },

    async created() {
      this.activeBillboard =
        localStorage.getItem('selectedBillboard') ?? 'mainScreen'
      this.setMedia(this.activeBillboard)
    },

    mounted() {
      setTimeout(() => this.setExpandedPanel(), 100)
    },

    methods: {
      ...mapMutations('billboard', ['setBillboard']),

      setMedia(billboardName) {
        this.playlist = []
        this.displayCollection = null
        if (this.availableEventProps.includes(billboardName)) {
          this.playlist = this.display.event_props[billboardName]
          this.displayCollection = billboardName
        } else if (billboardName == 'imagePosts') {
          this.playlist = this.display.default_social_posts.stills
          this.displayCollection = 'stills_default_social_posts'
        } else if (billboardName == 'videoPosts') {
          this.playlist = this.display.default_social_posts.videos
          this.displayCollection = 'videos_default_social_posts'
        } else {
          for (let i = 0; i < this.display.presentations.length; i++) {
            const presentation = this.display.presentations[i]
            for (let j = 0; j < presentation.billboards.length; j++) {
              const billboard = presentation.billboards[j]
              if (billboard.name == billboardName) {
                this.setBillboard(billboard)
                this.playlist = billboard.media
                return
              }
            }
          }
        }
      },

      hasMediaForBillboard(billboardName) {
        if (this.availableEventProps.includes(billboardName)) {
          return (
            Boolean(this.display?.event_props[billboardName]?.length) &&
            Boolean(this.display?.event_props[billboardName][0].url)
          )
        } else if (billboardName == 'imagePosts') {
          return (
            Boolean(this.display?.default_social_posts?.stills?.length) &&
            Boolean(this.display?.default_social_posts?.stills[0].url)
          )
        } else if (billboardName == 'videoPosts') {
          return (
            Boolean(this.display?.default_social_posts?.videos?.length) &&
            Boolean(this.display?.default_social_posts?.videos[0].url)
          )
        } else {
          for (let i = 0; i < this.display.presentations.length; i++) {
            const presentation = this.display.presentations[i]
            for (let j = 0; j < presentation.billboards.length; j++) {
              const billboard = presentation.billboards[j]
              if (billboard.name == billboardName) {
                if (
                  Boolean(billboard.media.length) &&
                  Boolean(billboard.media[0].url)
                ) {
                  return true
                }
              }
            }
          }
        }

        return false
      },

      setExpandedPanel() {
        let parent = this.$refs[this.activeBillboard]?.$parent
        while (parent) {
          if (parent.$el.classList.contains('billboard-panel')) {
            parent.open()
            return
          }
          parent = parent.$parent
        }
      },

      addMedia() {
        const id = Math.round(Math.random() * 10000000)
        this.playlist.push({
          id: id,
          name: '',
          temp: true,
        })
      },

      async clearPlaylist() {
        this.loading = true
        await this.$store.dispatch('display/clearMedia')
        await this.$store.dispatch('display/loadDisplay', this.display.key)
        this.showClearModal = false
        this.playlist = []
        this.loading = false
      },

      onSaveMedia(index, media) {
        if (media.looping === true) {
          this.playlist = this.playlist.map((e) => ({ ...e, looping: false }))
        }
        this.playlist[index] = media
        this.savingMedia = false
      },

      onDeleteMedia(index) {
        this.playlist.splice(index, 1)
        this.savingMedia = false
      },

      onReorderPlaylist() {
        this.$store.dispatch(
          `${this.mediaItemModel}/reorderMedia`,
          this.playlist.filter((e) => !e.temp).map((e) => e.id)
        )
      },

      displayContainsProp(type) {
        return this.displayEvent?.props?.findIndex((p) => p.type == type) != -1
      },
    },
  }
</script>
