<template>
  <div class="flex justify-center">
    <mdi-icon
      icon="drag-vertical"
      class="text-mine-500"
      :class="{
        'handle cursor-grab': canUpdate,
        'opacity-20 cursor-not-allowed': !canUpdate,
      }"
    />
    <div class="h-min relative bg-mine-800 rounded w-44">
      <div class="flex p-3 min-h-[60px]">
        <div class="flex flex-col max-w-[80%]">
          <h3 class="text-xs text-ellipsis overflow-hidden whitespace-nowrap">
            {{ post.account_name }}
          </h3>
          <h4 v-show="post.account_handle" class="text-xs text-mine-300 mt-1">@{{ post.account_handle }}</h4>
        </div>
        <div class="relative ml-auto">
          <flat-icon-button
            :disabled="!canUpdate"
            icon="dots-vertical"
            width-class="w-6"
            @click="() => (showContextMenu = true)"
          />
          <div
            v-if="showContextMenu"
            class="absolute z-10 right-0"
            v-click-away="() => (showContextMenu = false)"
          >
            <context-menu :menu="contextMenu()" />
          </div>
        </div>
      </div>
      <div
        class="relative h-32 rounded-sm overflow-hidden group bg-mine-700"
        :class="{
          'ring-2 ring-cerise': isCurrent,
          'ring-2 ring-golden': isComingUp,
          'cursor-pointer':
            post.permalink && display.presentation != 'highlightPost',
          'hover:ring-2 hover:ring-golden':
            display.presentation != 'highlightPost',
        }"
        @click="onClickPost(post)"
      >
        <div
          v-if="post.image_orientation"
          class="absolute left-0 top-0 bg-black/60 text-white text-[10px] px-1 rounded-br-sm text-center whitespace-nowrap hidden group-hover:block"
        >
          {{
            `${post.image_width} x ${post.image_height} (${post.image_orientation})`
          }}
        </div>
        <image-crop
          v-if="post.image_width > post.image_height"
          class="absolute z-[1] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          :stroke-width="3"
          :style="cropSize"
          :type="'square'"
          :color="isCurrent ? '#D52265' : isComingUp ? '#fec651' : '#e3e3e3'"
        />
        <loading-image
          v-if="post.original_image_url"
          :image-url="post.original_image_url"
          size-classes="h-32 w-full"
          background-class="object-contain"
        />
        <div
          v-else
          class="h-32 flex items-center justify-center text-xs text-mine-300"
        >
          Text only
        </div>
      </div>
      <div
        class="p-3"
        v-tooltip="{
          delay: { show: 1000 },
          content: post.message,
        }"
      >
        <h4
          class="text-xs text-mine-300 h-[70px] overflow-hidden text-ellipsis whitespace-normal"
          :style="`display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;`"
        >
          <div class="flex items-center space-x-2 mb-1">
            <img
              v-if="post.network"
              :src="`/img/${post.network}.svg`"
              :alt="post.network"
              class="h-3 w-auto"
            />
            <div class="text-mine-500 flex-1">{{ time }}</div>
            <mdi-icon
              v-if="post.media_type == 'video'"
              icon="play-circle-outline"
              size="18"
              class="w-5"
            />
          </div>
          <div>{{ post.message }}</div>
        </h4>
      </div>
    </div>
  </div>

  <Transition>
    <destructive-modal
      v-if="showDeletePostModal"
      title="Remove Post"
      body="Are you sure you want to remove this post? "
      @close="() => (showDeletePostModal = false)"
      @confirmed="onDeletePost"
    />
  </Transition>
</template>

<script>
  import moment from 'moment'
  import { mapState, mapActions, mapGetters } from 'vuex'
  import { postToApi } from '/src/utils/post_utils'

  export default {
    props: {
      index: {
        type: Number,
        required: true,
      },
      totalPosts: {
        type: Number,
        required: true,
      },
      post: {
        type: Object,
        required: true,
      },
      canUpdate: {
        type: Boolean,
        default: true,
      },
    },
    emits: ['highlight'],
    data() {
      return {
        showContextMenu: false,
        showDeletePostModal: false,
      }
    },
    computed: {
      ...mapState('display', ['display']),
      ...mapGetters('display', ['viewingDisplayAsSuperadmin']),

      time() {
        return moment(this.post.date).fromNow()
      },

      current() {
        return this.display?.presentation == 'highlightPost'
          ? this.display?.currentPost ?? 0
          : null
      },

      isCurrent() {
        return this.index === this.current
      },

      isComingUp() {
        return this.current != null ? this.index === this.current + 1 : null
      },

      cropSize() {
        let renderedWidth, renderedHeight
        let elWidth = 176
        let elHeight = 128

        // Aspect ratios
        let imgRatio =
          this.post?.image_width && this.post?.image_height
            ? this.post.image_width / this.post.image_height
            : 0
        let elRatio = elWidth / elHeight

        // Check which dimension is the limiting one
        if (elRatio > imgRatio) {
          // Div is "wider" (in terms of aspect ratio), so image is limited by height
          renderedHeight = elHeight
          renderedWidth = elHeight * imgRatio
        } else {
          // Div is "taller", so image is limited by width
          renderedWidth = elWidth
          renderedHeight = elWidth / imgRatio
        }

        return {
          width: `${renderedWidth}px`,
          height: `${renderedHeight}px`,
        }
      },
    },

    methods: {
      ...mapActions('app', ['errorAlert']),
      ...mapActions('presentation', ['deletePost']),

      contextMenu() {
        return [
          {
            show: !this.viewingDisplayAsSuperadmin,
            icon: 'plus-circle-outline',
            label: 'Add to Highlight Playlist',
            action: async () => {
              const post = await this.getTagboardPost()

              if (post) {
                await this.$store.dispatch('presentation/addPostToPlaylist', {
                  presentation: this.display.presentations.find(
                    (e) => e.name == 'highlightPlaylist'
                  ),
                  post: postToApi(post, this.display.tagboard_id),
                  successMessage: 'Added to Highlight Playlist.',
                })
              } else {
                this.errorAlert({
                  message: 'Could not fetch post from Tagboard.',
                })
              }

              this.showContextMenu = false
            },
          },
          // {
          //   show: true,
          //   icon: 'sync',
          //   label: 'Refresh Post',
          //   action: () => {
          //     console.log('refresh post', this.post)
          //   },
          // },
          {
            show: true,
            icon: 'trash-can',
            label: 'Remove Post',
            action: () => {
              this.showDeletePostModal = true
            },
          },
        ]
      },

      async onDeletePost() {
        await this.deletePost(this.post)
      },

      async getTagboardPost() {
        const data = await this.$store.dispatch('app/tbRequest', {
          method: 'getPost',
          params: {
            tagboardId: this.post.tagboard_id,
            postId: this.post.post_id,
            network: this.post.network,
          },
        })
        return data
      },

      async onClickPost(post) {
        if (this.display.presentation != 'highlightPost') {
          this.$emit('highlight', this.post)
        }
      },
    },
  }
</script>
