<template>
  <div class="relative bg-mine-700 rounded-sm transition hover:bg-mine-600">
    <select
      v-model="value"
      class="relative z-10 appearance-none bg-transparent w-full focus:outline-none text-sm pl-3 px-8 py-1.5 rounded-sm"
    >
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
    <mdi-icon
      icon="chevron-down"
      class="absolute top-1/2 right-1 z-0 -translate-y-1/2 text-neutral-300"
    />
  </div>
</template>

<script>
  export default {
    props: {
      modelValue: {
        type: [String, Number, Boolean, Date, Object, null],
        default: null,
      },
      options: {
        type: Array,
        default: () => [],
      },
    },
    emits: ['update:modelValue'],
    computed: {
      value: {
        get() {
          return this.modelValue
        },
        set(value) {
          this.$emit('update:modelValue', value)
        },
      },
    },
  }
</script>
