<template>
  <label
    class="text-mine-300 flex flex-1 items-center me-3 cursor-pointer" 
    @click.prevent="changeSelection">
      <input
        type="radio"
        :value="option.value"
        class="hidden"
      />
      <span class="me-2 flex justify-center items-center h-6 leading-6">
        <mdi-icon :icon="selected ? 'radiobox-marked' : 'radiobox-blank'" size="24" /> 
      </span>
      <span class="flex h-6 justify-center items-center leading-6">
        {{ option.label }}
      </span>
    </label>
</template>

<script>
  export default {
    props: {
      option: {
        type: Object,
        default: {
          label: '',
          value: ''
        },
      },
      selected: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      changeSelection() {
        this.$emit('change:selection', this.option.value)
      }
    }
  }
</script>
