<template>
  <label
    for="inputCheckbox"
    class="cursor-pointer group"
    :class="{ 'flex items-center': label }"
    @click="() => (value = !value)"
  >
    <div class="relative inline-block">
      <input
        v-model="value"
        type="checkbox"
        name="inputCheckbox"
        class="toggle-checkbox bg-mine-700 block w-6 h-6 rounded-sm appearance-none cursor-pointer transition focus:outline-none enabled:group-hover:bg-mine-600"
      />
      <span
        class="toggle-label hidden cursor-pointer absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 text-mine-300"
      >
        <mdi-icon icon="check" size="24" />
      </span>
    </div>

    <div
      v-if="label"
      class="text-mine-300 ml-2 text-sm transition group-hover:text-mine-100"
    >
      {{ label }}
    </div>
  </label>
</template>

<script>
  export default {
    props: {
      modelValue: {
        type: Boolean,
        required: true,
      },
      label: {
        type: [String, null],
        default: null,
      },
    },
    emits: ['update:modelValue'],
    computed: {
      value: {
        get() {
          return this.modelValue
        },
        set(value) {
          this.$emit('update:modelValue', value)
        },
      },
    },
  }
</script>

<style lang="scss">
  .toggle-checkbox:checked + .toggle-label {
    display: block;
  }
</style>
