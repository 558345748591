<template>
  <div>
    <expansion-panel
      :title="user.name"
      :active="isExpanded"
      @open="isExpanded = !isExpanded"
    >
      <vue-draggable
        class="grid gap-x-8 gap-y-14 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-6 py-10 bg-mine-1000"
        handle=".handle"
        item-key="id"
        :list="mappedDisplays"
        @end="onReorderDisplays"
      >
        <template #item="{ element }">
          <div v-if="element.type != 'add'">
            <display-preview
              :display="element"
              :can-move="true"
              @edit="
                (e) => {
                  settingsModal = true
                  displayToEdit = e
                }
              "
              @move="
                (e) => {
                  moveModal = true
                  selectedUserId = user.id
                  displayToMove = e
                }
              "
            />
          </div>
          <div v-else :class="{ 'pl-7': displaysCount > 0 }">
            <skeleton-add-button width-class="w-full" @click="createDisplay" />
          </div>
        </template>
      </vue-draggable>
    </expansion-panel>

    <Transition>
      <display-settings-modal
        v-if="settingsModal"
        :display="displayToEdit"
        :on-save="onSaveDisplay"
        :on-delete="onDeleteDisplay"
        @close="
          () => {
            settingsModal = false
            displayToEdit = {}
          }
        "
      />
    </Transition>

    <Transition>
      <content-modal
        v-if="moveModal"
        close-text="Cancel"
        :title="`Move ${displayToMove.name ?? 'Display'}`"
        @close="
          () => {
            moveModal = false
            displayToMove = {}
          }
        "
      >
        <select-field-custom
          v-model="selectedUserId"
          :options="mappedUsers"
          class="relative z-50"
        />
        <template v-slot:actions>
          <flat-button class="ml-3" @click="moveDisplay"> Move </flat-button>
        </template>
      </content-modal>
    </Transition>
  </div>
</template>

<script>
  export default {
    props: {
      user: {
        type: Object,
        required: true,
      },
      users: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        refreshKey: 0,
        loading: true,
        isExpanded: true,
        displays: [],
        settingsModal: false,
        displayToEdit: {},
        moveModal: false,
        displayToMove: {},
        selectedUserId: null,
      }
    },

    computed: {
      displaysCount() {
        return this.displays?.filter((e) => !!e.id)?.length
      },
      mappedDisplays() {
        this.refreshKey
        return [...this.displays, { type: 'add' }]
      },
      mappedUsers() {
        return this.users.map((e) => ({ value: e.id, label: e.name }))
      },
    },

    created() {
      this.selectedUserId = this.user.id
      this.loadDisplays()
    },

    methods: {
      async loadDisplays() {
        this.loading = true
        const res = await this.$store.dispatch('app/apiRequest', {
          method: 'get',
          endpoint: `/v1/users/${this.user.id}/displays`,
        })
        if (res && res.status == 200) {
          this.displays = res.data.data
          this.subscribeToEvents()
        }
        this.loading = false
      },

      async onReorderDisplays() {
        await this.$store.dispatch(
          'display/reorder',
          this.mappedDisplays.filter((e) => !!e.id).map((e) => e.id)
        )
        await this.loadDisplays()
      },

      async onSaveDisplay() {
        await this.loadDisplays()
      },

      async onDeleteDisplay() {
        await this.loadDisplays()
      },

      async createDisplay() {
        const res = await this.$store.dispatch('app/apiRequest', {
          method: 'post',
          endpoint: `/v1/users/${this.user.id}/displays`,
        })

        if (res.status == 201 && res.data?.data) {
          await this.loadDisplays()
        }
      },

      async moveDisplay() {
        const res = await this.$store.dispatch('app/apiRequest', {
          method: 'post',
          endpoint: `/v1/users/${this.user.id}/displays/${this.displayToMove.id}/move`,
          data: { new_user_id: this.selectedUserId },
        })
        if (res.status == 200) {
          this.$emit('displayMoved', {
            display: this.displayToMove.id,
            newUser: this.selectedUserId,
          })
          this.moveModal = false
          this.displayToMove = {}
        }
      },

      subscribeToEvents() {
        this.displays.forEach((display) => this.subscribeToDisplay(display))
      },

      subscribeToDisplay(display) {
        const channel = 'display.' + display.key
        const listen = (event, callback = null) =>
          window.SAEcho.channel(channel).listen(event, (e) => {
            console.info(`SA.display.${display.key}.${event}`, e)
            if (callback != null) {
              callback(e)
            }
          })
        if (!window.SAEcho.connector.channels.hasOwnProperty(channel)) {
          listen('DisplayUpdated', async (e) => {
            await this.loadDisplays()
          })
          listen('DisplayConnected', (e) => {
            this.setDisplayConnection(display.id, true)
          })
          listen('DisplayDisconnected', (e) => {
            this.setDisplayConnection(display.id, false)
          })
        }
      },

      setDisplayConnection(id, connection) {
        const display = this.displays.find((d) => d.id == id)
        display.is_connected = connection
        this.refreshKey++
      },
    },
  }
</script>
