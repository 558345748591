<template>
  <div class="flex space-x-4">
    <div v-for="(option, index) in options" :key="index"
      class="min-w-[90px] flex items-center justify-center h-8 px-3 uppercase text-xs cursor-pointer leading-none" :class="{
        'bg-cerise-500/10 text-cerise-500': value == option.value,
        'bg-mine-700': value != option.value,
      }" v-tooltip="{
  delay: { show: 1000 },
  content: option.help,
  placement: 'bottom',
}" @click="() => (value = option.value)">
      {{ option.label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number, Boolean, Date, Object, null],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
}
</script>
