<template>
  <div
    class="w-full mx-auto md:px-6 lg:px-8 xl:px-10 pt-8 flex-1 flex flex-col max-w-screen-2xl"
  >

    <div class="px-4 lg:px-0 w-full flex items-center h-9">
      <h1 class="text-2xl font-bold text-mine-300">Moderation</h1>
      <flat-button v-if="getFilters.approved === 1" class="hidden lg:block ml-auto" type="dark" @click="() => (showClearModal = true)">
        Clear All
      </flat-button>
      <flat-icon-button
        v-if="getFilters.approved === 1"
        class="lg:hidden ml-auto"
        icon="broom"
        type="dark"
        @click="() => (showClearModal = true)"
      />
    </div>

    <div class="flex-1 flex flex-col mt-8">
      <div class="bg-mine-800 rounded flex flex-wrap items-center justify-between sm:justify-start py-2 gap-y-2">
        <div class="flex w-full sm:w-auto">
          <dropdown-selector @update:filters="handleFiltersUpdate"></dropdown-selector>
          <flat-icon-button
            v-if="isRefreshVisible()"
            v-tooltip="{
              delay: { show: 1000 },
              content: 'Reload the Moderation Tagboard.',
            }"
            icon="sync"
            class="mx-2"
            @click="onSyncPosts"
          />
          <flat-icon-button
            v-if="isCaptureRefreshVisible()"
            v-tooltip="{
              delay: { show: 1000 },
              content: 'Reload the Moderation Capture.',
            }"
            icon="sync"
            class="mx-2"
            @click="refreshPage"
          />
          <flat-icon-button
            :icon="search?.length ? 'backspace' : 'magnify'"
            class="mr-2 lg:hidden"
            @click="search?.length ? (search = '') : (showSearchModal = true)"
          />
        </div>

        <div
          class="flex-1 hidden lg:flex justify-center items-center text-center mx-2 text-mine-300 text-xs uppercase tracking-widest whitespace-nowrap"
        >
          <mdi-icon
            icon="all-inclusive"
            size="16"
            v-if="display.social_grid_cycles == 0"
            class="mr-3"
          />
          <div v-if="display.social_grid_cycles > 1" class="mr-4">
            <span class="font-bold text-white">{{ socialGridIndex }}</span> /
            {{ display.social_grid_cycles }}
          </div>
          <div v-show="presentation?.layout">Next Layout: {{ presentation?.layout }}</div>
        </div>
        <div class="flex ml-0 md2:ml-auto">
          <approved-radio
            v-show="!viewingDisplayAsSuperadmin"
            @change:filters="handleFiltersUpdate" 
            :options="approvedOptions" 
            :selectedOption="getFilters.approved" />
        </div>
        <div class="hidden lg:block relative w-48 mx-2">
          <text-field
            v-model="search"
            placeholder="Filter posts by keyword"
            class="pr-7"
          />
          <button
            v-if="search.length"
            class="absolute right-1 top-[6px] w-5 h-5 flex items-center justify-center text-white whitespace-nowrap bg-mine-800/60 rounded-sm transition hover:bg-mine-900 active:bg-mine-1000 focus:outline-none appearance-none"
            @click="() => (search = '')"
          >
            <mdi-icon icon="close" size="18" />
          </button>
        </div>
      </div>

      <div
        v-show="showList()"
        class="flex-1 flex flex-col bg-mine-1000 py-12 lg:py-20 px-4 md:px-8 lg:px-12"
      >
        <p
          v-if="!getPosts.length"
          class="flex-1 text-sm text-center flex items-center justify-center"
        >
          <template v-if="!tagboards.length">
            <div>
              Please create a Tagboard using your&nbsp;
              <a href="http://tagboard.com" target="_blank" class="text-cerise"
                >Tagboard account</a
              >.
            </div>
          </template>
          <template v-else-if="!selectedTagboard">
            Please select a Tagboard using the select field above.
          </template>
          <template v-else>
            Please add social posts to this Tagboard using your&nbsp;
            <a href="http://tagboard.com" target="_blank" class="text-cerise"
              >Tagboard account</a
            >
          </template>
        </p>
        <vue-draggable
          v-show="getPosts.length"
          :list="getPosts"
          handle=".handle"
          item-key="id"
          class="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-2 gap-y-10 sm:gap-y-14"
          ref="postsContainer"
          style="overflow-y: auto; height: 100%"
          @end="onReorderPosts"
        >
          <template #item="{ element, index }">
            <div>
              <social-grid-item
                :post="element"
                :draggable="getFilters.approved == 1"
                :presentation="presentation"
                @change:approvedState="changeApprovedState"
                @delete:socialGridPost="removeDeletedPost"
              />
            </div>
          </template>
        </vue-draggable>
        <div ref="loader" style="height: 1px"></div>
      </div>
      <div v-if="loading" class="flex items-center justify-center bg-mine-1000 flex-1">
        <loading-spinner
          color-classes="from-transparent to-cerise-600"
          background-class="bg-mine-1000"
        />
      </div>
      <div v-if="!loading" class="flex items-center justify-center bg-mine-1000 flex-1">
        <p
          v-if="!getPosts.length"
          class="flex-1 mt-10 text-sm text-center flex items-center justify-center"
        >
        No post found
        </p>
      </div>
    </div>
  </div>

  <Transition>
    <content-modal
      v-if="showSearchModal"
      title="Search"
      @close="() => (showSearchModal = false)"
    >
      <text-field v-model="search" placeholder="Filter posts by keyword" />
    </content-modal>
  </Transition>

  <Transition>
    <destructive-modal
      v-if="showClearModal"
      title="Clear Moderation"
      body="Are you sure you want to clear all posts from this playlist? They will not be deleted from the Tagboard or the Vixi Capture."
      confirm-text="Confirm"
      :loading="isClearingPosts"
      @confirmed="onClearPosts"
      @close="() => (showClearModal = false)"
    />
  </Transition>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex"
import { postToApi } from "/src/utils/post_utils"

export default {
  data() {
    return {
      loading: true,
      search: "",
      tagboards: [],
      showClearModal: false,
      showSearchModal: false,
      showRefresh: false,
      showCaptureRefresh: false,
      isClearingPosts: false,
      post: {},
      approvedOptions: [
        { label: "All", value: "all" },
        { label: "Approved", value: 1 },
        { label: "Unapproved", value: 0 },
      ]
    }
  },
  computed: {
    ...mapState("auth", ["apiUser"]),
    ...mapState("display", ["display"]),
    ...mapState("presentation", ["presentation", "socialGridIndex"]),
    ...mapState("auth", ["tagboardAccess", "captureAccess", "tagboardLoggedin"]),
    ...mapGetters("display", ["viewingDisplayAsSuperadmin"]),
    ...mapGetters("post", ["getPosts", "getPage", "getLoadedPages", "getNextPageUrl", "getFilters"]),

    mappedTagboards() {
      return this.tagboards.map((e) => ({
        value: e._id,
        label: e.name,
      }))
    },
  },

  watch: {
    search(newValue) {
      if (newValue.length >= 3 || newValue.length === 0) {
        this.search = newValue
        this.resetPagination()
        this.$store.commit('post/setFilters', { keyword: this.search })
        this.loadPage()
      }
    },
  },

  async mounted() {
    this.setPresentation(this.display.presentations.find((e) => e.name == "socialGrid"))
    window.addEventListener("scroll", this.handleScroll)
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll)
  },

  created() {
    this.$store.commit("post/reset")
  },
  
  methods: {
    ...mapMutations("presentation", ["setPresentation"]),

    async onSelectTagboard(tagboardId, showMessage = false) {
      this.loading = true

      await this.$store.dispatch("display/update", {
        key: this.display.key,
        tagboard_id: tagboardId,
      })

      await this.$store.dispatch("display/subscribeToTagboard", { tagboardId })

      const posts = await this.getPostsFromTagboard()
      if (!posts) {
        return false
      }

      if (showMessage) {
        const tagboardName = this.tagboards.find((e) => e._id == tagboardId)
          ?.name

        if (tagboardName) {
          this.$store.dispatch("app/updateAlert", {
            message: `Switched Moderation Tagboard to "${tagboardName}".`,
            timeout: 5000,
          })
        }
      }

      await this.$store.dispatch("presentation/savePosts", {
        presentation: this.presentation,
        refresh: true,
        posts: posts.map((e) => postToApi(e, tagboardId)),
      })

      this.loading = false
      return true
    },

    async getPostsFromTagboard() {
      const data = await this.$store.dispatch("app/tbRequest", {
        method: "getPosts",
        params: this.selectedTagboard,
      })
      return data?.posts
    },

    async onReorderPosts() {
      await this.$store.dispatch(
        "presentation/reorderPosts",
        this.getPosts.map((e) => e.post_presentation_id)
      )
    },

    async onClearPosts() {
      this.isClearingPosts = true
      const result = await this.$store.dispatch("presentation/clearPosts", {
        successMessage: "Moderation cleared.",
      })
      if (result && this.getFilters.approved === 1) {
        this.$store.commit('post/clearPosts');
      }
      await this.$store.dispatch("display/update", {
        key: this.display.key,
        tagboard_id: "",
      })
      this.showClearModal = false
      this.selectedTagboard = null
      this.isClearingPosts = false
    },

    async onSyncPosts(showMessage = true) {
      if (this.selectedTagboard) {
        const res = await this.onSelectTagboard(this.selectedTagboard)
        if (res && showMessage) {
          this.$store.dispatch("app/updateAlert", {
            message: "Tagboard reloaded.",
          })
        }
      }
    },

    findEnity(source) {
      return this.$store.dispatch('sourceEntity/findSourceEntity', { source, entityId: this.getFilters.sourceEntityId})
    },

    isRefreshVisible() {
      return !this.viewingDisplayAsSuperadmin && this.showRefresh
    },

    isCaptureRefreshVisible() {
      return !this.viewingDisplayAsSuperadmin && this.showCaptureRefresh
    },

    async getSelectedEntity(source) {
      let entity = await this.findEnity(source)

      if (!entity) {
        const sourceEntities = this.$store.getters['sourceEntity/getSourceEntities']
  
        if (sourceEntities.length === 1) {
          const sourceEntity = sourceEntities.find(se => se.source === source)
          if (sourceEntity) {
            entity = sourceEntity
          }
        }
      }

      return entity;
    },

    async toggleRefresh() {
      let entity = await this.getSelectedEntity('tagboard')
      if (entity) {
        this.showRefresh = true
        this.showCaptureRefresh = false
        this.selectedTagboard = entity.value
        await this.onSyncPosts(false)
      } else {
        this.showRefresh = false
        this.selectedTagboard = null
      }
    },

    async toggleCaptureRefresh() {
      let entity = await this.getSelectedEntity('capture')
      if (entity || this.getFilters.sourceEntityId === 'all') {
        this.showCaptureRefresh = true
        this.showRefresh = false
        this.selectedTagboard = entity?.value || 'all'
      } else {
        this.showCaptureRefresh = false
      }
    },

    async handleFiltersUpdate(filters) {
      this.resetPagination()
      if (this.viewingDisplayAsSuperadmin) {
        filters.approved = 1
      }
      this.$store.commit('post/setFilters', { ...filters, presentationId: this.presentation.id })
      await this.toggleRefresh()
      await this.toggleCaptureRefresh()
      this.loadPage()
    },

    async refreshPage() {
      this.resetPagination()
      this.loadPage();
    },

    async loadPage() {
      if (this.viewingDisplayAsSuperadmin) {
        this.loading = true
        await this.$store.dispatch('display/markModerationPostsByDisplay')
        this.loading = false
        return
      }
      // Check if this page has already been loaded
      if (this.getLoadedPages.includes(this.getPage)) {
        return
      }

      try {
        this.loading = true
        await this.$store.dispatch("post/loadPosts", true)
      } finally {
        this.loading = false
      }
    },

    async loadMorePosts() {
      if (this.getNextPageUrl == null || this.loading) {
        return
      }

      this.$store.commit("post/setNextPage")

      await this.loadPage()
    },

    handleScroll() {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 && !this.loading && this.getFilters.approved !== 1) {
        this.loadMorePosts()
      }
    },

    resetPagination() {
      this.$store.commit('post/resetPagination')
    },

    showList() {
      return this.getPosts.length && (!this.tagboardAccess || this.tagboardLoggedin)
    },

    changeApprovedState(post) {
      if (this.getFilters.approved !== undefined && this.getFilters.approved !== 'all') {
        this.$store.commit('post/removePost', post.id)
      }
    },

    removeDeletedPost(post) {
      this.$store.commit('post/removePost', post.id)
    },

    clearPosts() {
      this.$store.commit('post/clearPosts')
    }
    
  },
}
</script>
