<template>
  <div
    class="fixed z-10 bottom-0 right-0 md:bottom-4 md:right-5"
    :class="{
      'w-full md:w-auto': active,
    }"
  >
    <div class="bg-mine-900 rounded overflow-clip shadow-2xl relative">
      <div
        v-if="!canSetPresentation"
        v-show="active"
        class="absolute z-10 inset-0 bg-mine-1000/90 flex items-center justify-center text-center ml-3 text-sm text-mine-300"
      >
        Please complete the Display configuration in order to use the Vixi
        Social Desktop App.
      </div>

      <Transition>
        <div
          v-if="transitioning"
          v-show="active"
          class="absolute z-10 inset-0 bg-mine-1000/90 hover:cursor-wait flex items-center justify-center text-center"
        >
          <span class="loading-indicator"></span>
          <span class="ml-3 text-sm text-mine-300">Transitioning</span>
        </div>
      </Transition>

      <div v-show="active" class="flex justify-center space-x-3 p-3 relative">
        <button
          v-for="(presentation, index) in presentations"
          class="h-14 w-14 flex items-center justify-center rounded transition"
          :key="index"
          v-tooltip="{
            delay: { show: 1000 },
            content: presentation.help,
          }"
          :class="{
            'text-mine-500 bg-mine-800 hover:bg-golden-300/10  hover:text-golden':
              activePresentation != presentation.name,
            'text-cerise bg-cerise-500/10':
              activePresentation == presentation.name,
          }"
          @click.prevent="() => setPresentation(presentation.name)"
        >
          <span :class="presentation.icon"></span>
        </button>
        <div
          v-if="display.auto_mode"
          class="absolute inset-0 bg-transparent cursor-not-allowed"
        ></div>
      </div>

      <div
        v-show="active"
        class="flex justify-between rounded p-3 bg-mine-1000"
      >
        <flat-icon-button
          icon="alpha-a-box"
          v-tooltip="{
            delay: { show: 1000 },
            content: 'Run the entire show automatically.',
          }"
          :class="{ 'ring-golden !text-golden ring-1': display?.auto_mode }"
          @click="toggleAutomode"
        />
        <div class="flex space-x-3">
          <flat-icon-button
            v-tooltip="{
              delay: { show: 1000 },
              content: 'Return to the previous Highlight item or show state.',
            }"
            icon="rewind"
            :disabled="!canGoToPrevPost"
            @click="() => goToPresentation('prev')"
          />
          <flat-icon-button
            v-tooltip="{
              delay: { show: 1000 },
              content: display?.auto_mode
                ? 'Pause the show (disables Auto mode).'
                : 'Start playing the show (enables Auto mode).',
            }"
            :icon="display?.auto_mode ? 'pause' : 'play'"
            @click="toggleAutomode"
          />
          <flat-icon-button
            v-tooltip="{
              delay: { show: 1000 },
              content: 'Proceed to the next Highlight item or show state.',
            }"
            icon="fast-forward"
            :disabled="display?.auto_mode || display.presentation == 'highlightPost'"
            @click="() => goToPresentation('next')"
          />
        </div>
        <flat-icon-button
          v-tooltip="{
            delay: { show: 1000 },
            content: 'Hide the Mini Controller.',
          }"
          icon="arrow-bottom-right"
          @click="() => (active = false)"
        />
      </div>

      <div v-show="!active" class="p-3">
        <flat-icon-button
          v-tooltip="{
            delay: { show: 1000 },
            content: 'Show the Mini Controller.',
          }"
          icon="arrow-top-left"
          @click="() => (active = true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState, mapGetters } from 'vuex'

  export default {
    data() {
      return {
        active: false,
        presentations: [
          {
            name: 'heroCta',
            icon: 'icon-hero',
            help: 'Go to the Hero / CTA state.',
          },
          {
            name: 'socialGrid',
            icon: 'icon-social_grid',
            help: 'Go to the Moderation state.',
          },
          {
            name: 'highlightPlaylist',
            icon: 'icon-highlight_playlist',
            help: 'Go to the Highlight Playlist state.',
          },
          {
            name: 'highlightPost',
            icon: 'icon-highlight_post',
            help: 'Highlight the most recent post.',
          },
        ],
      }
    },

    computed: {
      ...mapState('display', ['display', 'transitioning']),
      ...mapGetters('display', ['canSetPresentation', 'canGoToPrevPost']),

      activePresentation() {
        return this.display?.presentation
      },
    },

    watch: {
      active(newValue, oldValue) {
        localStorage.setItem('miniDashboard', newValue)
      },
    },

    mounted() {
      const activeState = localStorage.getItem('miniDashboard')
      if (activeState) {
        this.active = activeState == 'true' ? true : false
      }
    },

    methods: {
      ...mapActions('display', [
        'setPresentation',
        'toggleAutomode',
        'goToPresentation',
      ]),
    },
  }
</script>
