<template>
  <div
    class="flex flex-col items-center cursor-pointer"
    :class="{ 'cursor-not-allowed': disabled }"
    @mouseover="hovering = true"
    @mouseleave="hovering = false"
    @click="onPressed"
  >
    <div class="relative">
      <div
        v-if="checked"
        class="absolute inset-0 flex items-center justify-center"
      >
        <mdi-icon
          icon="checkbox-marked-circle-outline"
          class="transition"
          :class="{
            'text-cerise': active,
            '!text-mine-500': hovering && !active,
            'text-mine-700': !active,
          }"
        />
      </div>

      <svg
        v-if="type == 'Side Tower Left' || type == 'Side Tower Right'"
        :class="bgColor"
        :fill="fill"
        :fill-opacity="fillOpacity"
        width="33"
        height="138"
        viewBox="0 0 33 138"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.46912"
          y="1"
          width="29.9222"
          height="136"
          rx="3"
          :stroke="stroke"
          class="transition"
          stroke-width="2"
        />
      </svg>
      <svg
        v-if="type == 'Main Screen'"
        :class="bgColor"
        :fill="fill"
        :fill-opacity="fillOpacity"
        width="224"
        height="138"
        viewBox="0 0 224 138"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.19458"
          y="1"
          width="221.455"
          height="136"
          rx="3"
          :stroke="stroke"
          class="transition"
          stroke-width="2"
        />
      </svg>
      <svg
        v-if="
          type == 'Social Upper 1' ||
          type == 'Social Upper 2' ||
          type == 'Social Lower 3' ||
          type == 'Social Lower 4' ||
          type == 'Highlight Upper 2' ||
          type == 'Highlight Lower 1'
        "
        width="68"
        height="103"
        viewBox="0 0 68 103"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 4.9503C1 3.0398 2.76204 1.61601 4.62995 2.01719L64.6299 14.9035C66.0125 15.2004 67 16.4225 67 17.8366V98.0497C67 99.9602 65.238 101.384 63.37 100.983L3.37005 88.0965C1.98749 87.7996 1 86.5775 1 85.1634V4.9503Z"
          :fill="fill"
          :fill-opacity="fillOpacity"
          :stroke="stroke"
          class="transition"
          stroke-width="2"
        />
      </svg>
      <svg
        v-if="
          type == 'Social Upper 3' ||
          type == 'Social Upper 4' ||
          type == 'Social Lower 1' ||
          type == 'Social Lower 2' ||
          type == 'Highlight Upper 1' ||
          type == 'Highlight Lower 2'
        "
        :fill="fill"
        :fill-opacity="fillOpacity"
        width="69"
        height="103"
        viewBox="0 0 69 103"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 17.8465C1 16.429 1.99207 15.205 3.37879 14.9115L64.3788 2.00028C66.2443 1.60542 68 3.02841 68 4.93525V85.1535C68 86.571 67.0079 87.795 65.6212 88.0885L4.62122 101C2.7557 101.395 1 99.9716 1 98.0648V17.8465Z"
          :stroke="stroke"
          class="transition"
          stroke-width="2"
        />
      </svg>
      <svg
        v-if="type == 'Playlist BG'"
        :class="bgColor"
        :fill="fill"
        :fill-opacity="fillOpacity"
        width="242"
        height="121"
        viewBox="0 0 242 121"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="240"
          height="119"
          rx="3"
          :stroke="stroke"
          class="transition"
          stroke-width="2"
        />
      </svg>
      <svg
        v-if="type == 'Playlist Widget'"
        :class="bgColor"
        fill="none"
        width="119"
        height="119"
        viewBox="0 0 119 119"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="117"
          height="117"
          rx="3"
          :stroke="stroke"
          class="transition"
          stroke-width="2"
        />
      </svg>
      <svg
        v-if="type == 'Post BG'"
        :class="bgColor"
        :fill="fill"
        :fill-opacity="fillOpacity"
        width="177"
        height="108"
        viewBox="0 0 177 108"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.81006"
          y="1"
          width="173.485"
          height="105.291"
          rx="3"
          :stroke="stroke"
          class="transition"
          stroke-width="2"
        />
      </svg>
      <svg
        v-if="type == 'Post Widget' || type == 'Social Popup'"
        :class="bgColor"
        :fill="fill"
        :fill-opacity="fillOpacity"
        width="88"
        height="88"
        viewBox="0 0 88 88"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="85.74"
          height="85.74"
          rx="3"
          :stroke="stroke"
          class="transition"
          stroke-width="2"
        />
      </svg>
      <svg
        v-if="type == 'Ribbon Banner'"
        :class="bgColor"
        :fill="fill"
        :fill-opacity="fillOpacity"
        width="359"
        height="45"
        viewBox="0 0 359 45"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1.49365"
          width="357"
          height="41.718"
          rx="3"
          :stroke="stroke"
          class="transition"
          stroke-width="2"
        />
      </svg>
      <svg
        v-if="type == 'BB Rack'"
        :class="bgColor"
        :fill="fill"
        :fill-opacity="fillOpacity"
        width="88"
        height="88"
        viewBox="0 0 88 88"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="85.74"
          height="85.74"
          rx="3"
          :stroke="stroke"
          class="transition"
          stroke-width="2"
        />
      </svg>
      <svg
        v-if="type == 'Flag'"
        :class="bgColor"
        :fill="fill"
        :fill-opacity="fillOpacity"
        width="44"
        height="31"
        viewBox="0 0 44 31"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="42"
          height="29"
          rx="3"
          :stroke="stroke"
          class="transition"
          stroke-width="2"
        />
      </svg>
      <svg
        v-if="type == 'Image Posts'"
        :class="bgColor"
        :fill="fill"
        :fill-opacity="fillOpacity"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="78"
          height="78"
          rx="3"
          :stroke="stroke"
          class="transition"
          stroke-width="2"
        />
      </svg>
      <svg
        v-if="type == 'Video Posts'"
        :class="bgColor"
        :fill="fill"
        :fill-opacity="fillOpacity"
        width="80"
        height="143"
        viewBox="0 0 80 143"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="78"
          height="140.16"
          rx="3"
          :stroke="stroke"
          class="transition"
          stroke-width="2"
        />
      </svg>
      <svg
        v-if="type == 'LED Billboard'"
        :class="bgColor"
        :fill="fill"
        :fill-opacity="fillOpacity"
        width="120"
        height="67"
        viewBox="0 0 120 67"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="118"
          height="65"
          rx="3"
          :stroke="stroke"
          class="transition"
          stroke-width="2"
        />
      </svg>
    </div>

    <div
      class="text-xs uppercase text-center tracking-widest mt-3"
      :class="{ '!text-cerise': active }"
    >
      {{ type }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        required: true,
      },
      active: {
        type: Boolean,
        default: false,
      },
      checked: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['onPressed'],
    data() {
      return {
        hovering: false,
      }
    },
    computed: {
      bgColor() {
        return this.active ? 'bg-cerise-500/10' : 'bg-transparent'
      },
      fill() {
        return this.active ? '#d52265' : 'none'
      },
      fillOpacity() {
        return this.active ? '0.1' : '1'
      },
      stroke() {
        return this.active ? '#d52265' : this.hovering ? '#666666' : '#2d2d2d'
      },
    },
    methods: {
      onPressed() {
        if (this.disabled) return

        this.$emit('onPressed')
      },
    },
  }
</script>
