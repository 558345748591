<template>
  <div class="fixed z-20 inset-0 backdrop-blur flex justify-center items-center pt-24 pb-8 py-9">
    <div class="w-[1000px] h-full max-w-full bg-mine-900 rounded shadow-xl flex flex-col">
      <div class="bg-mine-800 rounded flex items-center py-2 w-full">
        <dropdown-selector @update:filters="handleFiltersUpdate"></dropdown-selector>
        <div class="flex justify-end">
          <flat-icon-button :icon="search?.length ? 'backspace' : 'magnify'" class="ml-2 lg:hidden"
            @click="search?.length ? (search = '') : (showSearchModal = true)" />
          <div class="hidden lg:block relative lg:w-48">
            <text-field v-model="search" placeholder="Filter posts by keyword" class="pr-7" />
            <button v-if="search.length"
              class="absolute right-1 top-[6px] w-5 h-5 flex items-center justify-center text-white whitespace-nowrap bg-mine-800/60 rounded-sm transition hover:bg-mine-900 active:bg-mine-1000 focus:outline-none appearance-none"
              @click="() => (search = '')">
              <mdi-icon icon="close" size="18" />
            </button>
          </div>
          <flat-icon-button icon="close" class="mx-2" @click="close" />
        </div>
      </div>
      <div v-if="getPosts.length" class="flex-1 overflow-auto py-14 flex flex-col items-center" @scroll="handleScroll">
        <div class="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-14 gap-y-10 px-4">
          <div v-for="post in getPosts" :key="post.post_id" class="h-min relative bg-mine-800 rounded w-44">
            <div class="flex p-3">
              <div class="flex flex-col max-w-full">
                <h3 class="text-xs text-ellipsis overflow-hidden whitespace-nowrap">
                  {{ post.account_name }}
                </h3>
                <h4 v-show="post.account_handle" class="text-xs text-mine-300 mt-1">
                  @{{ post.account_handle }}
                </h4>
              </div>
            </div>
            <div
              class="relative h-32 rounded-sm overflow-hidden group bg-mine-700 hover:ring-2 hover:ring-golden hover:cursor-pointer"
              @click="() => selectPost(post)">
              <loading-image v-if="post.original_image_url" :image-url="post.original_image_url"
                size-classes="h-32 w-full" background-class="object-contain" />
              <div v-else class="h-32 flex items-center justify-center text-xs text-mine-300">
                Text only
              </div>
            </div>
            <div class="p-3" v-tooltip="{
              delay: { show: 1000 },
              content: post.message,
            }">
              <h4 class="text-xs text-mine-300 h-[70px] overflow-hidden text-ellipsis whitespace-normal"
                :style="`display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;`">
                <div class="flex items-center space-x-2 mb-1">
                  <img v-if="post.network" :src="`/img/${post.network}.svg`" :alt="post.network == 'twitter' ? 'x' : post.network" class="h-3 w-auto" />
                  <div class="text-mine-500 flex-1">
                    {{ getTime(post.date) }}
                  </div>
                  <mdi-icon v-if="post.media_type == 'video'" icon="play-circle-outline" size="18" class="w-5" />
                </div>
                <div>{{ post.message }}</div>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading" class="flex items-center justify-center flex-1">
        <loading-spinner color-classes="from-transparent to-cerise-600" background-class="bg-mine-900" />
      </div>
      <div v-if="!loading && !getPosts.length" class="flex items-center justify-center flex-1">
        <p class="flex-1 text-sm text-center flex items-center justify-center">
          No posts found.
        </p>
      </div>
    </div>
    <Transition>
      <content-modal v-if="showSearchModal" title="Search" @close="() => (showSearchModal = false)">
        <text-field v-model="search" placeholder="Filter posts by keyword" />
      </content-modal>
    </Transition>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters } from "vuex"

export default {

  emits: ['selectPost', 'close'],

  data() {
    return {
      loading: true,
      search: '',
      showSearchModal: false,
      lastScrollPosition: 0
    }
  },

  computed: {
    ...mapGetters("post", ["getPosts", "getPage", "getLoadedPages", "getNextPageUrl"]),
    ...mapState("presentation", { presentation: "presentation"}),
  },

  watch: {
    search(newValue) {
      if (newValue.length >= 3 || newValue.length === 0) {
        this.search = newValue
        this.resetPagination()
        this.$store.commit('post/setFilters', { keyword: this.search })
        this.loadPage()
      }
    },
  },

  created() {
    this.$store.commit("post/reset")
  },

  methods: {
    async handleScroll(e) {
      if ( e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight - 100 && !this.loading) {
        this.loadMorePosts()
      }
    },

    async handleFiltersUpdate(filters) {
      this.$store.commit('post/setFilters', { ...filters, presentationId: this.presentation.id })
      this.resetPagination()
      this.loadPage()
    },

    async loadPage() {
      // Check if this page has already been loaded
      if (this.getLoadedPages.includes(this.getPage)) {
        return
      }

      try {
        this.loading = true
        await this.$store.dispatch("post/loadPosts", true)
      } finally {
        this.loading = false
      }
    },

    async loadMorePosts() {
      if (this.getNextPageUrl == null || this.loading) {
        return
      }

      this.$store.commit("post/setNextPage")

      await this.loadPage()
    },

    resetPagination() {
      this.$store.commit('post/resetPagination')
    },

    getTime(date) {
      return moment(date).fromNow()
    },

    selectPost(post) {
      this.$emit('selectPost', post)
    },

    close() {
      this.$emit('close')
    },
  },
}
</script>
