<template>
  <div class="flex items-center">
    <span class="pr-3">R</span>
    <number-field v-model="r" @change="() => updateValue('r')" />
    <span class="px-3">G</span>
    <number-field v-model="g" @change="() => updateValue('g')" />
    <span class="px-3">B</span>
    <number-field v-model="b" @change="() => updateValue('b')" />
    <div
      class="shrink-0 w-8 h-8 rounded-sm ml-6"
      :style="{ backgroundColor: `rgb(${r},${g},${b})` }"
    ></div>
  </div>
</template>

<script>
  export default {
    props: {
      initialValue: {
        type: [String],
        default: null,
      },
    },
    data() {
      return {
        r: 0,
        g: 0,
        b: 0,
      }
    },
    created() {
      if (this.initialValue) {
        const values = this.initialValue.split(',')
        this.r = values[0]
        this.g = values[1]
        this.b = values[2]
      }
    },
    methods: {
      updateValue(value) {
        if (this[value] > 255) {
          this[value] = 255
        }
        if (this[value] == null || this[value] == '' || this[value] < 0) {
          this[value] = 0
        }
        this.$emit('updated:value', `${this.r},${this.g},${this.b}`)
      },
    },
  }
</script>
