import { createRouter, createWebHashHistory } from 'vue-router'
import LoginPage from '/src/pages/LoginPage.vue'
import NotAuthorizedPage from '/src/pages/NotAuthorizedPage.vue'
import DisplaysPage from '/src/pages/DisplaysPage.vue'
import UsersPage from '/src/pages/UsersPage.vue'
import DisplayDashboardPage from '/src/pages/DisplayDashboardPage.vue'
import DisplayBillboardsPage from '/src/pages/DisplayBillboardsPage.vue'
import DisplaySocialGridPage from '/src/pages/DisplaySocialGridPage.vue'
import DisplayHighlightPlaylistPage from '/src/pages/DisplayHighlightPlaylistPage.vue'
import DisplayHighlightPostPage from '/src/pages/DisplayHighlightPostPage.vue'
import DisplayPageLayout from '/src/components/DisplayPageLayout.vue'

import { auth0Provider } from '/src/auth0'
import { pageInIframe } from '../utils/helper'
import store from '../store'

async function auth(to, from, next) {
  try {
    const token = await auth0Provider.getAccessTokenSilently();
    if (token) {
      !localStorage.getItem('accessToken') ? store.dispatch('auth/init') : '';
      return next()
    }
  } catch (error) {
    if (pageInIframe()) {
      return next({ name: 'NotAuthorized' })
    } 
    return next({ name: 'Login' })
  }
}

async function guest(to, from, next) {
  try {
    const token = localStorage.getItem('accessToken')
    if (token && store.getters['organization/organization']) {
      return next({ name: 'Displays' })
    } 
    return next()
  } catch (error) {
    return next()
  }  
}

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'Login',
      component: LoginPage,
      beforeEnter: [guest],
    },
    {
      path: '/not-authorized',
      name: 'NotAuthorized',
      component: NotAuthorizedPage,
      beforeEnter: [guest],
    },
    {
      path: '/app',
      name: 'Displays',
      component: DisplaysPage,
      beforeEnter: [auth],
    },
    {
      path: '/app/users',
      name: 'Users',
      component: UsersPage,
      beforeEnter: [auth],
    },
    {
      path: '/app/displays/:key',
      component: DisplayPageLayout,
      beforeEnter: [auth],
      children: [
        {
          name: 'Controller',
          path: 'controller',
          component: DisplayDashboardPage,
        },
        {
          name: 'Graphics',
          path: 'graphics',
          component: DisplayBillboardsPage,
        },
        {
          name: 'Moderation',
          path: 'moderation',
          component: DisplaySocialGridPage,
        },
        {
          name: 'Highlight Playlist',
          path: 'highlight-playlist',
          component: DisplayHighlightPlaylistPage,
        },
        {
          name: 'Highlight Post',
          path: 'highlight-post',
          component: DisplayHighlightPostPage,
        },
      ],
    },
  ],
})

export default router
