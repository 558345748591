<template>
  <app-layout>
    <div v-if="!loading" class="flex flex-col flex-1">
      <div
        v-if="viewingDisplayAsSuperadmin"
        class="px-5 py-2 text-sm bg-cerise"
      >
        Viewing Display as superadmin.
      </div>

      <div class="px-5 h-11 flex items-center text-sm">
        <router-link
          v-tooltip="{
            delay: { show: 1000 },
            content: 'Return to the Displays menu.',
          }"
          :to="viewingDisplayAsSuperadmin ? '/app/users' : '/app'"
          class="text-cerise"
        >
          Displays
        </router-link>
        <template v-if="viewingDisplayAsSuperadmin">
          <span class="mx-4">/</span>
          <span class="whitespace-nowrap overflow-hidden text-ellipsis">
            {{ display?.user?.name ?? 'User' }}
          </span>
        </template>
        <span class="mx-4">/</span>
        <span class="whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">
          {{ display?.name ?? 'New Display' }}
        </span>
        <span v-if="!showInIframe" class="hidden md:block ml-6 text-mine-600">Last updated {{ lastEdited }}</span>
        <span v-if="!showInIframe" class="ml-auto"></span>
        <span
          v-if="!showInIframe"
          class="hidden md:block uppercase tracking-widest text-xs text-mine-400"
          :class="{ 'ml-2': showInIframe }"
        >
          {{ connected ? 'Connected to UE' : 'Disconnected from UE' }}
        </span>
        <mdi-icon
          v-tooltip="{
            delay: { show: 1000 },
            content: connected ? 'Connected to UE' : 'Disconnected from UE',
          }"
          icon="circle"
          size="12"
          class="ml-2"
          :class="{ 'text-green': connected, 'text-red': !connected, 'ml-4': showInIframe }"
        />
        <flat-icon-button
          v-tooltip="{
            delay: { show: display.auto_mode ? 0 : 1000 },
            content: 'Edit Display settings.',
            content: display.auto_mode ? 'Disable auto mode to edit settings' : 'Edit Display settings'
          }"
          icon="pencil"
          class="ml-5"
          @click="edit"
          :disabled="display.auto_mode"
        />
        <span v-if="showInIframe" class="hidden md:block ml-4 text-mine-600 text-xs">Last updated {{ lastEdited }}</span>

        <div v-if="showInIframe" class="ml-auto flex items-center space-x-4">
          <app-header-menu />
        </div>
      </div>

      <div class="px-2 h-11 flex border-t border-b border-mine-800">
        <router-link
          v-for="(link, index) in links"
          :key="index"
          class="h-[43px] px-3 flex-1 md:flex-initial flex items-center justify-center text-[13px] border-b border-transparent text-mine-300 transition hover:border-mine-600 hover:text-mine-200"
          active-class="!border-cerise !text-cerise"
          :to="link.route"
        >
          <span :class="`${link.icon}`"></span>
          <span class="hidden md:block ml-2">{{ link.label }}</span>
        </router-link>
      </div>

      <div class="flex-1 flex flex-col">
        <router-view />
      </div>

      <mini-display-dashboard v-show="showMiniDashboard" />
    </div>
  </app-layout>

  <Transition>
    <display-settings-modal
      v-if="settingsModal"
      :display="display"
      @close="() => (settingsModal = false)"
    />
  </Transition>
</template>

<script>
  import moment from 'moment'
  import { mapState, mapMutations, mapGetters } from 'vuex'
  import { pageInIframe } from '../utils/helper'

  export default {
    data() {
      return {
        loading: true,
        settingsModal: false,
        links: [
          {
            label: 'Controller',
            route: 'controller',
            icon: 'icon-dashboard text-[18px] md:text-[13px]',
          },
          {
            label: 'Graphics',
            route: 'graphics',
            icon: 'icon-hero text-[16px] md:text-[10px]',
          },
          {
            label: 'Moderation',
            route: 'moderation',
            icon: 'icon-social_grid text-[18px] md:text-[13px]',
          },
          {
            label: 'Highlight Playlist',
            route: 'highlight-playlist',
            icon: 'icon-highlight_playlist text-[18px] md:text-[11px]',
          },
          {
            label: 'Highlight Post',
            route: 'highlight-post',
            icon: 'icon-highlight_post text-[16px] md:text-[9px]',
          },
        ],
        showInIframe: pageInIframe()
      }
    },
    computed: {
      ...mapState('display', ['display']),
      ...mapGetters('display', ['viewingDisplayAsSuperadmin']),

      connected() {
        return this.display?.is_connected
      },
      showMiniDashboard() {
        return this.$route.name != 'Dashboard'
      },
      lastEdited() {
        return moment(this.display?.updated_at).subtract(5, 'seconds').fromNow()
      },
    },
    async created() {
      await this.$store.dispatch('display/loadDisplay', this.$route.params.key)
      this.loading = false
    },
    unmounted() {
      this.setDisplay(null)
      this.setTransitioning(false)
    },
    methods: {
      ...mapMutations('display', ['setDisplay', 'setTransitioning']),

      edit() {
        this.settingsModal = true
      },
    },
  }
</script>
