<template>
  <div
    class="px-3 sm:px-4 py-3 mb-5 bg-mine-800/60 flex items-center rounded overflow-hidden"
  >
    <mdi-icon
      icon="drag-vertical"
      class="text-mine-500 mr-4"
      :class="{
        'handle cursor-grab': canUpdate,
        'opacity-20 cursor-not-allowed': !canUpdate,
      }"
    />

    <div
      class="relative w-24 h-24 flex-shrink-0 rounded overflow-hidden aspect-square bg-mine-700"
      :class="{
        'ring-2 ring-cerise': isCurrent,
        'ring-2 ring-golden': isComingUp,
        'cursor-pointer': post.permalink,
      }"
      @click="onClickPost(post)"
    >
      <image-crop
        v-if="post.image_width > post.image_height"
        class="absolute z-[1] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        :stroke-width="4"
        :style="cropSize"
        :type="'square'"
        :color="isCurrent ? '#D52265' : isComingUp ? '#fec651' : '#e3e3e3'"
      />
      <loading-image
        v-if="post.original_image_url"
        :image-url="post.original_image_url"
        size-classes="h-24 w-24"
        background-class="object-contain"
      />
      <div
        v-else
        class="w-24 h-24 flex items-center justify-center text-xs text-mine-300"
      >
        Text only
      </div>
    </div>

    <div class="flex-1 flex flex-col sm:flex-row">
      <div class="flex-1 pl-6 sm:px-10 flex flex-col items-start">
        <div class="text-mine-300 text-xs flex items-center space-x-2 mb-2">
          <img
            v-if="post.network"
            :src="`/img/${post.network}.svg`"
            :alt="post.network"
            class="h-3 w-auto"
          />
          <div v-show="post.account_handle">@{{ post.account_handle }}</div>
          <mdi-icon
            v-if="post.media_type == 'video'"
            icon="play-circle-outline"
            size="18"
            class="w-5 pl-1"
          />
        </div>
        <div
          v-tooltip="{
            delay: { show: 1000 },
            content: post.message,
          }"
          class="text-mine-500 text-xs h-[50px] max-w-[300px] overflow-hidden text-ellipsis whitespace-normal"
          :style="`display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;`"
        >
          {{ post.message }}
        </div>
      </div>

      <div
        class="flex sm:flex-col sm:space-y-3 space-x-3 sm:space-x-0 justify-end sm:justify-center mt-3 sm:mt-0"
      >
        <flat-icon-toggle
          icon="eye-outline"
          :active="post.is_visible"
          :disabled="!canUpdate"
          @click="() => (displayInHPL ? null : toggleVisibility())"
        />
        <flat-icon-button
          icon="trash-can"
          :disabled="!canUpdate"
          @click="() => (showDeleteModal = true)"
        />
      </div>
    </div>
  </div>

  <Transition>
    <destructive-modal
      v-if="showDeleteModal"
      title="Remove Media Item"
      body="Are you sure you want to remove this media item from the Highlight Playlist? It will not be deleted from the Tagboard."
      :loading="deleting"
      confirm-text="Remove"
      @confirmed="onDeletePost"
      @close="() => (showDeleteModal = false)"
    />
  </Transition>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  export default {
    props: {
      totalPosts: {
        type: Number,
        required: true,
      },
      post: {
        type: Object,
        default: () => {},
      },
      canUpdate: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        name: null,
        showDeleteModal: false,
        deleting: false,
      }
    },
    emits: ['delete:highlightPost'],
    computed: {
      ...mapState('display', ['display']),
      ...mapGetters('presentation', ['visibleHPLPosts']),

      displayInHPL() {
        return this.display?.presentation == 'highlightPlaylist'
      },

      current() {
        return this.displayInHPL ? this.display?.currentPost ?? 0 : null
      },

      isCurrent() {
        if (this.current == null) return null
        const currentPostId = this.visibleHPLPosts[this.current]
        return this.post.id == currentPostId
      },

      isComingUp() {
        if (this.current == null) return null
        const nextPostId = this.visibleHPLPosts[this.current + 1]
        return nextPostId ? this.post.id === nextPostId : null
      },

      cropSize() {
        let renderedWidth, renderedHeight
        let elWidth = 96
        let elHeight = 96

        // Aspect ratios
        let imgRatio =
          this.post?.image_width && this.post?.image_height
            ? this.post.image_width / this.post.image_height
            : 0
        let elRatio = elWidth / elHeight

        // Check which dimension is the limiting one
        if (elRatio > imgRatio) {
          // Div is "wider" (in terms of aspect ratio), so image is limited by height
          renderedHeight = elHeight
          renderedWidth = elHeight * imgRatio
        } else {
          // Div is "taller", so image is limited by width
          renderedWidth = elWidth
          renderedHeight = elWidth / imgRatio
        }

        return {
          width: `${renderedWidth}px`,
          height: `${renderedHeight}px`,
        }
      },
    },
    methods: {
      ...mapActions('presentation', ['deletePost', 'updatePost']),

      async toggleVisibility() {
        let post = JSON.parse(JSON.stringify(this.post))
        post.is_visible = !this.post.is_visible
        await this.updatePost(post)
      },

      async onDeletePost() {
        this.deleting = true
        await this.deletePost(this.post)
        this.$emit('delete:highlightPost', this.post);
        this.deleting = false
        this.showDeleteModal = false
      },

      onClickPost(post) {
        if (post.permalink) {
          window.open(post.permalink, '_blank')
        }
      },
    },
  }
</script>
