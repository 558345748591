<template>
  <app-layout>
    <header v-if="pageInIframe" class="px-5 h-11 flex items-center text-sm border-b border-mine-800">
      <div class="ml-auto flex items-center space-x-4">      
        <app-header-menu />
      </div>
    </header>
    <section class="py-8 px-4 md:px-6 lg:px-8 xl:px-10 pb-24">
      <div v-if="loading" class="min-w-[336px] min-h-[500px] flex items-center justify-center">
        <loading-spinner color-classes="from-transparent to-cerise-600" />
      </div>

      <div v-if="!loading" class="mx-auto" :class="{
        'max-w-[280px]': displaysCount == 0,
        'max-w-[280px] md:max-w-[616px]': displaysCount == 1,
        'max-w-[280px] md:max-w-[616px] lg:max-w-[952px]': displaysCount > 1,
      }">
        <div class="flex justify-between max-w-full mb-12" :class="{
          'col-span-1': displaysCount == 0,
          'col-span-1 md:col-span-2': displaysCount == 1,
          'col-span-1 md:col-span-2 lg:col-span-3': displaysCount > 1,
        }">
          <h1 class="text-2xl font-bold text-mine-300">Displays</h1>
          <div class="flex">
            <flat-button class="hidden md:inline-block" type="dark" @click="create">
              Add Display
            </flat-button>
            <flat-icon-button icon="plus" width-class="w-10" height-class="h-10" class="md:hidden" />
            <flat-icon-button v-if="isSuperadmin" class="ml-3" width-class="w-10" height-class="h-10" icon="account-multiple"
              v-tooltip="{
                delay: { show: 1000 },
                content: 'View all Users and Displays (SA Access)',
                placement: 'bottom',
              }" @click="$router.push('/app/users')" />
          </div>
        </div>

        <vue-draggable class="grid gap-x-8 gap-y-14" handle=".handle" item-key="id" :list="displays" :class="{
          'grid-cols-1': displaysCount == 0,
          'grid-cols-1 md:grid-cols-2': displaysCount == 1,
          'grid-cols-1 md:grid-cols-2 lg:grid-cols-3': displaysCount > 1,
        }" @end="onReorderDisplays">
          <template #item="{ element }">
            <div v-if="element.type != 'add'">
              <display-preview :display="element" @edit="(e) => {
                  settingsModal = true
                  displayToEdit = e
                }
                " />
            </div>
            <div v-else :class="{ 'pl-7': displaysCount > 0 }">
              <skeleton-add-button width-class="w-full" @click="create" />
            </div>
          </template>
        </vue-draggable>
      </div>
    </section>

    <Transition>
      <display-settings-modal v-if="settingsModal" :display="displayToEdit" :on-save="onSaveDisplay" @close="() => {
          settingsModal = false
          displayToEdit = {}
        }
        " />
    </Transition>
  </app-layout>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { pageInIframe } from '../utils/helper'

export default {
  data() {
    return {
      loading: true,
      displayToEdit: {},
      settingsModal: false,
      pageInIframe: pageInIframe()
    }
  },

  computed: {
    ...mapState('display', {
      displays: (state) => [...state.displays, { type: 'add' }],
    }),
    ...mapState('auth', ['apiUser']),
    ...mapGetters('auth', ['isSuperadmin']),
    displaysCount() {
      return this.displays?.filter((e) => !!e.id)?.length
    },
  },

  async created() {
    await this.loadAll()
    this.loading = false
  },

  methods: {
    ...mapActions('display', ['loadAll', 'create']),

    async onSaveDisplay() {
      await this.loadAll()
    },

    async onReorderDisplays() {
      await this.$store.dispatch(
        'display/reorder',
        this.displays.filter((e) => !!e.id).map((e) => e.id)
      )
      await this.loadAll()
    }
  },
}
</script>
