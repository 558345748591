<template>
  <loading-bar v-if="appLoading" />
  <app-alert />
  <div
    v-if="loading"
    class="min-h-screen flex items-center justify-center bg-mine-1100"
  >
    <div
      class="flex h-12 w-12 items-center justify-center rounded-full bg-gradient-to-tr from-mine-1100 to-cerise-600 animate-spin"
    >
      <div class="h-10 w-10 rounded-full bg-mine-1100"></div>
    </div>
  </div>
  <div
    v-else-if="fatalError.active"
    class="min-h-screen flex flex-col text-center items-center justify-center bg-mine-1100"
  >
    <h1 v-if="fatalError.title" class="text-red font-bold uppercase">
      {{ fatalError.title }}
    </h1>
    <p
      v-if="fatalError.message"
      class="text-mine-400 max-w-md mx-auto text-sm mt-2"
    >
      {{ fatalError.message }}
    </p>
  </div>
  <router-view v-else />
  <tagboard-login-modal :showModal="showTagboardLogin" @tagboard:login="loginWithTagboard" />
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import { Userpilot } from 'userpilot';
  import { pageInIframe } from './utils/helper'

  export default {
    data() {
      return {
        loading: true,
        pageInIframe: pageInIframe(),
        preventDoubleCall: false
      }
    },

    computed: {
      ...mapState({
        appLoading: state => state.app.loading,
        fatalError: state => state.app.fatalError,
        apiUser: state => state.auth.apiUser
      }),
      ...mapGetters({
        showTagboardLogin: 'app/showTagboardLogin', 
        isAuth: 'auth/isAuth'
      })
    },

    async created() {
      await this.initApp()

      this.showLoginOrRedirect()

      // Check authentication state for the chat widget
      if (this.isAuth) {
        this.initiateCall()
        this.initiateUserPilot()
      } else {
        // If widget is present, forcefully hide it.
        if (window.fcWidget) {
          window.fcWidget.hide()
        }
      }

      if (this.isAuth && localStorage.getItem('tbAccessToken')) {
        const tbUser = await this.$store.dispatch('tagboard/getUser')
        if (tbUser && tbUser.id !== this.apiUser.tagboard_user_id) {
          this.$store.commit('app/setShowTagboardLogin', true)
          await this.$store.dispatch(
            'app/showAlert',
            { type: 'error', message: `The Tagboard login doesn't match your tagboard account. Please login with the correct Tagboard account`, timeout: 5000 },
          )
        }
      }
    },

    watch: {
      isAuth(newValue) {
        if (newValue) {
          this.initiateCall()
        } else {
          if (window.fcWidget) {
            window.fcWidget.hide()
          }
        }
      },
      $route() {
        if (this.isAuth) {
          Userpilot.reload();
        }
      },
    },

    mounted() {
      window.addEventListener('message', this.handleMessage, false);
    },

    beforeDestroy() {
      window.removeEventListener('message', this.handleMessage);
    },

    methods: {
      async handleMessage(event) {
        if (event.data.sender === 'vixi-suite' && !this.preventDoubleCall) {
          this.preventDoubleCall = true

          this.$store.commit('organization/setActiveOrgToken', event.data.message.activeOrgToken);
          const prevOrganization = this.$store.getters['organization/organization']
          const organization = await this.$store.dispatch('organization/getOrganization', { activeOrgToken: event.data.message.activeOrgToken })
          if (prevOrganization && organization && prevOrganization.id !== organization.id) {
            this.$store.dispatch('auth/saveOrganizationId');
            this.$router.push({ name: 'Displays' })
            this.$store.dispatch('display/markModerationPostsForDisplays')
          }

          setTimeout(() => {
            this.preventDoubleCall = false
          }, 0)
        }
      },
      initFreshChat() {
        const userId = this.apiUser?.id.toString() // This is the unique identifier for the user
        const userEmail = this.apiUser?.email
        const userName = this.apiUser?.name

        if (window.fcWidget) {
          window.fcWidget.init({
            token: '792acafb-f8dc-420c-be8f-741bb9b691d3',
            host: 'https://thefamousgroup-support.freshchat.com',
          })
  
          // Set user details as per Freshchat's recommended instruction
          window.fcWidget.setExternalId(userId.toString()) // Convert the ID to a string if it's a number
          window.fcWidget.user.setFirstName(userName)
          window.fcWidget.user.setEmail(userEmail)
        }
      },

      initialize(i, t) {
        var e
        if (!i.getElementById(t)) {
          e = i.createElement('script')
          e.id = t
          e.async = true
          e.src = 'https://thefamousgroup-support.freshchat.com/js/widget.js'
          e.onload = this.initFreshChat
          i.head.appendChild(e)
        } else {
          this.initFreshChat()
        }
      },

      initiateCall() {
        if (!this.pageInIframe) {
          this.initialize(document, 'Freshchat-js-sdk')
        }
      },

      initiateUserPilot() {
        const organization = this.$store.getters['organization/organization']
        if (this.apiUser && organization) {
          Userpilot.identify(
            this.apiUser.id,
            {
              name: this.apiUser.name,
              email: this.apiUser.email,
              company: { 
                id: organization.id, 
                name: organization.name 
              }
            }
          )
        }
      },

      async initApp() {
        try {
          let firstTime = 0;
          if(!this.$store.getters['organization/organization']) {
            firstTime = 1;
          }
          const userLoaded = await this.$store.dispatch('auth/init')
          if (!userLoaded) {  
            this.$store.dispatch('app/quitRedirect')
            this.loading = false
            return
          }

          await this.$store.dispatch('display/loadEvents')
          window.Echo.connector.pusher.connection.bind('error', (error) =>
            this.$store.dispatch('app/fatalError', {
              title: 'Connection Error',
              message:
                "We've encountered a temporary disruption in the server connection. Please refresh the app or try again in a few moments.",
            })
          )

          if(firstTime && this.$store.getters['organization/organization']) {
            if (this.$route.path === "/") {
              this.$router.push({name: 'Displays'})
            } else {
              this.$router.push({ path: this.$route.path });
            }
          }
        } catch (error) {
          this.$store.dispatch('app/quitRedirect')
        } finally {
          this.loading = false
        }  
      },

      async loginWithTagboard() {
        if (this.pageInIframe) {
          const height = 600;
          const width = 600;

          const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
          const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
          const left = (window.innerWidth / 2) - (width / 2) + dualScreenLeft;
          const top = (window.innerHeight / 2) - (height / 2) + dualScreenTop;

          const url = `${import.meta.env.VITE_TAGBOARD_USER_URL}/auth/jwt?returnUrl=${import.meta.env.VITE_APP_BASE_URL}/closeWindow`
          window.open(url, 'TagboardLogin', `width=${width},height=${height},left=${left},top=${top}`)
        } else {
          await this.$store.dispatch('auth/loginTagboard')
        }
      },

      showLoginOrRedirect() {
        if (window.location.pathname === '/closeWindow') {
          window.close();
          if (window.opener) {
            window.opener.location.reload();
          }
        } else {
          try {
            this.$store.commit('app/setShowTagboardLogin', this.$store.getters['auth/tagboardNotLogin'])
          } catch {
            this.$store.commit('app/setShowTagboardLogin', false)
          }
        }
      }
    },
  }
</script>