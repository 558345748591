<template>
  <div class="flex">
    <div class="bg-mine-1100 px-3 rounded-l flex items-center text-sm text-mine-300">
      {{ text }}
    </div>
    <flat-icon-button v-tooltip="{
      delay: { show: 1000 },
      content: helpText,
    }" icon="content-copy" @click="copy" />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    copiedMessage: {
      type: String,
      default: false,
    },
    helpText: {
      type: String,
      default: null,
    },
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.text)
      if (this.copiedMessage) {
        this.$store.dispatch('app/updateAlert', {
          message: this.copiedMessage,
        })
      }
    },
  },
}
</script>
