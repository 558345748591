<template>
  <header class="bg-mine-800 px-4 h-16 flex items-center">
    <router-link to="/app">
      <img
        :src="getLogo"
        alt="Vixi Unreal"
        class="hidden md:block h-8 w-auto"
      />
      <img
        src="/img/logo_2.svg"
        alt="Vixi Unreal"
        class="md:hidden h-8 w-auto"
      />
    </router-link>
    <div class="ml-auto flex items-center space-x-4">      
      <app-header-menu />

      <div class="relative h-10">
        <div
          class="rounded-full h-10 w-10 border-white border-2 cursor-pointer flex items-center justify-center text-sm bg-cover bg-center bg-no-repeat"
          :style="{
            backgroundImage: 'url(' + apiUser?.profile_image_url + ') ',
          }"
          @click="showAccount = !showAccount"
        ></div>
        <Transition>
          <div
            v-if="showAccount"
            v-click-away="() => (showAccount = false)"
            class="fixed sm:absolute z-50 right-0 bg-mine-800 border border-mine-600 rounded-md shadow-md p-4"
          >
            <div class="text-mine-400 text-xs whitespace-nowrap mb-5">
              SIGNED IN AS
            </div>
            <div class="text-mine-400 font-bold whitespace-nowrap">
              {{ apiUser?.name }}
            </div>
            <div class="text-mine-400 text-xs whitespace-nowrap">
              {{ apiUser?.email }}
            </div>
            <flat-button class="w-full mt-6" @click.prevent="logout">
              Sign Out
            </flat-button>
          </div>
        </Transition>
      </div>
    </div>
  </header>
</template>

<script>
  import { mapState } from 'vuex'
  const env = import.meta.env
  export default {
    data() {
      return {
        showHelp: false,
        showApps: false,
        showAccount: false,
        ueVersion: env.VITE_UE_VERSION,
      }
    },
    computed: {
      ...mapState('auth', ['apiUser']),
      version() {
        return __APP_VERSION__
      },
      getLogo() {
        if (this.apiUser.tagboard_access) {
          return '/img/logo.svg'
        }
        return '/img/vixi_unreal_logo.svg'
      }
    },
    methods: {
      async logout() {
        await this.$store.dispatch('auth/logout')
        this.$router.push({ name: 'Login' })
      }
    },
  }
</script>
