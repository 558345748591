<template>
  <div
    v-show="imageLoading && image"
    :class="`${sizeClasses} flex items-center justify-center`"
  >
    <loading-spinner
      color-classes="from-transparent to-cerise-600"
      :background-class="backgroundColor"
    />
  </div>
  <div
    v-show="!image"
    :class="`${sizeClasses} flex items-center justify-center text-xs text-mine-300`"
  >
    No image
  </div>
  <img
    v-show="!imageLoading"
    :src="image"
    alt="Image"
    :class="`w-full h-full object-center ${backgroundClass}`"
    @error="onImageError"
    @load="() => (imageLoading = false)"
  />
</template>

<script>
  export default {
    props: {
      imageUrl: {
        type: String,
        default: null,
      },
      backgroundColor: {
        type: String,
        default: 'bg-mine-700',
      },
      backgroundClass: {
        type: String,
        default: 'object-cover',
      },
      sizeClasses: {
        type: String,
        default: 'h-24 w-24',
      },
    },
    data() {
      return {
        imageLoadErrorCount: 0,
        imageLoading: true,
        image: null,
      }
    },
    created() {
      this.image = this.imageUrl
    },
    methods: {
      onImageError() {
        if (this.imageLoadErrorCount <= 20) {
          this.imageLoadErrorCount++
          setTimeout(() => {
            this.image += '?' + +new Date()
          }, 3000)
        } else {
          this.image = null
        }
      },
    },
  }
</script>
