<template>
  <div class="flex items-center w-full sm:w-auto">
    <select-field-custom
      v-if="options.length > 1"
      :modelValue="selectedValue"
      :options="options"
      class="w-full sm:w-44 ml-2"
      @update:modelValue="handleSelectedValueUpdate"
    />
    <div class="hidden lg:block uppercase text-xs tracking-widest text-mine-300 ml-3" v-else-if="options.length === 1">{{ options[0].label }}<span v-if="colon">:</span></div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    selectedValue: {
      type: [String, Number],
      default: null
    },
    colon: false,
  },
  methods: {
    handleSelectedValueUpdate(selectedValue) {
      this.$emit('update:selectedValue', selectedValue);
    }
  }
};
</script>