import router from '/src/router'
const { VITE_APP_BASE_URL } = import.meta.env
import { mapGetters } from 'vuex'
import { auth0Provider } from '/src/auth0'
import { pageInIframe } from '/src/utils/helper'

export default {
  namespaced: true,
  state: {
    user: null,
    apiUser: null,
    auth0AccessToken: null,
    auth0IsAuthenticated: false,
    content: {
      title: 'Access Denied',
      message: '',
    },
  },
  getters: {
    isAuth: (state) => state.apiUser != null,
    tagboardAccess: (state) =>
      state.apiUser.tagboard_access && state.apiUser.tagboard_user_id,
    tagboardLoggedin: (state) =>
      state.apiUser.tagboard_access &&
      state.apiUser.tagboard_user_id &&
      localStorage.getItem('tbAccess'),
    captureAccess: (state) => state.apiUser.capture_access,
    isSuperadmin: (state) => state.apiUser?.is_superadmin,
    auth0AccessToken: (state) => state.auth0AccessToken,
    auth0IsAuthenticated: (state) => state.auth0IsAuthenticated,
    tagboardNotLogin: (state) =>
      state.apiUser.tagboard_access &&
      state.apiUser.tagboard_user_id &&
      !localStorage.getItem('tbAccessToken'),
    content: (state) => state.content,
  },
  mutations: {
    reset(state) {
      state.user = null
    },
    setTagboardUser(state, user) {
      state.user = user
    },
    setApiUser(state, apiUser) {
      state.apiUser = apiUser
    },
    setAuth0AcceesToken(state, auth0AccessToken) {
      state.auth0AccessToken = auth0AccessToken
    },
    setAuth0IsAuthenticated(state, auth0IsAuthenticated) {
      state.auth0IsAuthenticated = auth0IsAuthenticated
    },
    setContent(state, { title, message }) {
      state.content = {
        title: title || state.content.title,
        message,
      }
    },
  },
  actions: {
    async init({ commit, dispatch, rootGetters }) {
      commit(
        'setAuth0AcceesToken',
        await auth0Provider.getAccessTokenSilently()
      )
      commit('setAuth0IsAuthenticated', auth0Provider.isAuthenticated)
      const activeOrg = await dispatch(
        'organization/getOrganization',
        { activeOrgToken: rootGetters['organization/activeOrgToken'] },
        { root: true }
      )

      if (activeOrg) {
        const params = new URLSearchParams(window.location.search)
        const queryToken = params.get('accessToken')

        if (queryToken) {
          window.location.search = ''
          localStorage.setItem('tbAccessToken', queryToken)
        }

        return await dispatch('loadUser')
      }
    },

    async getUserToken() {
      return await auth0Provider.getAccessTokenSilently()
    },

    async loginTagboard({ dispatch }) {
      await dispatch(
        'app/tbRequest',
        {
          method: 'authenticate',
          params: import.meta.env.VITE_APP_BASE_URL,
        },
        { root: true }
      )
    },

    async login() {
      auth0Provider.loginWithRedirect()
    },

    async logout({ dispatch }, showMessage = true) {
      await dispatch(
        'app/apiRequest',
        { method: 'get', endpoint: '/v1/auth/logout' },
        { root: true }
      )
      localStorage.removeItem('tbAccessToken')
      dispatch('postLogout')

      if (showMessage) {
        dispatch(
          'app/showAlert',
          { type: 'success', message: 'Logged out successfully.' },
          { root: true }
        )
      }
      auth0Provider.logout({ logoutParams: { returnTo: VITE_APP_BASE_URL } })
    },

    postLogout({ dispatch }) {
      dispatch('app/reset', null, { root: true })
      router.push({ name: 'Login' })
      dispatch('cleanLocalStorageExceptKey', 'updatedDownload')
    },

    productNotAuthorized({ dispatch, commit }) {
      dispatch('app/reset', null, { root: true })
      const message = 'Your organization does not have access to this product.'
      if (pageInIframe()) {
        commit('app/setNotAuthorize', { message }, { root: true })
        router.push({ name: 'NotAuthorized' })
      } else {
        commit('auth/setContent', { message }, { root: true })
        router.push({ name: 'Login' })
      }
      dispatch('cleanLocalStorageExceptKey', 'updatedDownload')
    },

    cleanLocalStorageExceptKey(keyName) {
      for (var key in localStorage) {
        if (key !== keyName) {
          localStorage.removeItem(key)
        }
      }
    },

    async loadUser({ commit, dispatch, state, rootGetters }) {
      if (!state.auth0IsAuthenticated) {
        return false
      }

      try {
        const apiLogin = await dispatch(
          'app/apiRequest',
          {
            method: 'post',
            endpoint: '/v1/auth/login',
            data: {
              device_name: navigator.userAgent,
              organization_id: rootGetters['organization/organization']?.id,
            },
            headers: {
              Authorization: `Bearer ${state.auth0AccessToken}`,
              'Organization-Token': rootGetters['organization/activeOrgToken'],
            },
          },
          { root: true }
        )

        if (!apiLogin) {
          await dispatch('logout', false)
          return false
        }

        const loginData = apiLogin.data.data
        localStorage.setItem('accessToken', loginData.token)
        commit('setApiUser', loginData.user)
        dispatch('subscribeToApiUserNotifications')
        dispatch('app/updateNowAlert', {}, { root: true })
        dispatch('app/subscribeToDataBaseFilled', {}, { root: true })
        return true
      } catch (error) {
        console.error(error)
      }
    },

    subscribeToApiUserNotifications({ state, dispatch }) {
      window.Echo.options.auth.headers.Authorization = `Bearer ${localStorage.getItem(
        'accessToken'
      )}`

      window.Echo.private(`App.Models.User.${state.apiUser.id}`).notification(
        (notification) => {
          if (!notification?.show) return
          switch (notification.message_type) {
            case 'update':
            case 'success':
            case 'warning':
            case 'error':
              dispatch(
                `app/${notification.message_type}Alert`,
                {
                  message: notification.message,
                },
                { root: true }
              )
              break

            default:
              dispatch(
                `app/updateAlert`,
                {
                  message: notification.message,
                },
                { root: true }
              )
              break
          }
        }
      )
    },

    async saveOrganizationId({ dispatch, rootGetters }) {
      const apiLogin = await dispatch(
        'app/apiRequest',
        {
          method: 'post',
          endpoint: '/v1/users/organization-id',
          data: {
            organization_id: rootGetters['organization/organization']?.id,
          },
        },
        { root: true }
      )
    },
  },
}
