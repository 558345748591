import moment from 'moment'

function postToApi(tbPost, tagboardId = null) {
  let type = tbPost.post_type
  let image = tbPost.photos?.length
    ? tbPost.photos[0].l
    : tbPost.videos?.length
    ? tbPost.videos[0].p
    : null
  let media = tbPost.videos?.length
    ? tbPost.videos[0].m
    : tbPost.photos?.length
    ? tbPost.photos[0].l
    : null

  if (tbPost?.network == 'youtube') {
    type = 'photo'
    image = tbPost.videos[0].t
    media = tbPost.videos[0].t
  }

  return {
    source_id: tbPost.post_id,
    account_handle: tbPost.user_name,
    account_name: tbPost.user_real_name,
    account_avatar: tbPost.user_profile_image_url,
    message: tbPost.text,
    date: moment(tbPost.post_time).toISOString(),
    media_type: type,
    original_image_url: image,
    original_media_url: media,
    network: tbPost.network,
    hashtags: tbPost.hashtags,
    permalink: tbPost.permalink,
    account_permalink: tbPost.user_permalink,
    source_entity_id: tagboardId,
  }
}

async function loadPostDimensions(post) {
  if (!post.original_image_url) return post
  const image = await loadImage(post.original_image_url)
  return Object.assign(post, {
    image_width: image?.naturalWidth ?? 0,
    image_height: image?.naturalHeight ?? 0,
  })
}

async function loadImage(url) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = resolve
    img.src = url
  })
}

export { postToApi, loadPostDimensions }
