<template>
  <div class="flex flex-col min-h-screen">
    <app-header v-if="!showInIframe"></app-header>
    <main class="flex-1 flex flex-col bg-mine-1100" :class="{ 'mt-4': showInIframe}">
      <slot />
    </main>
  </div>
</template>

<script>
  import { pageInIframe } from '../utils/helper'

  export default {
    data() {
      return {
        showInIframe: pageInIframe()
      }
    }
  }
</script>
