<template>
  <div
    :class="`${widthClass} ${heightClass} flex items-center justify-center  whitespace-nowrap bg-mine-700 rounded-sm 0 ${
      active ? 'text-white' : 'text-mine-500'
    } ${
      disabled
        ? 'opacity-50 cursor-not-allowed'
        : 'cursor-pointer transition hover:bg-mine-600 active:bg-mine-500'
    }`"
  >
    <mdi-icon :icon="icon" :size="iconSize" />
  </div>
</template>

<script>
  export default {
    props: {
      active: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
        required: true,
      },
      iconSize: {
        type: String,
        default: '16',
      },
      widthClass: {
        type: String,
        default: 'w-8',
      },
      heightClass: {
        type: String,
        default: 'h-8',
      },
    },
  }
</script>
