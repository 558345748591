<template>
  <div>
    <div class="fixed z-20 inset-0 backdrop-blur flex items-center">
      <app-container class="py-16">
        <div
          class="max-w-[600px] w-full mx-auto bg-mine-1000 rounded overflow-hidden shadow-xl"
        >
          <div class="flex justify-between items-center bg-mine-800 p-1">
            <h1 class="text-white text-xs uppercase tracking-widest pl-3">
              Display Settings
            </h1>
            <flat-icon-button :disabled="loading" icon="close" @click="close" />
          </div>
          <div class="flex">
            <a
              v-for="(tab, index) in tabs"
              :key="index"
              href
              class="flex-1 h-9 flex items-center justify-center text-[13px] border-b transition"
              :class="{
                'border-cerise text-cerise': activeTab == tab,
                'border-mine-700 text-mine-300 hover:border-mine-600 hover:text-mine-200':
                  activeTab != tab,
              }"
              @click.prevent="() => (activeTab = tab)"
            >
              {{ tab }}
            </a>
          </div>
          <div class="px-4 sm:px-12 py-6 min-h-[420px]">
            <div v-show="activeTab == 'Specs'">
              <div class="flex flex-col space-y-6 mx-auto">
                <modal-field-wrapper
                  label="Display Name"
                  v-tooltip="{
                    delay: { show: 1000 },
                    content: 'Name of this Vixi Unreal Display.',
                  }"
                >
                  <text-field v-model="model.name" />
                </modal-field-wrapper>

                <modal-field-wrapper
                  label="Display Key"
                  v-tooltip="{
                    delay: { show: 1000 },
                    content:
                      'Enter your Display Key into the Vixi Unreal Desktop App to link it to Vixi Unreal.',
                  }"
                >
                  <text-copy
                    :text="model.key"
                    copied-message="Display key copied."
                    help-text="Copy the display key to your clipboard."
                  />
                </modal-field-wrapper>

                <modal-field-wrapper
                  label="Resolution"
                  v-tooltip="{
                    delay: { show: 1000 },
                    content:
                      'Pixel resolution of the Vixi Unreal Desktop App (width by height).',
                  }"
                >
                  <div class="flex items-center">
                    <number-field v-model="model.resolution_x" />
                    <mdi-icon
                      icon="close"
                      size="18"
                      class="px-3 text-mine-300"
                    />
                    <number-field v-model="model.resolution_y" />
                  </div>
                </modal-field-wrapper>

                <modal-field-wrapper
                  label="Window Offset"
                  v-tooltip="{
                    delay: { show: 1000 },
                    content:
                      'Pixel offset of the Vixi Unreal Desktop App (to run the app on an alternate monitor).',
                  }"
                >
                  <div class="flex items-center">
                    <span class="pr-3">X</span>
                    <number-field v-model="model.window_offset_x" />
                    <span class="px-3">Y</span>
                    <number-field v-model="model.window_offset_y" />
                  </div>
                </modal-field-wrapper>

                <modal-field-wrapper
                  label="Frames per Second"
                  v-tooltip="{
                    delay: { show: 1000 },
                    content:
                      'How many frames per second the Vixi Unreal Desktop App renders at. Ensure that your monitor refresh rate matches this value.',
                  }"
                >
                  <select-field-custom
                    v-model="model.fps"
                    :options="fpsOptions"
                    class="max-w-[100px]"
                  />
                </modal-field-wrapper>
              </div>
            </div>

            <div v-show="activeTab == 'Event'">
              <div class="flex flex-col space-y-6 mx-auto">
                <modal-field-wrapper
                  label="Type"
                  v-tooltip="{
                    delay: { show: 1000 },
                    content:
                      'The 3D environment that Vixi Unreal Desktop App is rendered in.',
                  }"
                >
                  <select-field-custom
                    v-model="model.event_id"
                    :options="events"
                    class="max-w-[200px]"
                  />
                </modal-field-wrapper>

                <modal-field-wrapper
                  label="Primary Color"
                  v-tooltip="{
                    delay: { show: 1000 },
                    content:
                      'The red, green and blue color values used for the overall look and feel of the 3D environment props and Highlight transitions.',
                  }"
                >
                  <color-field
                    :initial-value="model.event_color_1"
                    @updated:value="(value) => (model.event_color_1 = value)"
                  />
                </modal-field-wrapper>

                <modal-field-wrapper
                  label="Secondary Color"
                  v-tooltip="{
                    delay: { show: 1000 },
                    content:
                      'The red, green and blue color values used as an accent for the look and feel of the 3D environment props and Highlight transitions.',
                  }"
                >
                  <color-field
                    :initial-value="model.event_color_2"
                    @updated:value="(value) => (model.event_color_2 = value)"
                  />
                </modal-field-wrapper>

                <modal-field-wrapper
                  label="Logo"
                  v-tooltip="{
                    delay: { show: 1000 },
                    content:
                      'Your event or team logo (A PNG file with an alpha channel that is at least 512 x 512 pixels in resolution is recommended).',
                  }"
                >
                  <image-field
                    :initial-value="model.event_logo"
                    @updated:value="onSelectEventLogo"
                  />
                </modal-field-wrapper>

                <modal-field-wrapper
                  label="Show Props"
                  v-tooltip="{
                    delay: { show: 1000 },
                    content:
                      'Whether to show 3D props that represent your chosen event type (maximalist) or hide them (minimalist).',
                  }"
                >
                  <checkbox-field v-model="model.show_event_props" />
                </modal-field-wrapper>

                <modal-field-wrapper
                  label="Show Transitions"
                  v-tooltip="{
                    delay: { show: 1000 },
                    content:
                      'Whether to show an animated transition between Highlight Posts and Playlist items (on) or hard cut between items (off).',
                  }"
                >
                  <checkbox-field v-model="model.show_transitions" />
                </modal-field-wrapper>
              </div>
            </div>

            <div v-show="activeTab == 'Timing'">
              <div class="flex flex-col space-y-6 mx-auto">
                <modal-field-wrapper
                  label="Global Speed"
                  v-tooltip="{
                    delay: { show: 1000 },
                    content:
                      'How quickly the Vixi Unreal Desktop App runs, as a multiplier (1 is normal, 2 is double speed, and 0.5 is half speed)',
                  }"
                >
                  <range-slider-field
                    v-model="model.global_speed"
                    :min="0.25"
                    :max="8"
                    :step="0.01"
                  />
                </modal-field-wrapper>

                <modal-field-wrapper
                  label="Transition Speed"
                  v-tooltip="{
                    delay: { show: 1000 },
                    content:
                      'How quickly the Vixi Unreal Desktop App camera transitions between states. When set to it’s maximum value (CUT), the camera will cut between states (no animation).',
                  }"
                >
                  <range-slider-field
                    v-model="model.transition_speed"
                    :min="0.25"
                    :max="8"
                    :step="0.01"
                    :max-text="'CUT'"
                  />
                </modal-field-wrapper>

                <modal-field-wrapper
                  label="Hero / CTA"
                  v-tooltip="{
                    delay: { show: 1000 },
                    content: 'How many seconds to show the Hero / CTA state.',
                  }"
                >
                  <div class="flex">
                    <div class="flex-1 flex items-center">
                      <number-field
                        v-model="model.hero_cta_item_duration"
                        class="!w-[50px] text-center px-2 pr-0"
                      />
                      <span
                        class="px-3 text-xs text-mine-500 whitespace-nowrap tracking-widest"
                        >sec per graphic</span
                      >
                    </div>
                  </div>
                </modal-field-wrapper>

                <modal-field-wrapper label="Moderation">
                  <div class="flex">
                    <div
                      v-tooltip="{
                        delay: { show: 1000 },
                        content:
                          'How many seconds to show each Moderation cycle.',
                      }"
                      class="flex items-center mr-8"
                    >
                      <number-field
                        v-model="model.social_grid_item_duration"
                        class="!w-[50px] text-center px-2 pr-0"
                      />
                      <span
                        class="px-3 text-xs text-mine-500 whitespace-nowrap tracking-widest"
                        >sec per grid</span
                      >
                    </div>
                    <div
                      v-tooltip="{
                        delay: { show: 1000 },
                        content:
                          'How many times to show Moderation in a row. A value of zero (0) will loop the Moderation state indefinitely.',
                      }"
                      class="flex items-center"
                    >
                      <number-field
                        v-model="model.social_grid_cycles"
                        class="!w-[50px] text-center px-2 pr-0"
                      />
                      <span
                        class="pl-3 text-xs text-mine-500 whitespace-nowrap tracking-widest"
                        >cycles</span
                      >
                    </div>
                  </div>
                </modal-field-wrapper>

                <modal-field-wrapper
                  label="Highlight Playlist"
                  v-tooltip="{
                    delay: { show: 1000 },
                    content:
                      'How many seconds to show each Highlight Playlist item.',
                  }"
                >
                  <div class="flex">
                    <div class="flex-1 flex items-center">
                      <number-field
                        v-model="model.highlight_playlist_item_duration"
                        class="!w-[50px] text-center px-2 pr-0"
                      />
                      <span
                        class="px-3 text-xs text-mine-500 whitespace-nowrap tracking-widest"
                        >sec per item</span
                      >
                    </div>
                  </div>
                </modal-field-wrapper>

                <modal-field-wrapper
                  label="Highlight Post"
                  v-tooltip="{
                    delay: { show: 1000 },
                    content: 'How many seconds to show each Highlighted Post.',
                  }"
                >
                  <div class="flex">
                    <div class="flex-1 flex items-center">
                      <number-field
                        v-model="model.highlight_post_item_duration"
                        class="!w-[50px] text-center px-2 pr-0"
                      />
                      <span
                        class="px-3 text-xs text-mine-500 whitespace-nowrap tracking-widest"
                        >sec per post</span
                      >
                    </div>
                  </div>
                </modal-field-wrapper>
              </div>
            </div>

            <div v-show="activeTab == 'Layouts'">
              <modal-field-wrapper label="Moderation Layouts">
                <div class="flex-col space-y-10">
                  <div class="grid grid-cols-3 gap-5">
                    <layout-thumb
                      v-for="(layout, index) in model.social_grid_layouts"
                      :key="index"
                      v-tooltip="{
                        delay: { show: 1000 },
                        content:
                          'Toggle whether this layout is enabled in Moderation or not. When  enabled, it’s likelihood of appearance is determined by the posts you feature to your Tagboard.',
                      }"
                      :type="layout.name"
                      :active="layout.enabled"
                      class="cursor-pointer"
                      @click="() => (layout.enabled = !layout.enabled)"
                    />
                  </div>
                </div>
              </modal-field-wrapper>
            </div>

            <div v-show="activeTab == 'Camera'">
              <modal-field-wrapper label="Zoom">
                <inline-select-field
                  v-model="model.camera_zoom"
                  :options="zoomOptions"
                />
              </modal-field-wrapper>
            </div>
          </div>
          <div class="hidden lg:flex lg:justify-between p-4">
            <div class="flex space-x-3 mb-4 sm:mb-0">
              <flat-button
                v-tooltip="{
                  delay: { show: 1000 },
                  content: 'Permanently delete this Display from Vixi Unreal.',
                }"
                @click="() => (showDeleteModal = true)"
              >
                Delete
              </flat-button>
              <flat-button
                v-tooltip="{
                  delay: { show: 1000 },
                  content:
                    'Reset all Display Settings to their default values.',
                }"
                @click="() => (showResetModal = true)"
              >
                Reset All
              </flat-button>
            </div>
            <div class="flex space-x-3">
              <flat-button
                v-tooltip="{
                  delay: { show: 1000 },
                  content:
                    'Create a copy of this Display with all current Settings, Graphics, Moderation and Highlight configurations.',
                }"
                @click="() => (showDuplicateModal = true)"
              >
                Duplicate
              </flat-button>
              <flat-button
                v-tooltip="{
                  delay: { show: 1000 },
                  content:
                    'Save the current Display Settings and apply to Vixi Unreal App.',
                }"
                type="primary"
                :loading="loading"
                @click="save"
              >
                Save
              </flat-button>
            </div>
          </div>
          <div class="flex justify-end space-x-3 p-4 lg:hidden">
            <flat-icon-button
              v-tooltip="{
                delay: { show: 1000 },
                content: 'Permanently delete this Display from Vixi Unreal.',
              }"
              icon="trash-can"
              @click="() => (showDeleteModal = true)"
            />
            <flat-icon-button
              v-tooltip="{
                delay: { show: 1000 },
                content: 'Reset all Display Settings to their default values.',
              }"
              icon="restart"
              @click="() => (showResetModal = true)"
            />
            <flat-icon-button
              v-tooltip="{
                delay: { show: 1000 },
                content:
                  'Create a copy of this Display with all current Settings, Graphics, Moderation and Highlight configurations.',
              }"
              icon="content-copy"
              @click="() => (showDuplicateModal = true)"
            />
            <flat-icon-button
              v-tooltip="{
                delay: { show: 1000 },
                content:
                  'Save the current Display Settings and apply to Vixi Unreal App.',
              }"
              icon="content-save"
              @click="save"
            />
          </div>
        </div>
      </app-container>
    </div>

    <Transition>
      <destructive-modal
        v-if="showDeleteModal"
        title="Delete Display"
        body="Are you sure you want to delete this display? This action cannot be undone."
        :has-keyword="true"
        :loading="loading"
        @confirmed="deleteDisplay"
        @close="() => (showDeleteModal = false)"
      />
    </Transition>

    <Transition>
      <destructive-modal
        v-if="showResetModal"
        title="Reset All Display Settings"
        body="Are you sure you want to reset all display settings to their defaults? All existing settings will be lost forever."
        keyword="RESET"
        confirm-text="Reset"
        :has-keyword="true"
        :loading="loading"
        @confirmed="onResetSettings"
        @close="() => (showResetModal = false)"
      />
    </Transition>

    <Transition>
      <confirm-modal
        v-if="showDuplicateModal"
        title="Duplicate Display"
        body="Please confirm you want to duplicate this display. This process can take a few minutes to complete."
        :loading="loading"
        @confirmed="onDuplicate"
        @close="() => (showDuplicateModal = false)"
      />
    </Transition>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    props: {
      display: {
        type: Object,
        required: true,
      },
      onSave: {
        type: Function,
        default: () => {},
      },
      onDelete: {
        type: Function,
        default: () => {},
      },
    },

    data() {
      return {
        loading: true,
        isDeleting: false,
        isDuplicating: false,
        model: null,
        activeTab: 'Specs',
        tabs: ['Specs', 'Event', 'Timing', 'Layouts', 'Camera'],
        showDeleteModal: false,
        showResetModal: false,
        showDuplicateModal: false,
        eventLogoFile: null,
        zoomOptions: [
          {
            value: 'wide',
            label: 'Wide',
            help: 'Use a wide camera lens so more of the environment is visible.',
          },
          {
            value: 'medium',
            label: 'Medium',
            help: 'Use a medium camera lens so a mix of the environment and social media content is visible.',
          },
          {
            value: 'tight',
            label: 'Tight',
            help: 'Use a tight camera lens so more of the social media content is visible.',
          },
        ],
        fpsOptions: [
          { value: 30, label: 30 },
          { value: 60, label: 60 },
        ],
      }
    },

    computed: {
      ...mapState('display', {
        selectedDisplay: 'display',
        events: (state) =>
          state.events.map((e) => ({
            value: e.id,
            label: e.name,
          })),
      }),
    },

    async created() {
      this.model = JSON.parse(JSON.stringify(this.display))

      if (!this.model.social_grid_layouts?.length) {
        this.model.social_grid_layouts = [
          { name: '3x1Square', enabled: false },
          { name: '3x1Portrait', enabled: false },
          { name: '3x1Mixed', enabled: false },
          { name: '3x2Square', enabled: false },
          { name: '3x2Mixed', enabled: false },
          { name: '4x1Square', enabled: false },
          { name: '4x1Portrait', enabled: false },
          { name: '5x1Square', enabled: false },
          { name: '5x1Mixed', enabled: false },
        ]
      }

      this.loading = false
    },

    methods: {
      ...mapActions('display', [
        'update',
        'destroy',
        'resetDisplay',
        'duplicate',
      ]),

      close() {
        this.$emit('close')
      },
      onSelectEventLogo(logo) {
        this.eventLogoFile = logo
      },
      async save() {
        this.loading = true
        const data = JSON.parse(JSON.stringify(this.model))
        delete data.default_social_posts
        if (this.eventLogoFile) {
          data.event_logo = this.eventLogoFile
        } else {
          delete data.event_logo
        }
        const res = await this.update(data)
        if (res) {
          await this.onSave()
          this.$store.dispatch('app/showAlert', {
            message: 'Display settings updated.',
            type: 'success'
          })
          this.close()
        }
        this.loading = false
      },
      async deleteDisplay() {
        this.loading = true
        await this.destroy(this.model.key)
        if (this.model.key == this.selectedDisplay?.key) {
          this.$router.push('/app')
        }
        await this.onDelete()
        this.loading = false
        this.close()
      },
      async onResetSettings() {
        this.loading = true
        const res = await this.resetDisplay(this.model.key)
        if (res) {
          await this.onSave()
          this.close()
        }
        this.loading = false
        this.showResetModal = false
      },
      async onDuplicate() {
        this.loading = true
        const res = await this.duplicate(this.model.key)
        if (res) {
          this.close()
        }
        this.loading = false
      },
    },
  }
</script>
