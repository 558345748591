import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueClickAway from 'vue3-click-away'
import VTooltipPlugin from 'v-tooltip'
import Draggable from 'vuedraggable'
import { Collapse } from 'vue-collapsed'
import '@mdi/font/css/materialdesignicons.css'
import './assets/scss/app.scss'
import router from './router'
import store from './store'
import App from './App.vue'
import './echo'
import { auth0Provider } from './auth0';
import { Userpilot } from 'userpilot';

const app = createApp(App)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(router),
    Sentry.replayIntegration({
      blockAllMedia: false,
      maskAllInputs: false,
      maskAllText: false,
    }),
    Sentry.browserProfilingIntegration(),
  ],
  tracingOrigins: [import.meta.env.VITE_APP_BASE_URL, import.meta.env.VITE_API_BASE_URL],
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,
  environment: import.meta.env.VITE_MODE || 'local',
  tunnel: '/api/v2/tnl',
})

app
  .use(router)
  .use(store)
  .use(VueAxios, axios)
  .use(VueClickAway)
  .use(VTooltipPlugin)
  .use(auth0Provider)
  .component('VueCollapse', Collapse)
  .component('VueDraggable', Draggable)

/** App components */
const components = import.meta.globEager('./components/**/*.vue')
Object.entries(components).forEach(([path, definition]) => {
  const componentName = path
    .split('/')
    .pop()
    .replace(/\.\w+$/, '')
  app.component(componentName, definition.default)
})

Userpilot.initialize(import.meta.env.VITE_USERPILOT_TOKEN);

app.mount('#app')