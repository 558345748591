import axios from 'axios'

export default {
  namespaced: true,
  state: {
    removedPost: null,
  },
  mutations: {
    setRemovedPost(state, value) {
      state.removedPost = value
    },
  },
  actions: {
    authenticate({}, redirectTo) {
      window.location.assign(
        `${
          import.meta.env.VITE_TAGBOARD_USER_URL
        }/auth/jwt?returnUrl=${redirectTo}`
      )
    },

    async getUser({ dispatch }) {
      const http = await dispatch('http')
      const response = await http.get(
        import.meta.env.VITE_TAGBOARD_USER_URL + '/info'
      )

      if (response?.data?.id) {
        return response.data
      }

      return null
    },

    async getTagboards({ dispatch }, userId) {
      const http = await dispatch('http')
      const response = await http.get(
        import.meta.env.VITE_TAGBOARD_BOARD_URL + '/tagboards/' + userId
      )

      if (response?.data?.results) {
        return response.data.results
      }

      return null
    },

    async getPosts({ dispatch }, tagboardId) {
      const http = await dispatch('http')
      const response = await http.get(
        import.meta.env.VITE_TAGBOARD_API_URL + '/v1.0/featured/' + tagboardId
      )

      if (response?.data) {
        return response.data
      }

      return null
    },

    async getPost({ dispatch }, { tagboardId, postId, network }) {
      const http = await dispatch('http')
      const response = await http.get(
        import.meta.env.VITE_TAGBOARD_API_URL + '/v1.0/post/' + tagboardId,
        {
          params: {
            post_id: postId,
            network: network,
          },
        }
      )

      if (response?.data?.post) {
        return response.data.post
      }

      return null
    },

    async removePost({ commit, dispatch }, { tagboardId, postId, network }) {
      const http = await dispatch('http')
      const response = await http.delete(
        import.meta.env.VITE_TAGBOARD_STORY_URL + '/uncurate/' + tagboardId,
        {
          data: {
            post_id: postId,
            network: network,
          },
        }
      )

      commit('setRemovedPost', postId)

      return response?.status == 200
    },

    async reorderPosts({ dispatch }, { tagboardId, posts }) {
      const http = await dispatch('http')
      const response = await http.put(
        import.meta.env.VITE_TAGBOARD_STORY_URL + '/reorder/' + tagboardId,
        { posts: posts.reverse() }
      )
      return response?.status == 200
    },

    http({ dispatch, commit }) {
      const http = axios.create({
        timeout: 3600000,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('tbAccessToken'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })

      http.interceptors.response.use(
        (response) => {
          return response
        },
        async (error) => {
          if (error?.response?.data?.location) {
            window.location = error.response.data.location
            return error
          } else if (error?.response?.status == 401) {
            commit('app/setShowTagboardLogin', true, { root: true })
            await dispatch(
              'app/showAlert',
              { type: 'error', message: `The Tagboard session expired. Please login again.`, timeout: 5000 },
              { root: true }
            )
            return error
          }

          dispatch(
            'app/errorAlert',
            {
              message:
                'A network or Tagboard API error has ocurred. Please try again.',
            },
            { root: true }
          )

          return Promise.reject(error)
        }
      )

      return http
    },
  },
}
