<template>
  <div :id="`post-${post.id}`" class="flex justify-center">
    <mdi-icon
      v-if="draggable"
      icon="drag-vertical"
      class="text-mine-500 handle cursor-grab"
    />
    <div class="h-min relative bg-mine-800 rounded w-44">
      <div class="flex p-3 min-h-[60px]">
        <div class="flex flex-col max-w-[80%]">
          <h3 class="text-xs text-ellipsis overflow-hidden whitespace-nowrap">
            {{ post.account_name }}
          </h3>
          <h4 v-show="post.account_handle" class="text-xs text-mine-300 mt-1">@{{ post.account_handle }}</h4>
        </div>
        <div class="relative ml-auto">
          <flat-icon-button
            v-if="!viewingDisplayAsSuperadmin"
            icon="dots-vertical"
            width-class="w-6"
            @click="() => (showContextMenu = true)"
          />
          <div
            v-if="showContextMenu"
            class="absolute z-10 right-0"
            v-click-away="() => (showContextMenu = false)"
          >
            <context-menu :menu="contextMenu()" />
          </div>
        </div>
      </div>
      <div
        class="relative h-32 rounded-sm overflow-hidden group bg-mine-700"
        :class="{
          'ring-2 ring-red': draggable && post.coming_up && post.last_played_order > 0,
          'ring-2 ring-cerise': draggable && !post.coming_up && post.last_played_order > 0,
          'ring-2 ring-golden': draggable && post.coming_up,
          'cursor-pointer': post.permalink,
        }"
        @click="onClickPost(post)"
      >
        <div
          v-if="post.image_orientation"
          class="absolute left-0 top-0 bg-black/60 text-white text-[10px] px-1 rounded-br-sm text-center whitespace-nowrap hidden group-hover:block"
        >
          {{ `${post.image_width} x ${post.image_height} (${post.image_orientation})` }}
        </div>
        <div
          v-if="draggable && post.coming_up"
          class="absolute right-0 bottom-0 text-xs w-5 rounded-tl-sm text-center"
          :class="{
            'bg-red text-white': post.last_played_order > 0,
            'bg-golden text-black': post.last_played_order == 0,
          }"
        >
          {{ post.coming_up_order }}
        </div>
        <image-crop
          v-if="post.image_orientation"
          class="absolute z-[1] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          :stroke-width="3"
          :style="cropSize"
          :type="post.image_orientation == 'portrait' ? 'portrait' : 'square'"
          :color="
            draggable && post.coming_up && post.last_played_order > 0
              ? '#fd3636'
              : draggable && !post.coming_up && post.last_played_order > 0
              ? '#D52265'
              : draggable && post.coming_up
              ? '#fec651'
              : '#e3e3e3'
          "
        />
        <loading-image
          v-if="post.original_image_url"
          :image-url="post.original_image_url"
          size-classes="h-32 w-full"
          background-class="object-contain"
        />
        <div v-else class="h-32 flex items-center justify-center text-xs text-mine-300">
          Text only
        </div>
      </div>
      <div
        class="p-3"
        v-tooltip="{
          delay: { show: 1000 },
          content: post.message,
        }"
      >
        <h4
          class="text-xs text-mine-300 h-[70px] overflow-hidden text-ellipsis whitespace-normal"
          :style="`display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;`"
        >
          <div class="flex items-center space-x-2 mb-1">
            <img
              v-if="post.network"
              :src="`/img/${post.network}.svg`"
              :alt="post.network"
              class="h-3 w-auto"
            />
            <div class="text-mine-500 flex-1">{{ time }}</div>
            <mdi-icon
              v-if="post.media_type == 'video'"
              icon="play-circle-outline"
              size="18"
              class="w-5"
            />
          </div>
          <div>{{ post.message }}</div>
        </h4>
      </div>
      <div
        class="text-mine-500 border-t uppercase text-xs tracking-widest whitespace-n owrap py-1 px-3 flex justify-between items-center "
        :class="{ 'cursor-default': viewingDisplayAsSuperadmin, 'cursor-pointer': !viewingDisplayAsSuperadmin}"
        v-tooltip="viewingDisplayAsSuperadmin ? {
                  delay: { show: 100 },
                  content: 'Unavailable in superadmin mode',
                } : null"
        @click="!viewingDisplayAsSuperadmin && manageState(post)"
      >
        <div>
          {{ textApproved() }}
        </div>
        <div class="flex">
          <span
            class="mdi text-base"
            :class="{ 'text-cerise': this.post.approved && !viewingDisplayAsSuperadmin, 'mdi-check-circle': post.approved, 'mdi-checkbox-blank-circle-outline': !post.approved }"
          />
        </div>
      </div>
    </div>
  </div>

  <Transition>
    <destructive-modal
      v-if="showDeletePostModal"
      title="Remove Post"
      body="Are you sure you want to remove this post? "
      @close="() => (showDeletePostModal = false)"
      @confirmed="onDeletePost"
    />
  </Transition>
</template>

<script>
import moment from "moment"
import { mapState, mapActions, mapGetters } from "vuex"
import { postToApi } from "/src/utils/post_utils"

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    presentation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showContextMenu: false,
      showDeletePostModal: false,
    }
  },
  emits: ['change:approvedState', 'delete:socialGridPost'],
  computed: {
    ...mapState("display", ["display"]),
    ...mapGetters("display", ["viewingDisplayAsSuperadmin"]),

    time() {
      return moment(this.post.date).fromNow()
    },

    cropSize() {
      let renderedWidth, renderedHeight
      let elWidth = 176
      let elHeight = 128

      // Aspect ratios
      let imgRatio =
        this.post?.image_width && this.post?.image_height
          ? this.post.image_width / this.post.image_height
          : 0
      let elRatio = elWidth / elHeight

      // Check which dimension is the limiting one
      if (elRatio > imgRatio) {
        // Div is "wider" (in terms of aspect ratio), so image is limited by height
        renderedHeight = elHeight
        renderedWidth = elHeight * imgRatio
      } else {
        // Div is "taller", so image is limited by width
        renderedWidth = elWidth
        renderedHeight = elWidth / imgRatio
      }

      return {
        width: `${renderedWidth}px`,
        height: `${renderedHeight}px`,
      }
    },
  },

  methods: {
    ...mapActions("app", ["errorAlert"]),
    ...mapActions("display", ["setPresentation"]),

    contextMenu() {
      return [
        {
          show: true,
          icon: "plus-circle-outline",
          label: "Add to Highlight Playlist",
          action: async () => {
            const post = (this.post.source_category == 'tagboard') ? await this.getTagboardPost() : this.post

            if (post) {
              await this.$store.dispatch("presentation/addPostToPlaylist", {
                presentation: this.display.presentations.find(
                  (e) => e.name == "highlightPlaylist"
                ),
                post:  (this.post.source_category == 'tagboard') ? postToApi(post, this.post.source_entity_id): post,
                successMessage: "Added to Highlight Playlist.",
              })
            } else {
              this.errorAlert({
                message: "Could not fetch post from Tagboard.",
              })
            }

            this.showContextMenu = false
          },
        },
        {
          show: true,
          icon: "plus-circle-outline",
          label: "Highlight Post",
          action: async () => {
            const post = (this.post.source_category == 'tagboard') ? await this.getTagboardPost() : this.post

            if (post) {
              await this.$store.dispatch("presentation/addPostToPlaylist", {
                presentation: this.display.presentations.find(
                  (e) => e.name == "highlightPost"
                ),
                post:  (this.post.source_category == 'tagboard') ? postToApi(post, this.post.source_entity_id): post,
                moveToStart: true,
                setPresentation: true,
                successMessage: "Highlighted post.",
              })
            } else {
              this.errorAlert({
                message: "Could not fetch post from Tagboard.",
              })
            }

            this.showContextMenu = false
          },
        },
        // {
        //   show: true,
        //   icon: 'sync',
        //   label: 'Refresh Post',
        //   action: () => {
        //     console.log('refresh post', this.post)
        //   },
        // },
        {
          show: true,
          icon: "trash-can",
          label: "Remove Post",
          action: () => {
            this.showDeletePostModal = true
          },
        },
      ]
    },

    async onDeletePost() {
      if (this.post.source_category === 'tagboard') {
        try {
          await this.$store.dispatch("app/tbRequest", {
            method: "removePost",
            params: {
              tagboardId: this.post.source_entity_id,
              postId: this.post.source_id,
              network: this.post.network,
            },
          })
        } catch (error) {
          console.log("Could not remove post from tagboard.", error)
        }
      }
      await this.$store.dispatch('post/destroy', { postId: this.post.id }, { root: true })
      this.$emit('delete:socialGridPost', this.post);
      this.showDeletePostModal = false
    },

    async getTagboardPost() {
      const data = await this.$store.dispatch("app/tbRequest", {
        method: "getPost",
        params: {
          tagboardId: this.post.source_entity_id,
          postId: this.post.source_id,
          network: this.post.network,
        },
      })
      return data
    },

    onClickPost(post) {
      if (post.permalink) {
        window.open(post.permalink, "_blank")
      }
    },

    textApproved() {
      if (this.post.approved) {
        return "post approved"
      }
      return "approve"
    },

    async manageState() {
      if (!this.post.approved) {
        this.post.approved = true
        await this.$store.dispatch("presentation/approvePost", {
          presentation: this.presentation,
          post: this.post,
        })
      } else {
        this.post.approved = false
        await this.$store.dispatch("presentation/disapprovePost", {
          presentation: this.presentation,
          post: this.post,
        })
      }
      this.$emit('change:approvedState', this.post);
    },
  },
}
</script>
