export default {
  namespaced: true,
  state: {
    page: 1,
    posts: [],
    loadedPages: [],
    nextPageUrl: null,
    filters: {
      keyword: '',
      approved: 'all',
      sourceCategory: localStorage.getItem('selectedSource') || 'all' ,
      sourceEntityId: localStorage.getItem('selectedSourceEntity') || 'all'
    }
  },
  getters: {
    getPosts: (state) => state.posts,
    getPage: (state) => state.page,
    getLoadedPages: (state) => state.loadedPages,
    getNextPageUrl: (state) => state.nextPageUrl,
    getFilters: (state) => state.filters,
  },
  mutations: {
    reset(state) {
      state.page = 1
      state.posts = []
      state.loadedPages = []
      state.nextPageUrl = null
      state.filters = {
        keyword: '',
        approved: 'all',
        sourceCategory: localStorage.getItem('selectedSource') || 'all' ,
        sourceEntityId: localStorage.getItem('selectedSourceEntity') || 'all'
      }
    },

    resetPagination(state) {
      state.page = 1
      state.posts = []
      state.loadedPages = []
    },

    setPosts(state, posts) {
      state.posts = posts
    },

    removePost(state, postId) {
      state.posts = state.posts.filter((p) => p.id !== postId)
    },

    clearPosts(state) {
      state.posts = [];
    },

    setPagePosts(state, { posts, nextPageUrl }) {
      state.posts = [...state.posts, ...posts];
      state.loadedPages.push(state.page)
      state.nextPageUrl = nextPageUrl
    },

    setNextPage(state) {
      state.page++
    },

    setFilters(state, filters) {
      Object.keys(filters).forEach((key) => {
        state.filters[key] = filters[key]
      })
    }
  },
  actions: {
    async destroy({ dispatch, rootGetters }, { postId, sourceCategory }) {
      let endpoint = `/v1/posts/${postId}`

      const organizationId = rootGetters['organization/organization']?.id
      if (organizationId) {
        endpoint += `?organizationId=${organizationId}`
      }
      if (sourceCategory) {
        endpoint += `&source_category=${sourceCategory}`
      }

      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'delete',
          endpoint,
          headers: {
            'Organization-Token': rootGetters['organization/activeOrgToken']
          },
        },
        { root: true }
      )
      if (res && res.status == 200) {
        dispatch(
          'app/updateAlert',
          { message: 'Post removed.' },
          { root: true }
        )
      }
    },

    async loadPosts({ dispatch, state, commit, rootGetters }, isPagination = false) {
      const filters = { ...state.filters, page: state.page }

      if (filters.approved === "all") {
        delete filters.approved
      }
      if (filters.sourceCategory === "all") {
        delete filters.sourceCategory
      }
      if (filters.sourceEntityId === "all") {
        delete filters.sourceEntityId
      }

      if (rootGetters['display/viewingDisplayAsSuperadmin']) {
        filters.approved = 1
        delete filters.sourceEntityId
      }

      let queryString = new URLSearchParams(filters).toString()
      const organizationId = rootGetters['organization/organization']?.id;
      if (organizationId) {
        queryString += `&organizationId=${organizationId}`
      }
      const tagboardToken = localStorage.getItem('tbAccessToken') ? {'Tagboard-Token': localStorage.getItem('tbAccessToken')} : {}
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'get',
          endpoint: `/v1/posts?${queryString}`,
          headers: {
            ...tagboardToken
          },
        },
        { root: true }
      )
      const data = res.data && res.data.data
      if (res.status == 200 && data) {
        const { approved, keyword } = state.filters
        const pagination = isPagination && approved !== 1
        const search = keyword === ''
        if (pagination && search) {
          commit("setPagePosts", { posts: data, nextPageUrl: res.data.next_page_url })
        } else {
          commit('setPosts', data);
        }
      }
    }
  },
}
