<template>
  <Transition>
    <content-modal
      v-if="showModal"
      :showCancel="false"
      title="Login required"
      modalCss="max-w-[600px]"
      actionCss="flex justify-center px-4 pb-12"
    >
      <div class="flex flex-col items-center">
        <img src="/img/tagboard_logo.svg" alt="VIXI" class="mt-6 mb-5"/>
        <p class="mb-1 text-center">In order to see your Tagboard data you need to login</p>
        <p class="text-xs text-mine-400 text-center mb-6">To continue, please click the button below.</p>
      </div>
      <template v-slot:actions>
        <flat-button class="ml-3" @click="tagboardLogin">Login with Tagboard</flat-button>
      </template>  
    </content-modal>
  </Transition>
</template>

<script>
  export default {
    props: {
      showModal: {
        type: Boolean,
        default: false
      }
    },
    emits: ['tagboard:login'],
    methods: {
      tagboardLogin() {
        this.$emit("tagboard:login")
      }
    }
  }
</script>