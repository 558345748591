<template>
  <div class="flex flex-1">
    <dropdown-base
      :options="sources"
      :selectedValue="selectedSource"
      @update:selectedValue="updateSelectedSource"
      :colon="true"
    />
    <dropdown-base
      :options="sourceEntities"
      :selectedValue="selectedSourceEntity"
      @update:selectedValue="updateSelectedSourceEntity"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      sources: 'sourceEntity/getSources', 
      sourceEntities: 'sourceEntity/getSourceEntities',
      selectedSource: 'sourceEntity/getSelectedSource',
      selectedSourceEntity: 'sourceEntity/getSelectedSourceEntity'
    }),
  },
  async created() {
    await this.fetchSourceEntities()

    this.$emit("update:filters", {
      sourceCategory: this.selectedSource,
      sourceEntityId: this.selectedSourceEntity,
    })
  },
  methods: {
    async fetchSourceEntities() {
      try {
        await this.$store.dispatch("sourceEntity/fetchEntities")
      } catch (error) {
        console.error("Error fetching source entities:", error)
      }
    },

    async updateSelectedSource(selectedValue) {
      this.$store.commit('sourceEntity/setSelectedSource', selectedValue)
      await this.fetchSourceEntities()
      this.updateFilters();
    },

    async updateSelectedSourceEntity(selectedValue) {
      this.$store.commit('sourceEntity/setSelectedSourceEntity', selectedValue)
      this.updateFilters();
    },

    updateFilters() {
      this.$emit("update:filters", {
        sourceCategory: this.selectedSource,
        sourceEntityId: this.selectedSourceEntity,
      })
    }
  },
}
</script>
