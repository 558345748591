<template>
  <auth-layout :title="notAuthorize.title">
    <p class="text-center text-suite-gray font-medium">{{ notAuthorize.message }}</p>
  </auth-layout>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    computed: {
      ...mapState('app', ['notAuthorize']),
    }
  }
</script>
