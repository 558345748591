import { loadPostDimensions } from '/src/utils/post_utils'

export default {
  namespaced: true,
  state: {
    presentation: null,
    highlightPlaylistPosts: [],
    highlightPosts: [],
    socialGridIndex: 1,
  },
  getters: {
    visibleHPLPosts(state) {
      return state.highlightPlaylistPosts
        .filter((e) => e.is_visible)
        .map((e) => e.id)
    },
  },
  mutations: {
    reset(state) {
      state.presentation = null
      state.highlightPlaylistPosts = []
      state.highlightPosts = []
    },
    setPresentation(state, presentation) {
      state.presentation = {
        ...state.presentation,
        ...presentation
      }
    },
    setPresentationLayout(state, layout) {
      state.presentation.layout = layout
    },
    setPosts(state, { name, posts }) {
      if (name == 'highlightPlaylist') {
        state.highlightPlaylistPosts = posts
      } else if (name == 'highlightPost') {
        state.highlightPosts = posts
      }
    },
    updatePost(state, post) {
      const posts = state[`${state.presentation.name}Posts`]
      const index = posts.findIndex((e) => e.id == post.id)
      posts[index] = post
    },
    setSocialGridIndex(state, index) {
      state.socialGridIndex = index
    },
  },
  actions: {
    async loadPosts({ state, commit, dispatch }, filters = '') {
      let queryString = ''
      if (filters) {
        delete filters['presentationId'];
        queryString = `?${new URLSearchParams(filters).toString()}`
      }

      const presentation = state.presentation
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'get',
          endpoint: `/v1/presentations/${presentation.id}/posts${queryString}`,
        },
        { root: true }
      )
      if (res.status == 200 && res.data?.data) {
        commit('setPosts', { name: presentation.name, posts: res.data.data })
      }
    },

    async savePosts(
      { dispatch },
      { presentation, posts, refresh = false, moveToStart = false }
    ) {
      const loadedPosts = []
      for (let i = 0; i < posts.length; i++) {
        const post = await loadPostDimensions(posts[i])
        loadedPosts.push(post)
      }
      await dispatch(
        'app/apiRequest',
        {
          method: 'post',
          endpoint: `/v1/presentations/${presentation.id}/posts`,
          data: {
            refresh: refresh,
            move_to_start: moveToStart,
            posts: loadedPosts,
          },
        },
        { root: true }
      )
    },

    async addPostToPlaylist(
      { rootState, state, commit, dispatch },
      {
        presentation,
        post,
        setPresentation = false,
        moveToStart = false,
        successMessage = null,
      }
    ) {
      if (setPresentation) {
        if (!rootState.display.display.is_connected) {
          return dispatch(
            'app/errorAlert',
            {
              message:
                'Vixi Unreal Desktop App is disconnected. Please download and run Vixi Unreal App.',
            },
            { root: true }
          )
        }
        commit('display/setTransitioning', true, { root: true })
      }
      const loadedPost = await loadPostDimensions(post)
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'post',
          endpoint: `/v1/presentations/${presentation.id}/add-to-playlist`,
          data: {
            presentation: presentation.name,
            post: loadedPost,
            move_to_start: moveToStart,
            set_presentation: setPresentation,
          },
        },
        { root: true }
      )
      if (res.status == 200 && res.data?.data) {
        if (successMessage) {
          dispatch(
            'app/updateAlert',
            { message: successMessage },
            { root: true }
          )
        }
        if (presentation.id == state.presentation.id) {
          commit('setPosts', { name: presentation.name, posts: res.data.data })
        }
      } else {
        commit('display/setTransitioning', false, { root: true })
      }
    },

    async updatePost({ state, commit, dispatch }, post) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'put',
          endpoint: `/v1/presentations/${state.presentation.id}/posts/${post.id}`,
          data: {
            is_visible: post.is_visible,
          },
        },
        { root: true }
      )
      if (res.status == 200 && res.data?.data) {
        commit('updatePost', res.data.data)
      }
    },

    async deletePost({ state, dispatch }, post) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'delete',
          endpoint: `/v1/presentations/${state.presentation.id}/posts/${post.id}`,
        },
        { root: true }
      )

      if (res.status == 200) {
        dispatch(
          'app/updateAlert',
          { message: 'Post removed.' },
          { root: true }
        )
      }
    },

    async reorderPosts({ state, dispatch }, order) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'post',
          endpoint: `/v1/presentations/${state.presentation.id}/posts-reorder`,
          data: { order },
        },
        { root: true }
      )

      return res.status == 200
    },

    async clearPosts({ state, commit, dispatch }, { successMessage = null }) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'delete',
          endpoint: `/v1/presentations/${state.presentation.id}/posts-clear`,
        },
        { root: true }
      )

      commit('setPosts', { name: state.presentation.name, posts: [] })
      if (res.status == 200) {
        if (successMessage) {
          dispatch(
            'app/showAlert',
            { message: successMessage, type: 'success' },
            { root: true }
          )
        }
        return true
      }

      return false
    },
    async approvePost({ dispatch, commit }, { presentation, post }) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'post',
          endpoint: `/v1/presentations/${presentation.id}/approve/${post.id}`,
        },
        { root: true }
      )
      if (res.status == 201) {
        commit('setPresentation', res.data.presentation)
        dispatch(
          'app/updateAlert',
          { message: 'Post approved.' },
          { root: true }
        )
        return true
      }
      return false
    },

    async disapprovePost({ dispatch, commit }, { presentation, post }) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'delete',
          endpoint: `/v1/presentations/${presentation.id}/posts/${post.id}`,
        },
        { root: true }
      )

      if (res.status == 200) {
        commit('setPresentation', res.data.presentation)
        dispatch(
          'app/updateAlert',
          { message: 'Post disapproved.' },
          { root: true }
        )
      }
    },
  },
}
