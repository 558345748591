<template>
  <div class="relative">
    <button
      @click="() => (active = !active)"
      class="hidden lg:flex items-center justify-between bg-mine-700 rounded-sm z-0 appearance-none w-full focus:outline-none text-sm text-left py-1 pl-3 pr-1 transition enabled:hover:bg-mine-600 enabled:active:bg-mine-500"
    >
      <span>{{ label }}</span>
      <mdi-icon icon="chevron-down" class="text-neutral-300" />
    </button>
    <flat-icon-button
      class="lg:hidden"
      :icon="mobileIcon"
      @click="() => (active = !active)"
    />
    <div
      v-show="active"
      v-click-away="() => (active = false)"
      class="absolute z-40 right-0 bg-mine-800 p-4 flex flex-col space-y-3 rounded-sm border border-mine-600"
    >
      <checkbox-field
        v-for="(option, index) in value"
        v-model="option.value"
        :key="index"
        :label="option.label"
      />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      modelValue: {
        type: [Array],
        default: null,
      },
      label: {
        type: String,
        required: true,
      },
      mobileIcon: {
        type: String,
        required: true,
      },
    },
    emits: ['update:modelValue'],
    data() {
      return {
        active: false,
      }
    },
    computed: {
      value: {
        get() {
          return this.modelValue
        },
        set(value) {
          this.$emit('update:modelValue', value)
        },
      },
    },
  }
</script>
