<template>
  <input
    v-model="value"
    type="number"
    class="appearance-none bg-mine-700 w-full focus:outline-none text-sm px-3 py-1.5 rounded-sm"
  />
</template>

<script>
  export default {
    props: {
      modelValue: {
        type: [Number, String, null],
        default: null,
      },
      type: {
        type: String,
        default: 'text',
      },
    },
    emits: ['update:modelValue'],
    computed: {
      value: {
        get() {
          return this.modelValue
        },
        set(value) {
          this.$emit('update:modelValue', value)
        },
      },
    },
  }
</script>
