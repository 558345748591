const { VITE_AUTH0_DOMAIN, VITE_AUTH0_CLIENTID, VITE_APP_BASE_URL, VITE_AUTH0_AUDIENCE } = import.meta.env
import { createAuth0 } from '@auth0/auth0-vue';

const auth0Provider = createAuth0({
  domain: VITE_AUTH0_DOMAIN,
  clientId: VITE_AUTH0_CLIENTID,
  authorizationParams: {
    redirect_uri: VITE_APP_BASE_URL,
    audience: VITE_AUTH0_AUDIENCE,
  }
});

export {auth0Provider}