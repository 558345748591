<template>
  <input
    v-model="value"
    :type="type"
    class="appearance-none bg-mine-700 w-full focus:outline-none text-sm px-3 py-1.5 rounded-sm transition placeholder:text-mine-500 disabled:opacity-50 disabled:cursor-not-allowed enabled:hover:bg-mine-600"
  />
</template>

<script>
  export default {
    props: {
      modelValue: {
        type: [String, Number, null],
        default: null,
      },
      type: {
        type: String,
        default: 'text',
      },
    },
    emits: ['update:modelValue'],
    computed: {
      value: {
        get() {
          return this.modelValue
        },
        set(value) {
          this.$emit('update:modelValue', value)
        },
      },
    },
  }
</script>
