import ApiError from '/src/errors/api_error'
import { postToApi } from '/src/utils/post_utils'

export default {
  namespaced: true,
  state: {
    displays: [],
    display: null,
    events: [],
    transitioning: false,
    counters: {
      countDown: 0,
      countUp: 0,
      countDownInterval: null,
      betweenStates: true,
    },
  },
  getters: {
    displayEvent: (state) =>
      !!state.display?.event_id && !!state.events.length
        ? state.events.find((e) => e.id == state.display.event_id)
        : null,
    displayHasDefaultPostsSet: (state) => {
      const defaultPosts = state.display?.default_social_posts
      const totalDefaultPosts =
        defaultPosts?.stills?.filter((e) => Boolean(e?.url)).length +
        defaultPosts?.videos?.filter((e) => Boolean(e?.url)).length
      return totalDefaultPosts >= 3
    },
    displayHasBillboardsSet: (state, getters) => {
      if (!state.display) return false
      for (let i = 0; i < state.display?.presentations?.length ?? 0; i++) {
        const presentation = state.display.presentations[i]
        for (let j = 0; j < presentation?.billboards?.length ?? 0; j++) {
          const billboard = presentation?.billboards[j]
          if (
            !Boolean(billboard.media.length) ||
            !Boolean(billboard.media[0].url)
          ) {
            return false
          }
        }
      }
      if (Boolean(getters.displayEvent?.props?.length)) {
        for (let i = 0; i < getters.displayEvent.props.length; i++) {
          const prop = getters.displayEvent.props[i]
          if (
            !Boolean(state.display.event_props[prop.type]?.length) ||
            !Boolean(state.display.event_props[prop.type][0].url)
          ) {
            return false
          }
        }
      }
      return true
    },
    canSetPresentation: (state, getters) =>
      getters.displayHasDefaultPostsSet &&
      getters.displayHasBillboardsSet,
    canGoToPrevPost: (state) =>
      (state.display?.presentation == 'highlightPost' ||
        state.display?.presentation == 'highlightPlaylist') &&
      state.display?.currentPost > 0,
    viewingDisplayAsSuperadmin: (state, _, rootState) =>
      rootState.auth.apiUser?.is_superadmin &&
      state.display?.user?.id !== rootState.auth.apiUser?.id,
  },
  mutations: {
    reset(state) {
      state.displays = []
      state.display = null
      state.events = []
      state.transitioning = false
    },
    setDisplays(state, displays) {
      state.displays = displays
    },
    setDisplay(state, display) {
      state.display = display
    },
    setDisplayPresentation(state, { key, presentation }) {
      let display = state.displays.find((d) => d.key === key)

      if (display) {
        display.presentation = presentation
      }

      if (state.display?.key == key) {
        state.display.presentation = presentation
      }
    },
    setDisplayConnection(state, { key, connection }) {
      let display = state.displays.find((d) => d.key === key)
      if (display) {
        display.is_connected = connection
        if (!connection) {
          display.presentation = null
          display.auto_mode = false
        }
      }

      if (state.display?.key == key) {
        state.display.is_connected = connection
        if (!connection) {
          state.display.presentation = null
          state.display.auto_mode = false
        }
      }
    },
    setDisplayUpdatedAt(state, { key, timestamp }) {
      let display = state.displays.find((d) => d.key === key)
      if (display) {
        display.updated_at = timestamp
      }

      if (state.display?.key == key) {
        state.display.updated_at = timestamp
      }
    },
    setDisplayNewValue(state, payload) {
      const { key, ...newValues } = payload

      const display = state.displays.find((d) => d.key === key)
      if (display) {
        Object.keys(newValues).forEach((keyOject) => {
          display[keyOject] = newValues[keyOject]
        })
      }
    },
    setEvents(state, events) {
      state.events = events
    },
    setDisplayCurrentPost(state, post) {
      if (state.display) {
        state.display.currentPost = post
      }
    },
    setTransitioning(state, status) {
      state.transitioning = status
    },
    incrementCountUp(state) {
      state.counters.countUp++
    },
    decrementCountDown(state) {
      if (state.counters.countDown > 0) {
        state.counters.countDown--
      } else {
        state.counters.betweenStates = true
        state.counters.countUp = 0
      }
    },
    resetCounters(state) {
      state.counters.countDown = 0
      state.counters.countUp = 0
    },
  },
  actions: {
    async loadAll({ state, dispatch, commit }) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'get',
          endpoint: '/v1/displays',
        },
        { root: true }
      )
      if (res.status == 200 && res.data?.data) {
        commit('setDisplays', res.data.data)
        state.displays.forEach((display) => {
          dispatch('subscribeToDisplay', display.key)
        })
      }
    },

    async reorder({ dispatch }, order) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'post',
          endpoint: `/v1/displays-reorder`,
          data: { order },
        },
        { root: true }
      )

      return res.status == 200
    },

    async loadDisplay({ dispatch, commit }, key) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'get',
          endpoint: `/v1/displays/${key}`,
        },
        { root: true }
      )
      if (res.status == 200 && res.data?.data) {
        commit('setDisplay', res.data.data)
        dispatch('subscribeToDisplay', res.data.data.key)
        if (res.data.data?.tagboard_id != null) {
          dispatch('subscribeToTagboard', { tagboardId: res.data.data.tagboard_id })
        }
        commit('setTransitioning', false)
      }
    },

    async create({ dispatch }) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'post',
          endpoint: '/v1/displays',
        },
        { root: true }
      )
      if (res.status == 201 && res.data?.data) {
        await dispatch('loadAll')
        return res.data.data
      }
      return null
    },

    async update({ state, commit, dispatch }, payload) {
      if (payload.presentation == 'init') {
        payload.presentation = null
      }

      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'post',
          endpoint: `/v1/displays/${payload.key}`,
          data: payload,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        { root: true }
      )

      if (res.status == 200 && res.data?.data) {
        if (state.display?.id === res.data.data?.id) {
          commit('setDisplay', res.data.data)
        }
        return res.data.data
      }

      return null
    },

    async destroy({ dispatch }, key) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'delete',
          endpoint: `/v1/displays/${key}`,
        },
        { root: true }
      )
      if (res.status == 200) {
        await dispatch('loadAll')
        dispatch(
          'app/showAlert',
          { message: 'Display deleted.', type: 'success' },
          { root: true }
        )
      }
    },

    async resetDisplay({ state, commit, dispatch }, key) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'get',
          endpoint: `/v1/displays/${key}/reset`,
        },
        { root: true }
      )
      if (res.status == 200 && res.data?.data) {
        dispatch(
          'app/showAlert',
          { message: 'Display reset.', type: 'success' },
          { root: true }
        )
        if (state.display?.id === res.data.data?.id) {
          commit('setDisplay', res.data.data)
        }
        return res.data.data
      }

      return null
    },

    async duplicate({ dispatch }, key) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'get',
          endpoint: `/v1/displays/${key}/duplicate`,
        },
        { root: true }
      )
      if (res.status == 200 && res.data?.data) {
        await dispatch('loadAll')
        dispatch(
          'app/showAlert',
          { message: 'Display duplicated.', type: 'success' },
          { root: true }
        )
        return res.data.data
      }

      return null
    },

    async setPresentation({ state, getters, commit, dispatch }, presentation) {
      let errorMessage = null
      if (!getters.canSetPresentation) {
        errorMessage = 'Please complete the display configuration.'
      }
      if (!state.display.is_connected) {
        errorMessage =
          'Cannot transition while Vixi Unreal Desktop App is disconnected. Please download and run Vixi Unreal Desktop App.'
      }
      if (state.transitioning) {
        errorMessage =
          'Display is transitioning, please wait for the current animation to complete.'
      }
      if (
        state.display.presentation != 'highlightPost' &&
        state.display.presentation === presentation
      ) {
        errorMessage =
          'The selected state is currently being presented. Please select another state.'
      }
      if (errorMessage) {
        return dispatch(
          'app/errorAlert',
          { message: errorMessage },
          { root: true }
        )
      }

      commit('setTransitioning', true)

      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'post',
          endpoint: `/v1/dashboard/${state.display.key}/presentation`,
          data: { presentation },
        },
        { root: true }
      )

      if (res.status == 200 && res.data?.data) {
        commit('setDisplay', res.data.data)
        return res.data.data
      }

      return null
    },

    async toggleAutomode({ state, commit, dispatch }) {
      if (!state.display.is_connected) {
        return dispatch(
          'app/errorAlert',
          {
            message:
              'Vixi Unreal Desktop App is disconnected. Please download and run Vixi Unreal App.',
          },
          { root: true }
        )
      }

      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'post',
          endpoint: `/v1/dashboard/${state.display.key}/auto`,
        },
        { root: true }
      )

      if (res.status == 200 && res.data?.data) {
        commit('setDisplay', res.data.data)
        dispatch(
          'app/updateAlert',
          {
            message: res.data.data?.auto_mode
              ? 'Auto mode enabled.'
              : 'Auto mode disabled.',
          },
          { root: true }
        )
        return res.data.data
      }

      return null
    },

    async goToPresentation({ state, commit, dispatch }, direction) {
      if (!state.display.is_connected) {
        return dispatch(
          'app/errorAlert',
          {
            message:
              'Vixi Unreal Desktop App is disconnected. Please download and run Vixi Unreal App.',
          },
          { root: true }
        )
      }

      if(state.display.presentation === 'highlightPost') {
        return;
      }

      commit('setTransitioning', true)
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'post',
          endpoint: `/v1/dashboard/${state.display.key}/${direction}`,
        },
        { root: true }
      )
      if (res instanceof ApiError) {
        commit('setTransitioning', false)
      }
    },

    async storeMedia({ state, dispatch }, { payload, successMessage = null }) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'post',
          endpoint: `/v1/displays/${state.display.key}/media`,
          data: payload,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        { root: true }
      )
      if (res.status == 201 && res.data?.data) {
        if (successMessage) {

          
          dispatch(
            'app/showAlert',
            { message: successMessage, type: 'success' },
            { root: true }
          )
        }
        return res.data.data
      }

      return null
    },

    async updateMedia({ state, dispatch }, payload) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'put',
          endpoint: `/v1/displays/${state.display.key}/media/${payload.id}`,
          data: payload,
        },
        { root: true }
      )

      return res.status == 200 && res.data?.data ? res.data.data : null
    },

    async deleteMedia({ state, dispatch }, id) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'delete',
          endpoint: `/v1/displays/${state.display.key}/media/${id}`,
        },
        { root: true }
      )

      return res.status == 200
    },

    async reorderMedia({ state, dispatch }, order) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'post',
          endpoint: `/v1/displays/${state.display.key}/media-reorder`,
          data: { order },
        },
        { root: true }
      )

      return res.status == 200
    },

    async clearMedia({ state, dispatch }) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'delete',
          endpoint: `/v1/displays/${state.display.key}/media-clear`,
        },
        { root: true }
      )

      return res.status == 200
    },

    async loadEvents({ state, commit, dispatch }) {
      if (!state.events?.length) {
        const res = await dispatch(
          'app/apiRequest',
          {
            method: 'get',
            endpoint: '/v1/events',
          },
          { root: true }
        )
        if (res.status == 200) {
          commit('setEvents', res.data.data)
        }
      }
    },

    subscribeToDisplay({ rootState, state, commit, dispatch, rootGetters }, key) {
      const channel = 'display.' + key
      const listen = (event, callback = null) =>
        window.Echo.channel(channel).listen(event, (e) => {
          console.info(`display.${key}.${event}`, e)
          if (callback != null) {
            callback(e)
          }

          // Dictionary of all scene durations
          const durationsDict = {
            socialGrid: state.display?.social_grid_item_duration,
            heroCta: state.display?.hero_cta_item_duration,
            highlightPlaylist: state.display?.highlight_playlist_item_duration,
            highlightPost: state.display?.highlight_post_item_duration,
          }

          if (event === 'DisplayArrivedAtPresentation' || e.post || e.layout) {
            // Handle a new post or layout as a new presentation.
            let durationType = e.presentation
            if (e.post) durationType = 'highlightPlaylist'
            if (e.layout) durationType = 'socialGrid'

            // Count up is always reset when a new presentation event comes in.
            state.counters.countUp = 0

            // Begin a new countdown.
            state.counters.countDown = durationsDict[durationType] || 0

            // Clear the existing interval if there is one.
            if (state.counters.countDownInterval) {
              clearInterval(state.counters.countDownInterval)
            }

            state.counters.betweenStates = false

            // Set up the interval with a 0.5s delay.
            state.counters.countDownInterval = setInterval(() => {
              commit('decrementCountDown')
              commit('incrementCountUp')
            }, 1000)
          }
        })

      if (!window.Echo.connector.channels.hasOwnProperty(channel)) {
        listen('DisplayUpdated', (e) => {
          commit('setDisplayConnection', { key, connection: true })
          commit('setDisplayUpdatedAt', { key, timestamp: e.updatedAt })
        })
        listen('DisplayAvailable', (e) => {
          commit('setDisplayNewValue', { key, loading: false })
        })
        listen('DisplayPresentationUpdated', (e) => {
          commit('setDisplayPresentation', {
            key,
            presentation: e.goToState,
          })
          if (key == state.display?.key) {
            if (e.sequence?.includes('next_grid')) {
              commit(
                'presentation/setSocialGridIndex',
                e.sequence.split(':')[1],
                { root: true }
              )
            } else {
              commit('presentation/setSocialGridIndex', 1, { root: true })
            }
          }
        })
        listen('DisplayConnected', (e) => {
          commit('setDisplayConnection', { key, connection: true })
        })
        listen('DisplayDisconnected', (e) => {
          commit('setDisplayConnection', { key, connection: false })
          dispatch(
            'app/errorAlert',
            { message: `Display ${key} disconnected.` },
            { root: true }
          )
        })
        listen('SocialGridUpdated', async (e) => {
          if (
            key == state.display?.key &&
            rootState.presentation?.presentation?.name == 'socialGrid'
          ) {
            await commit('presentation/setPresentationLayout', e.presentation.layout, { root: true })
            if (rootState.post.filters.approved === 1 || rootGetters['display/viewingDisplayAsSuperadmin']) {
              await dispatch('post/loadPosts', null, { root: true })
            }
          }
        })
        listen('DisplayArrivedAtPresentation', (e) => {
          if (
            e?.presentation == 'highlightPost' ||
            e?.presentation == 'highlightPlaylist'
          ) {
            commit('setDisplayCurrentPost', 0);
          }
          if (key == state.display?.key) {
            commit('setTransitioning', false);
          }
        });

        listen('DisplayArrivedAtPost', (e) => {
          if (key == state.display?.key) {
            commit('setDisplayCurrentPost', parseInt(e.post))
            commit('setTransitioning', false)
          }
        })
        listen('NextPost')
        listen('PostAddedToPlaylist')
      }
    },

    async createOrUpdatePost({ rootState, dispatch, state }, { tagboardId, data }) {
      const post = await dispatch(
        'app/tbRequest',
        {
          method: 'getPost',
          params: {
            tagboardId: tagboardId,
            postId: data.post_id,
            network: data.network,
          },
        },
        { root: true }
      )
      if (post && rootState.presentation.presentation) {
        await dispatch("presentation/savePosts", {
          presentation: rootState.presentation.presentation,
          refresh: false,
          posts: [postToApi(post, tagboardId)],
          
        }, { root: true })
        await dispatch('post/loadPosts', null, { root: true })
      }
    },

    subscribeToTagboard({ rootState, dispatch, getters }, { tagboardId }) {
      if (getters.viewingDisplayAsSuperadmin) {
        return
      }

      const channel = `tagboard-${tagboardId}`

      if (!window.tbPusher.channels.channels.hasOwnProperty(channel)) {
        const connector = window.tbPusher.subscribe(`tagboard-${tagboardId}`)

        connector.bind('post.created', async (data) => {
          console.log('post.created', data)
          await dispatch('createOrUpdatePost', { tagboardId, data})
        })

        connector.bind('post.updated', async (data) => {
          console.log('post.updated', data)
          await dispatch('createOrUpdatePost', { tagboardId, data})
        })

        connector.bind('post.deleted', async (data) => {
          console.log('post.deleted', data)
          if (rootState.tagboard.removedPost != data.post_id) {
            await dispatch('post/destroy', { postId: data.post_id, sourceCategory: 'tagboard' }, { root: true })
            await dispatch('post/loadPosts', null, { root: true })
          }
        })

        console.info(`Listening for tagboard ${tagboardId} events...`)
      }
    },

    async markModerationPostsForDisplays({ dispatch, state }) {
      const displays = state.displays.filter((d) => d.is_connected)
      for (let display of displays) {
        await dispatch('app/apiRequest', {
          method: 'put',
          endpoint: `/v1/displays/${display.key}/markModerationPosts`,
        }, { root: true })
      }
    },

    async markModerationPostsByDisplay({ dispatch, state }) {
      await dispatch('app/apiRequest', {
        method: 'put',
        endpoint: `/v1/displays/${state.display.key}/markModerationPosts`,
      }, { root: true })
    }

  },
}
