<template>
  <div
    class="w-full mx-auto md:px-6 lg:px-8 xl:px-10 pt-8 flex-1 flex flex-col max-w-screen-2xl"
  >
    <div
      class="w-full sm:max-w-fit mx-auto md:px-6 lg:px-8 xl:px-10 flex-1 flex flex-col items-center"
    >
      <div class="px-4 sm:px-0 w-full flex items-center">
        <h1 class="text-2xl font-bold text-mine-300">Highlight Playlist</h1>
        <flat-button
          v-if="!viewingDisplayAsSuperadmin"
          class="hidden lg:block ml-auto"
          @click="() => (showClearModal = true)"
          type="dark"
        >
          Clear All
        </flat-button>
        <flat-icon-button
          v-if="!viewingDisplayAsSuperadmin"
          class="lg:hidden ml-auto"
          icon="broom"
          type="dark"
          @click="() => (showClearModal = true)"
        />
      </div>
      <div class="w-full flex-1 flex mt-8">
        <div
          class="w-full sm:w-[640px] bg-mine-1000 pb-12 rounded-t flex flex-col"
        >
          <div class="bg-mine-800 p-2 rounded flex items-center min-h-[52px] justify-between">
            <flat-button
              v-if="!viewingDisplayAsSuperadmin"
              v-tooltip="{
                delay: { show: 1000 },
                content: `Add a social media post to Highlight Playlist.`,
              }"
              class="hidden lg:block"
              @click="addPost"
            >
              Add Post
            </flat-button>
            <flat-icon-button class="lg:hidden" icon="plus" @click="addPost" />
            <flat-icon-button
              :icon="search?.length ? 'backspace' : 'magnify'"
              class="ml-2 lg:hidden"
              @click="search?.length ? (search = '') : (showSearchModal = true)"
            />
            <div class="hidden lg:block relative w-48 ml-2">
              <text-field
                v-model="search"
                placeholder="Filter posts by keyword"
                class="pr-7"
              />
              <button
                v-if="search.length"
                class="absolute right-1 top-[6px] w-5 h-5 flex items-center justify-center text-white whitespace-nowrap bg-mine-800/60 rounded-sm transition hover:bg-mine-900 active:bg-mine-1000 focus:outline-none appearance-none"
                @click="() => (search = '')"
              >
                <mdi-icon icon="close" size="18" />
              </button>
            </div>
          </div>
          <div
            class="w-full px-4 sm:px-6 mt-6 flex-1 flex flex-col items-start"
          >
            <div
              v-if="loading"
              class="w-full flex items-center justify-center flex-1"
            >
              <loading-spinner
                color-classes="from-transparent to-cerise-600"
                background-class="bg-mine-1000"
              />
            </div>

            <p
              v-if="
                posts.length && !filteredPosts?.length && search?.length > 1
              "
              class="w-full flex-1 text-sm text-center flex items-center justify-center"
            >
              No posts found.
            </p>

            <vue-draggable
              v-show="!loading"
              :list="filteredPosts != null ? filteredPosts : posts"
              handle=".handle"
              item-key="id"
              @end="onReorderPosts"
              class="w-full"
            >
              <template #item="{ element }">
                <div>
                  <highlight-playlist-post
                    :total-posts="posts.length"
                    :post="element"
                    :can-update="canUpdatePosts"
                    @delete:highlightPost="removeHighlightPost"
                  />
                </div>
              </template>
            </vue-draggable>

            <skeleton-add-button
              v-if="
                !viewingDisplayAsSuperadmin &&
                !loading &&
                (!search || search?.length <= 1)
              "
              height-class="h-[120px]"
              width-class="w-full"
              @click="addPost"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <Transition>
    <content-modal
      v-if="showSearchModal"
      title="Search"
      @close="() => (showSearchModal = false)"
    >
      <text-field v-model="search" placeholder="Filter posts by keyword" />
    </content-modal>
  </Transition>

  <Transition>
    <destructive-modal
      v-if="showClearModal"
      title="Clear Highlight Playlist"
      body="Are you sure you want to remove all posts from this playlist? This action cannot be undone."
      :has-keyword="true"
      confirm-text="Confirm"
      keyword="CLEAR ALL"
      :loading="isClearingPosts"
      @confirmed="onClearPosts"
      @close="() => (showClearModal = false)"
    />
  </Transition>

  <Transition>
    <post-browser
      v-if="showPostBrowser"
      :excluded-post-ids="posts.map((e) => e.post_id)"
      @select-post="onSelectPostFromBrowser"
      @close="() => (showPostBrowser = false)"
    />
  </Transition>
</template>

<script>
  import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

  export default {
    data() {
      return {
        loading: true,
        showPostBrowser: false,
        isClearingPosts: false,
        showClearModal: false,
        showSearchModal: false,
        search: '',
      }
    },

    computed: {
      ...mapState('display', ['display']),
      ...mapState('presentation', {
        posts: 'highlightPlaylistPosts',
      }),
      ...mapGetters('display', ['viewingDisplayAsSuperadmin']),

      filteredPosts() {
        // let filtered = this.posts.filter(
        //   (post) =>
        //     this.socialNetworks.find((sn) => sn.key == post.network).value
        // )
        let filtered = this.posts
        if (this.search?.length > 1) {
          const searchTerm = this.search.toLowerCase()
          filtered = filtered.filter(
            (post) =>
              post.account_handle?.toLowerCase().includes(searchTerm) ||
              post.account_name?.toLowerCase().includes(searchTerm) ||
              post.message?.toLowerCase().includes(searchTerm) ||
              (post.hashtags &&
                post.hashtags.some((hashtag) =>
                  hashtag?.toLowerCase().includes(searchTerm)
                ))
          )
        }

        return filtered.length != this.posts.length ? filtered : null
      },

      canUpdatePosts() {
        return (
          this.display.presentation != 'highlightPlaylist' &&
          this.filteredPosts == null
        )
      },
    },

    async mounted() {
      this.setPresentation(
        this.display.presentations.find((e) => e.name == 'highlightPlaylist')
      )
      await this.loadPosts()
      this.loading = false
    },

    methods: {
      ...mapMutations('presentation', ['setPresentation']),
      ...mapActions('presentation', ['loadPosts']),

      addPost() {
        this.showPostBrowser = true
      },

      async onClearPosts() {
        this.isClearingPosts = true
        await this.$store.dispatch('presentation/clearPosts', {
          successMessage: 'Highlight Playlist cleared.',
        })
        this.showClearModal = false
        this.isClearingPosts = false
      },

      async onReorderPosts() {
        await this.$store.dispatch(
          'presentation/reorderPosts',
          this.posts.map((e) => e.post_presentation_id)
        )
      },

      async onSelectPostFromBrowser(post) {
        this.showPostBrowser = false
        this.loading = true
        await this.$store.dispatch('presentation/addPostToPlaylist', {
          presentation: this.display.presentations.find(
            (e) => e.name == 'highlightPlaylist'
          ),
          post: post,
        })
        this.loading = false
      },

      async removeHighlightPost() {
        await this.loadPosts()
      }
    },
  }
</script>
