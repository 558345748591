<template>
  <div
    class="bg-mine-900 border border-mine-500 rounded py-2 px-3 flex flex-col space-y-3"
  >
    <template v-for="(item, index) in menu" :key="index">
      <button
        v-if="item.show"
        class="p-2 lg:p-0 appearance-none text-xs flex items-center space-x-2 whitespace-nowrap transition hover:text-cerise"
        @click="() => item.action()"
      >
        <mdi-icon
          v-if="item.icon"
          :icon="item.icon"
          size="18"
          class="max-w-[18px]"
        />
        <span>{{ item.label }}</span>
      </button>
    </template>
  </div>
</template>

<script>
  export default {
    props: {
      menu: {
        type: Array,
        required: true,
      },
    },
  }
</script>
