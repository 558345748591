export default class ApiError {
  constructor(e, defaultMessage = 'Error') {
    this.e = e
    this.defaultMessage = defaultMessage
  }

  get hasMessage() {
    return !!this.e?.response?.data?.message
  }

  get message() {
    return this.e.response.data.message
  }

  get hasValidationErrors() {
    return !!this.e?.response?.data?.errors
  }

  validationErrorMessage() {
    return Object.keys(this.e.response.data.errors).reduce((message, key) => {
      return (message + JSON.stringify(this.e.response.data.errors[key]))
        .replace('["', '')
        .replace('"]', ' ')
        .replace('","', ' ')
    }, '')
  }

  errorMessage() {
    if (this.hasValidationErrors) {
      return this.validationErrorMessage()
    }
    if (this.hasMessage) {
      return this.message
    }
    return this.defaultMessage
  }
}
