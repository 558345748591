<template>
  <section class="py-8">
    <div
      class="sm:max-w-fit mx-auto md:px-6 lg:px-8 xl:px-10 flex flex-col items-center"
    >
      <h1 class="px-4 sm:px-0 w-full flex text-2xl font-bold text-mine-300">
        Controller
      </h1>
      <div class="w-full py-8">
        <div class="relative w-full sm:w-[480px] rounded overflow-hidden">
          <div
            class="flex justify-center sm:justify-start items-center p-2 bg-mine-800"
          >
            <span
              class="hidden sm:block flex-1 text-xs uppercase text-mine-400 pl-3 tracking-widest whitespace-nowrap text-ellipsis overflow-hidden pr-6"
            >
              {{ display?.name }}
            </span>
            <span class="text-xs uppercase text-mine-400 mr-3">
              Display Key
            </span>
            <text-copy
              :text="display?.key"
              copied-message="Display key copied."
              help-text="Copy the display key to your clipboard."
            />
          </div>

          <div
            v-if="!canSetPresentation"
            class="absolute inset-0 z-10 top-12 bg-mine-1000/90 flex flex-col justify-center items-center text-center pb-12 text-sm space-y-2"
          >
            <p class="text-mine-300 font-bold mb-3 leading-relaxed">
              Please complete the Display configuration<br />
              in order to use the Vixi Unreal Desktop App:
            </p>
            <p v-if="!displayHasBillboardsSet" class="text-mine-300">
              - Add at least one media item to each Graphic.
            </p>
            <p v-if="!displayHasDefaultPostsSet" class="text-mine-300">
              - Add at least 3 default social posts.
            </p>
          </div>

          <Transition>
            <div
              v-if="transitioning"
              class="absolute z-10 inset-0 top-12 bg-mine-1000/90 flex items-center justify-center text-center pb-12 hover:cursor-wait"
            >
              <span class="loading-indicator"></span>
              <span class="ml-3 text-sm text-mine-300">Transitioning</span>
            </div>
          </Transition>

          <div
            class="px-4 py-12 bg-mine-900 flex flex-col items-center justify-center"
          >
            <div class="grid grid-cols-2 gap-y-16 gap-x-20 relative">
              <layout-thumb
                type="Hero / CTA"
                v-tooltip="{
                  delay: { show: 1000 },
                  content: 'Go to the Hero / CTA state.',
                }"
                :active="display?.presentation == 'heroCta'"
                height-class="h-20"
                width-class="w-20"
                class="cursor-pointer"
                @click="() => setPresentation('heroCta')"
              />
              <layout-thumb
                type="Moderation"
                v-tooltip="{
                  delay: { show: 1000 },
                  content: 'Go to the Moderation state.',
                }"
                :active="display?.presentation == 'socialGrid'"
                height-class="h-20"
                width-class="w-20"
                class="cursor-pointer"
                @click="() => setPresentation('socialGrid')"
              />
              <layout-thumb
                type="Highlight Playlist"
                v-tooltip="{
                  delay: { show: 1000 },
                  content: 'Go to the Highlight Playlist state.',
                }"
                :active="display?.presentation == 'highlightPlaylist'"
                height-class="h-20"
                width-class="w-20"
                class="cursor-pointer"
                @click="() => setPresentation('highlightPlaylist')"
              />
              <layout-thumb
                type="Highlight Post"
                v-tooltip="{
                  delay: { show: 1000 },
                  content: 'Highlight the most recent post.',
                }"
                :active="display?.presentation == 'highlightPost'"
                height-class="h-20"
                width-class="w-20"
                class="cursor-pointer"
                @click="() => setPresentation('highlightPost')"
              />
              <div
                v-if="display?.auto_mode"
                class="absolute inset-0 bg-transparent cursor-not-allowed"
              ></div>
            </div>
          </div>

          <div
            class="px-4 py-8 bg-mine-1000 flex flex-col items-center justify-center"
          >
            <flat-button
              v-tooltip="{
                delay: { show: 1000 },
                content: 'Run the entire show automatically.',
                placement: 'top',
              }"
              :class="{ 'ring-golden !text-golden ring-1': display?.auto_mode }"
              @click="toggleAutomode"
            >
              Auto
            </flat-button>
            <div class="mt-4 flex items-center space-x-4">
              <div class="flex">
                <div
                  v-show="display?.auto_mode"
                  v-if="!counters.betweenStates"
                  id="countup"
                  class="bg-mine-1100 px-3 rounded-l flex items-center text-sm text-mine-300"
                >
                  {{ formatSeconds(counters?.countUp || 0) }}
                </div>
                <div
                  v-show="display?.auto_mode"
                  v-else
                  id="countdown"
                  class="bg-mine-1100 px-3 rounded-r flex items-center text-sm text-mine-300"
                >
                  <div class="ellipsis-loader"></div>
                </div>
                <flat-icon-button
                  :disabled="!canGoToPrevPost || display?.auto_mode"
                  v-tooltip="{
                    delay: { show: 1000 },
                    content:
                      'Return to the previous Highlight item or show state.',
                  }"
                  icon="rewind"
                  @click="() => goToPresentation('prev')"
                />
              </div>

              <flat-icon-button
                width-class="w-11"
                height-class="h-11"
                icon-size="28"
                v-tooltip="{
                  delay: { show: 1000 },
                  content: display?.auto_mode
                    ? 'Pause the show (disables Auto mode).'
                    : 'Start playing the show (enables Auto mode).',
                }"
                :icon="display?.auto_mode ? 'pause' : 'play'"
                @click="toggleAutomode"
              />

              <div class="flex">
                <flat-icon-button
                  icon="fast-forward"
                  v-tooltip="{
                    delay: { show: 1000 },
                    content:
                      'Proceed to the next Highlight item or show state.',
                  }"
                  :disabled="display?.auto_mode || display.presentation == 'highlightPost'"
                  @click="() => goToPresentation('next')"
                />
                <div
                  v-show="display?.auto_mode"
                  v-if="!counters.betweenStates"
                  id="countdown"
                  class="bg-mine-1100 px-3 rounded-r flex items-center text-sm text-mine-300"
                >
                  - {{ formatSeconds(counters?.countDown || 0) }}
                </div>
                <div
                  v-show="display?.auto_mode"
                  v-else
                  id="countdown"
                  class="bg-mine-1100 px-3 rounded-r flex items-center text-sm text-mine-300"
                >
                  <div class="ellipsis-loader"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapActions, mapState, mapGetters } from 'vuex'

  export default {
    data() {
      return {
        minutes: 0,
      }
    },
    computed: {
      ...mapState('display', ['display', 'transitioning', 'counters']),
      ...mapGetters('display', [
        'canSetPresentation',
        'displayHasDefaultPostsSet',
        'displayHasBillboardsSet',
        'canGoToPrevPost',
      ]),
    },
    methods: {
      ...mapActions('display', [
        'setPresentation',
        'toggleAutomode',
        'goToPresentation',
      ]),
      formatSeconds(seconds) {
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
          .toString()
          .padStart(2, '0')}`
      },
    },

    async created() {
      await this.$store.dispatch('display/markModerationPostsByDisplay')
    }
  }
</script>

<style>
  #countup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 67.84px;
  }

  #countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 67.84px;
  }

  .ellipsis-loader {
    font-size: 25px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    margin-left: 50px;
    align-items: center;
    transform: translateY(-25%);
  }

  .ellipsis-loader:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\2026';
    /* ascii code for the ellipsis character */
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }

  .cursor-not-allowed {
    cursor: not-allowed;
  }
</style>
