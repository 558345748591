const { VITE_UE_VERSION, VITE_UE_NOTIFICATION } = import.meta.env

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function isDifferentDates(date1, date2) {
  return (
    date1.getFullYear() !== date2.getFullYear() ||
    date1.getMonth() !== date2.getMonth() ||
    date1.getDate() !== date2.getDate()
  )
}

function isActiveUpdateNotification(isAuth) {
  const notificationDay = new Date(localStorage.getItem('updateNotificationDay'))
  const updateDownloaded = localStorage.getItem('updatedDownload')
  const now = new Date()  

  return VITE_UE_NOTIFICATION &&
    updateDownloaded !== VITE_UE_VERSION &&
    isDifferentDates(notificationDay, now) &&
    isAuth
}

function pageInIframe() {
  return (window.location !== window.parent.location);
}

export { isDifferentDates, isActiveUpdateNotification, capitalizeFirstLetter, pageInIframe }