import { createStore } from 'vuex'
import api from './modules/api'
import app from './modules/app'
import auth from './modules/auth'
import display from './modules/display'
import presentation from './modules/presentation'
import billboard from './modules/billboard'
import tagboard from './modules/tagboard'
import post from './modules/post'
import organization from './modules/organization'
import sourceEntity from './modules/sourceEntity';

const store = createStore({
  modules: {
    api,
    app,
    auth,
    display,
    presentation,
    billboard,
    tagboard,
    post,
    organization,
    sourceEntity
  },
})

export default store
