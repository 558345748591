import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

window.Pusher = Pusher

const env = import.meta.env

function createEcho(connectionName) {
  let options = {}

  console.info(`Connecting to WS Server for ${connectionName}.`)

  options = {
    broadcaster: 'pusher',
    key: env.VITE_PUSHER_KEY,
    cluster: env.VITE_PUSHER_CLUSTER,
    wsHost: env.VITE_WS_HOST,
    wsPort: env.VITE_WS_PORT,
    forceTLS: false,
    disableStats: true,
    scheme: env.VITE_PUSHER_SCHEME,
    authEndpoint: env.VITE_API_BASE_URL + '/v1/broadcasting/auth',
    enabledTransports: ['ws', 'wss'],
    auth: {
      headers: {
        Authorization: '',
      },
    },
  }

  let echo = new Echo(options)

  echo.connector.pusher.connection.bind('error', function (err) {
    console.error(`Connection error for ${connectionName}.`, err)
  })

  return echo
}

window.Echo = createEcho('User')

window.SAEcho = createEcho('Superadmin')

window.tbPusher = new Pusher(env.VITE_TAGBOARD_PUSHER_KEY, {
  cluster: env.VITE_TAGBOARD_PUSHER_CLUSER,
})

export { createEcho }
