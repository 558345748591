<template>
  <auth-layout :title="content.title">
    <p v-show="content.message" class="text-center text-suite-gray font-medium">{{ content.message }}</p>
    <p v-show="!content.message" class="text-center text-suite-gray font-medium">Failed to authenticate. Please sign into the Vixi Suite.<br />
          Stand-alone login for testing: <a href="#" class="text-suite-gray" @click.prevent="login">Log In</a></p>
  </auth-layout>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    computed: {
      ...mapGetters('auth', ['content']),
    },
    methods: {
      ...mapActions('app', ['notAuthorize']),
      ...mapActions('auth', ['login']),
    },
  }
</script>
