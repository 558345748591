<template>
  <div
    :class="`flex h-6 w-6 items-center justify-center rounded-full bg-gradient-to-tr animate-spin ${colorClasses}`"
  >
    <div :class="`h-5 w-5 rounded-full ${backgroundClass}`"></div>
  </div>
</template>

<script>
  export default {
    props: {
      colorClasses: {
        type: String,
        default: 'from-mine-1100 to-mine-300',
      },
      backgroundClass: {
        type: String,
        default: 'bg-mine-1100',
      },
    },
  }
</script>
