<template>
  <button
    :class="`${heightClass} ${widthClass} border-2 border-dashed border-mine-700 rounded flex items-center justify-center group transition hover:border-golden`"
    :style="{ borderColor: isDragging ? 'rgb(254, 198, 81)' : '' }"
  >
    <mdi-icon
      icon="plus-circle-outline"
      size="36"
      class="text-mine-700 w-9 transition group-hover:text-golden"
      :style="{ color: isDragging ? 'rgb(254, 198, 81)' : '' }"
    />
  </button>
</template>

<script>
  export default {
    props: {
      heightClass: {
        type: String,
        default: 'h-48',
      },
      widthClass: {
        type: String,
        default: 'w-72',
      },
      isDragging: {
        type: Boolean,
      },
    },
  }
</script>
