<template>
  <div>
    <svg
      v-if="type == 'square'"
      width="130"
      height="130"
      viewBox="0 0 130 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      :style="style"
    >
      <rect
        :stroke="color"
        x="0.5"
        y="0.5"
        width="129"
        height="129"
        rx="3.5"
        stroke-dasharray="4 4"
        :stroke-width="strokeWidth"
      />
    </svg>

    <svg
      v-if="type == 'portrait'"
      width="70"
      height="130"
      viewBox="0 0 70 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      :style="style"
    >
      <rect
        :stroke="color"
        x="0.5"
        y="0.5"
        width="69"
        height="129"
        rx="3.5"
        stroke-dasharray="4 4"
        :stroke-width="strokeWidth"
      />
    </svg>
  </div>
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        default: 'square',
      },
      color: {
        type: String,
        default: '#e3e3e3',
      },
      style: {
        type: Object,
        default: () => {},
      },
      strokeWidth: {
        type: Number,
        default: 1,
      },
    },
  }
</script>
