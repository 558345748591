<template>
  <div
    class="flex justify-center items-center text-center mx-2 text-xs uppercase tracking-widest whitespace-nowrap"
  >
    <template v-for="(option) in options">
      <radio-field 
        :selected="selectedOption === option.value"
        :option="option"
        @change:selection="updateSelectedOption" 
      />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: []
    },
    selectedOption: {
      type: [String, Number],
      default: 'all'
    }
  },
  data() {
    return {
      filters: {}
    };
  },
  methods: {
    updateSelectedOption(value) {
      this.filters.approved = value
      this.$emit("change:filters", this.filters)
    },
  },
};
</script>
