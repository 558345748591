<template>
  <div
    class="flex flex-col sm:flex-row sm:items-center sm:justify-center w-full"
  >
    <h6
      class="uppercase sm:text-right mb-2 sm:mb-0 text-mine-400 text-xs w-1/3 whitespace-nowrap pr-8"
    >
      {{ label }}
    </h6>
    <div class="sm:w-2/3">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        required: true,
      },
    },
  }
</script>
