<template>
  <div class="flex flex-col min-h-screen">
    <main class="flex-1 bg-mine-1100">
      <app-container
        class="flex flex-col items-center justify-center min-h-screen text-sm text-white p-0 m-0 tracking-[1.25px] leading-[22px]"
      >
        <h1 class="text-suite-pink mb-0.5">{{ title }}</h1>
        <slot />
      </app-container>
    </main>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      message: {
        type: String,
        default: ''
      }
    }
  }
</script>