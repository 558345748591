export default {
  namespaced: true,
  state: {
    sources: [],
    sourceEntities: [],
    selectedSource: localStorage.getItem('selectedSource') || "all",
    selectedSourceEntity: localStorage.getItem('selectedSourceEntity') || "all",
  },
  getters: {
    getSources: (state) =>  state.sources,
    getSourceEntities: (state) =>  state.sourceEntities,
    getSelectedSource: (state) =>  state.selectedSource,
    getSelectedSourceEntity: (state) =>  state.selectedSourceEntity,
  },
  mutations: {
    setSources(state, rootState) {
      state.sources = []
      
      if (rootState.auth.apiUser.tagboard_access && localStorage.getItem("tbAccessToken")) {
        state.sources.push({ value: "tagboard", label: "Tagboard" })
      }
      if (rootState.auth.apiUser.capture_access) {
        state.sources.push({ value: "capture", label: "Capture" })
      }

      if (state.sources.length > 1) {
        state.sources.unshift({ value: "all", label: "All Sources" })
      }
    },
    setSuperadminSources(state) {
      state.sources = []
      state.sources.push({ value: "all", label: "All Sources" })
      state.sources.push({ value: "tagboard", label: "Tagboard" })
      state.sources.push({ value: "capture", label: "Capture" })
    },
    setSourceEntities(state, sourceEntities) {
      if (sourceEntities.length > 1) {
        sourceEntities.unshift({ value: "all", label: "All Events" })
      }
      state.sourceEntities = sourceEntities
    },
    setSelectedSource(state, selectedSource) {
      state.selectedSource = selectedSource
      state.selectedSourceEntity = 'all'
      localStorage.setItem('selectedSource', selectedSource)
      localStorage.setItem('selectedSourceEntity', 'all')
    },
    setSelectedSourceEntity(state, selectedSourceEntity) {
      state.selectedSourceEntity = selectedSourceEntity
      localStorage.setItem('selectedSource', state.selectedSource)
      localStorage.setItem('selectedSourceEntity', selectedSourceEntity)
    }
  },
  actions: {
    async fetchEntities({ dispatch, commit, state, rootState, rootGetters }) {
      if (rootGetters['display/viewingDisplayAsSuperadmin']) {
        commit('setSuperadminSources')
        commit('setSourceEntities', [])
        return
      }
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'post',
          endpoint: '/v1/sourcesEntities',
          headers: {
            'Tagboard-Token': localStorage.getItem('tbAccessToken'),
            'Organization-Token': rootGetters['organization/activeOrgToken']
          },
          data: {
            filter: state.selectedSource,
            organizationId: rootGetters['organization/organization']?.id
          },
        },
        { root: true }
      )
      if (res.status == 200 && res.data) {
        commit('setSources', rootState)
        commit('setSourceEntities', res.data)
      }
    },

    findSourceEntity({ state }, { source, entityId }) {
      return state.sourceEntities.find((s) => s.source === source && s.value == entityId)
    }
  },
}
