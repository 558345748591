<template>
  <div>
    <div
      class="bg-mine-800 h-12 rounded flex items-center px-6 cursor-pointer transition hover:bg-mine-700 active:bg-mine-600"
      @click="open"
    >
      <mdi-icon
        icon="chevron-right"
        class="transition"
        :class="{ 'transform rotate-90': active }"
      />
      <span class="uppercase text-xs tracking-widest ml-5">{{ title }}</span>
    </div>
    <VueCollapse :when="active" class="collapse-animation">
      <slot />
    </VueCollapse>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: 'Title',
      },
      active: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['open'],
    methods: {
      open() {
        this.$emit('open')
      },
    },
  }
</script>

<style>
  .collapse-animation {
    transition: height 150ms cubic-bezier(0.3, 0, 0.6, 1);
  }
</style>
