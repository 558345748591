<template>
  <Transition>
    <div
      v-if="alert.active"
      class="fixed z-50 top-0 sm:top-[70px] sm:right-3 rounded border w-full sm:w-72"
      :class="{
        'bg-mine-900 border-mine-700': alert.type == 'update' || alert.type == 'updateNow',
        'bg-red-900 border-red-400': alert.type == 'error',
        'bg-green-900 border-green-700': alert.type == 'success',
        'bg-golden-600 border-golden-500': alert.type == 'warning',
      }"
    >
      <div class="flex items-center justify-between p-2">
        <div
          class="font-bold mx-2 max-w-[200px] whitespace-nowrap overflow-hidden text-ellipsis"
          :class="{
            'text-mine-300': alert.type === 'update' || alert.type == 'updateNow',
            'text-white': alert.type === 'error',
            'text-white': alert.type === 'success',
            'text-white': alert.type === 'warning',
          }"
        >
          {{ alert.title }}
        </div>

        <flat-icon-button
          v-if="alert.type === 'update'"
          icon="close"
          @click="resetAlert"
        />
        <flat-icon-button
          v-if="alert.type === 'updateNow'"
          icon="close"
          @click="disableDayUpdateNotification"
        />
        <button
          v-if="alert.type === 'error'"
          :class="`w-8 h-8 flex items-center justify-center text-white whitespace-nowrap bg-red-500/10 rounded-sm transition enabled:hover:bg-red-600/60 enabled:active:bg-red-600/60 focus:outline-none appearance-none disabled:opacity-50 disabled:cursor-not-allowed`"
          @click="resetAlert"
        >
          <mdi-icon icon="close" size="16" />
        </button>
        <button
          v-if="alert.type === 'success'"
          :class="`w-8 h-8 flex items-center justify-center text-white whitespace-nowrap bg-green-500/10 rounded-sm transition enabled:hover:bg-green-500/20 enabled:active:bg-green-500/30 focus:outline-none appearance-none disabled:opacity-50 disabled:cursor-not-allowed`"
          @click="resetAlert"
        >
          <mdi-icon icon="close" size="16" />
        </button>
        <button
          v-if="alert.type === 'warning'"
          :class="`w-8 h-8 flex items-center justify-center text-white whitespace-nowrap bg-golden-500/10 rounded-sm transition enabled:hover:bg-golden-500/20 enabled:active:bg-golden-500/30 focus:outline-none appearance-none disabled:opacity-50 disabled:cursor-not-allowed`"
          @click="resetAlert"
        >
          <mdi-icon icon="close" size="16" />
        </button>
      </div>
      <div
        class="text-sm px-4 pt-2 pb-4"
        :class="{
          'text-mine-300': alert.type === 'update' || alert.type == 'updateNow',
          'text-white': alert.type === 'error',
          'text-white': alert.type === 'success',
          'text-white': alert.type === 'warning',
        }"
      >
      {{ alert.message }}
      <Link v-if="alert.url" :url="alert.url" :text="alert.linkText" :dynamicMethod="disablePermanentlyUpdateNotification" :classTemplate="alert.classTemplate"></Link>
      {{ alert.endMessage }}
      </div>
    </div>
  </Transition>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import Link from './Link.vue';

  export default {
    components: {
      Link
    },
    computed: {
      ...mapState('app', ['alert']),
    },
    methods: {
      ...mapActions('app', ['resetAlert', 'disableDayUpdateNotification', 'disablePermanentlyUpdateNotification']),
    },
  }
</script>
