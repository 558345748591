<template>
  <div
    v-show="loading"
    :class="`flex items-center justify-center loading-display ${loading ? 'absolute top-0 left-0 bottom-0 right-0 z-[1] bg-mine-1100/60' : ''}`"
  >
    <loading-spinner
      color-classes="from-transparent to-cerise-600"
    />
  </div>

  <slot />
</template>

<script>
  export default {
    props: {
      loading: {
        type: Boolean,
        default: false,
      }
    }
  }
</script>
