<template>
  <button
    class="flex-shrink-0 uppercase text-xs text-white text-center py-3 whitespace-nowrap px-6 rounded-sm tracking-widest transition focus:outline-none appearance-none disabled:opacity-50 disabled:cursor-not-allowed leading-none"
    :class="[...buttonClasses]"
    :disabled="loading || disabled"
  >
    <slot />
  </button>
</template>

<script>
  export default {
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: ''
      }
    },
    computed: {
      buttonClasses() {
        const classes = []
        if (this.loading) {
          classes.push('loading-indicator')
        }
        if (this.type === 'primary') {
          classes.push('bg-cerise', 'enabled:hover:bg-cerise-500', 'enabled:active:bg-cerise-500')
        } else if (this.type === 'dark') {
          classes.push('bg-mine-1000', 'enabled:hover:bg-mine-700', 'enabled:active:bg-mine-700')
        } else {
          classes.push('bg-mine-700', 'enabled:hover:bg-mine-600', 'enabled:active:bg-mine-500')
        }
        return classes;
      }
    },
  }
</script>
