import axios from 'axios'
import router from '/src/router'

const { VITE_SUIT_URL } = import.meta.env
export default {
  namespaced: true,
  state: {
    organization: null,
    activeOrgToken: null
  },
  getters: {
    organization: (state) => state.organization,
    activeOrgToken: (state) => state.activeOrgToken,
  },
  mutations: {
    reset(state) {
      state.organization = null
    },
    setOrganization(state, organization) {
      state.organization = organization
    },
    setActiveOrgToken(state, activeOrgToken) {
      state.activeOrgToken = activeOrgToken
    },
  },
  actions: {
    async getOrganization({ dispatch, state, commit, rootState }, { activeOrgToken }) {
      if (!activeOrgToken) {
        activeOrgToken = await dispatch('getOrgToken')
      }
      if (!activeOrgToken) {
        return false;
      }
      try {
        const res = await dispatch(
          'app/apiRequest',
          {
            method: 'post',
            endpoint: `/v1/organization`,
            headers: {
              'Organization-Token': activeOrgToken,
            },
            data: {
              userId: rootState.auth.apiUser?.id
            }
          },
          { root: true }
        )
        commit('setActiveOrgToken', activeOrgToken);
        state.organization = res && res.data

        return state.organization
      } catch (error) {
        console.log(error)
      }
     
    },
    async getOrgToken({ rootGetters, commit }) {
      try {
        const token = rootGetters['auth/auth0AccessToken']

        const apiUrl = `${VITE_SUIT_URL}/api/orgs/getActiveOrgToken`;
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.data) {
          return response.data
        } else {
          const message = 'No organization is selected. Please select an organization in the Vixi Suite, or contact your TFG representative to subscribe.'
          commit('app/setNotAuthorize', { message }, { root: true })
          commit('auth/setContent', { message }, { root: true })
        }
      } catch(error) {
        console.error(error);
      }

    }
  },
}
