<template>
  <div class="px-4 py-3 mb-5 bg-mine-800/60 flex items-center rounded">
    <mdi-icon
      icon="drag-vertical"
      class="text-mine-500 mr-4"
      :class="{
        'cursor-grab handle': !loading && media.url,
        'cursor-not-allowed': loading || !media.url,
      }"
    />
    <div
      v-if="loading"
      class="w-20 h-20 sm:w-24 sm:h-24 flex items-center justify-center"
    >
      <div class="loading-indicator"></div>
    </div>
    <div
      v-if="!loading && media.url"
      class="flex-shrink-0 w-20 h-20 sm:w-24 sm:h-24 bg-mine-700 rounded overflow-hidden aspect-square relative group"
    >
      <img
        v-if="media.type == 'image'"
        :src="media.url"
        class="w-20 h-20 sm:w-24 sm:h-24 object-contain"
      />
      <video
        v-if="media.type == 'video'"
        :src="media.url"
        muted
        class="w-20 h-20 sm:w-24 sm:h-24 object-contain"
      ></video>
      <div
        v-if="media.orientation"
        class="absolute right-0 bottom-0 bg-black/60 text-white text-[10px] p-1 text-center w-full leading-[10px] hidden group-hover:block"
      >
        {{ `${media.width} x ${media.height} (${media.orientation})` }}
      </div>
    </div>
    <skeleton-add-button
      :id="`dropzone-${media?.id}`"
      v-if="!loading && !media.url"
      :is-dragging="isDragging"
      height-class="h-20 sm:h-24"
      width-class="w-20 sm:w-24"
      @click="() => $refs.input.click()"
      @dragover.prevent="isDragging = true"
      @dragleave.prevent="isDragging = false"
      @drop.prevent="handleDrop"
    />
    <input
      v-if="!loading && !media.url"
      ref="input"
      type="file"
      class="hidden"
      @change="onSelectMedia"
    />
    <div
      class="flex-1 flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0"
    >
      <div
        class="flex-1 flex flex-col items-end pl-6 md:px-6 xl:px-12 space-y-3"
      >
        <div class="w-full flex items-center">
          <span class="uppercase text-xs text-mine-500 mr-3"> Name </span>
          <text-field
            v-model="name"
            :disabled="!media.url"
            @change="onNameChange"
          />
        </div>
        <span class="uppercase text-xs text-mine-500">
          {{ media.file_name }}
        </span>
      </div>
      <div
        class="flex flex-row md:flex-col md:space-y-3 justify-end space-x-3 md:space-x-0"
      >
        <flat-icon-toggle
          icon="eye-outline"
          v-tooltip="{
            delay: { show: 1000 },
            content:
              'Toggle visibility of this playlist item — when off, this item is skipped.',
          }"
          :active="media.visible"
          :disabled="!media.url"
          @click="() => (!media.url ? null : toggleVisibility())"
        />
        <flat-icon-toggle
          v-if="model == 'billboard'"
          v-tooltip="{
            delay: { show: 1000 },
            content:
              'Toggle looping of this playlist item — when on, only this item is played and all other items in the playlist are skipped.',
          }"
          icon="replay"
          :active="media.looping"
          :disabled="!media.url"
          @click="() => (!media.url ? null : toggleLooping())"
        />
        <flat-icon-button
          icon="trash-can"
          @click="() => (showDeleteModal = true)"
        />
      </div>
    </div>
  </div>

  <Transition>
    <destructive-modal
      v-if="showDeleteModal"
      title="Delete Media"
      body="Are you sure you want to delete this media? This cannot be undone."
      :loading="loading"
      @confirmed="destroy"
      @close="() => (showDeleteModal = false)"
    />
  </Transition>
</template>

<script>
  export default {
    props: {
      media: {
        type: Object,
        default: () => {},
      },
      model: {
        type: String,
        default: 'billboard',
      },
      displayCollection: {
        type: String,
        default: null,
      },
    },
    emits: ['saving', 'saved', 'deleting', 'deleted', 'error'],
    data() {
      return {
        name: null,
        loading: false,
        showDeleteModal: false,
        isDragging: false,
      }
    },
    created() {
      this.name = this.media?.name
    },
    methods: {
      handleDrop(e) {
        e.preventDefault()
        this.isDragging = false
        this.onSelectMedia(e)
      },

      async destroy() {
        this.$emit('deleting')
        this.loading = true
        if (this.media.url) {
          await this.$store.dispatch(`${this.model}/deleteMedia`, this.media.id)
        }
        this.$emit('deleted')
        this.loading = false
        this.showDeleteModal = false
      },

      async onSelectMedia($event) {
        let media

        if ($event.dataTransfer?.files?.length > 0) {
          media = $event.dataTransfer.files[0] ?? null
        } else {
          media = $event.target.files[0] ?? null
        }

        this.$refs.input.value = ''

        if (!media.size || media.size > 26214400) {
          this.$store.dispatch('app/errorAlert', {
            title: 'File too large',
            message: 'Please choose a file under 25MB.',
          })
          return
        }

        if (
          this.displayCollection == 'videos_default_social_posts' &&
          media.type != 'video/mp4'
        ) {
          this.$store.dispatch('app/errorAlert', {
            title: 'Invalid format',
            message: 'Please choose a video in MP4 format.',
          })
          return
        }

        if (
          this.displayCollection != 'videos_default_social_posts' &&
          media.type != 'image/jpeg' &&
          media.type != 'image/png'
        ) {
          this.$store.dispatch('app/errorAlert', {
            title: 'Invalid format',
            message: 'Please choose an image in JPG or PNG format.',
          })
          return
        }

        if (media) {
          this.$emit('saving')
          this.loading = true
          const successMessage =
            this.displayCollection == 'stills_default_social_posts' ||
            this.displayCollection == 'videos_default_social_posts'
              ? 'Default post upload complete.'
              : 'Billboard upload complete.'

          const res = await this.$store.dispatch(`${this.model}/storeMedia`, {
            successMessage,
            payload: {
              media: media,
              collection: this.displayCollection,
              name: null,
            },
          })

          if (res) {
            this.$emit('saved', res)
          } else {
            this.$emit('error')
          }

          this.loading = false
        }
      },

      async onNameChange() {
        this.$emit('saving')
        const res = await this.$store.dispatch(`${this.model}/updateMedia`, {
          id: this.media.id,
          name: this.name,
          visible: this.media.visible,
          looping: this.media.looping,
          collection: this.displayCollection,
        })
        if (res) {
          this.$emit('saved', res)
        } else {
          this.$emit('error')
        }
      },

      async toggleVisibility() {
        this.$emit('saving')
        const res = await this.$store.dispatch(`${this.model}/updateMedia`, {
          id: this.media.id,
          name: this.name,
          visible: !this.media.visible,
          looping: this.media.looping,
          collection: this.displayCollection,
        })
        if (res) {
          this.$emit('saved', res)
        } else {
          this.$emit('error')
        }
      },

      async toggleLooping() {
        this.$emit('saving')
        const res = await this.$store.dispatch(`${this.model}/updateMedia`, {
          id: this.media.id,
          name: this.name,
          visible: this.media.visible,
          looping: !this.media.looping,
          collection: this.displayCollection,
        })
        if (res) {
          this.$emit('saved', res)
        } else {
          this.$emit('error')
        }
      },
    },
  }
</script>
