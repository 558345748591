const { VITE_UE_DATE_BY, VITE_UE_APP_LINK, VITE_UE_VERSION } = import.meta.env
import { pageInIframe } from '/src/utils/helper'
import router from '/src/router'
import {
  isActiveUpdateNotification,
  capitalizeFirstLetter,
} from '/src/utils/helper'

export default {
  namespaced: true,
  state: {
    loading: false,
    fatalError: {
      active: false,
      title: 'Fatal Error',
      message: 'Please try again.',
    },
    alert: {
      active: false,
      title: null,
      message: null,
      type: 'update',
      url: null,
      linkText: null,
      classTeplate: null,
    },
    notAuthorize: {
      title: 'Access Denied',
      message: 'Your organization does not have access to this product.'
    },
    showTagboardLogin: false
  },
  mutations: {
    setLoading(state, value) {
      state.loading = value
    },
    setFatalError(state, error) {
      state.fatalError = error
    },
    setAlert(state, alert) {
      state.alert = alert
    },
    setNotAuthorize(state, { title, message }) {
      state.notAuthorize = {
        title: title || state.notAuthorize.title,
        message
      }
    },
    setShowTagboardLogin(state, value) {
      state.showTagboardLogin = value
    }
  },
  getters: {
    notAuthorize: (state) => state.notAuthorize,
    showTagboardLogin: (state) => state.showTagboardLogin,
  },
  actions: {
    reset({ commit }) {
      commit('auth/reset', null, { root: true })
      commit('billboard/reset', null, { root: true })
      commit('display/reset', null, { root: true })
      commit('presentation/reset', null, { root: true })
      commit('organization/reset', null, { root: true })
    },

    async tbRequest(
      { commit, dispatch },
      { method, params = null, endLoading = true }
    ) {
      commit('setLoading', true)

      try {
        return await dispatch(`tagboard/${method}`, params, { root: true })
      } catch (error) {
        console.error(error.response)
      } finally {
        if (endLoading) {
          commit('setLoading', false)
        }
      }
    },

    async apiRequest(
      { commit, dispatch },
      { method, endpoint, data, headers = {}, endLoading = true }
    ) {
      commit('setLoading', true)

      try {
        const http = await dispatch('api/http', null, { root: true })
        if (method === 'get' || method === 'delete') {
          return await http[method](endpoint, { headers: headers })
        }
        return await http[method](endpoint, data, {
          headers: headers,
        })
      } catch (error) {
        if (error.response && error.response.error) {
          throw error.response
        }
        console.error(error.response);
      } finally {
        if (endLoading) {
          commit('setLoading', false)
        }
      }
    },

    fatalError({ commit }, { title, message }) {
      commit('setFatalError', {
        active: true,
        title,
        message,
      })
    },

    updateNowAlert({ commit }) {
      const message = `New version of Desktop App required by ${VITE_UE_DATE_BY}. Please `
      const active = isActiveUpdateNotification(this.state.auth.user)
      const endMessage = ' now.'
      const title = 'Update Now'
      const url = VITE_UE_APP_LINK
      const linkText = 'download'
      const type = 'updateNow'
      const classTeplate = ''
      commit('setAlert', {
        active,
        title,
        message,
        endMessage,
        type,
        url,
        linkText,
        classTeplate,
      })
    },

    updateAlert(
      { commit, dispatch },
      { title = 'Update', message, timeout = 3000 }
    ) {
      commit('setAlert', {
        active: true,
        title: title,
        message: message,
        type: 'update',
      })
      setTimeout(() => {
        dispatch('resetAlert')
      }, timeout)
    },

    showAlert({ commit, dispatch }, { message, type, timeout = 3000 }) {
      commit('setAlert', {
        active: true,
        title: capitalizeFirstLetter(type),
        message,
        type,
      })
      setTimeout(() => {
        dispatch('resetAlert')
      }, timeout)
    },

    warningAlert(
      { commit, dispatch },
      { title = 'Warning', message, timeout = 3000 }
    ) {
      commit('setAlert', {
        active: true,
        title: title,
        message: message,
        type: 'warning',
      })
      setTimeout(() => {
        dispatch('resetAlert')
      }, timeout)
    },

    errorAlert(
      { commit, dispatch },
      { title = 'Error', message, timeout = 3000 }
    ) {
      if (message) {
        commit('setAlert', {
          active: true,
          title: title,
          message,
          type: 'error',
        })
      }
      setTimeout(() => {
        dispatch('resetAlert')
      }, timeout)
    },

    resetAlert({ commit, dispatch }) {
      commit('setAlert', {
        active: false,
        message: null,
        endMessage: null,
        type: 'update',
        url: null,
        linkText: null,
        classTeplate: null,
      })
      dispatch('updateNowAlert')
    },

    disableDayUpdateNotification({ dispatch }) {
      localStorage.setItem('updateNotificationDay', new Date())
      dispatch('resetAlert')
    },

    disablePermanentlyUpdateNotification({ dispatch }) {
      localStorage.setItem('updatedDownload', VITE_UE_VERSION)
      dispatch('resetAlert')
    },

    quitRedirect() {
      if (!pageInIframe()) {
        router.push({ name: 'Login' })
      } else {
        router.push({ name: 'NotAuthorized' })
      }
    },

    subscribeToDataBaseFilled({ rootState, state, commit, dispatch }) {
      const userId = rootState.auth.apiUser.id
      const channel = 'database-filled.' + userId
      window.Echo.channel(channel).listen('DatabaseFilled', (data) => {
        console.log(
          'The database loading process is complete (' + data.source + ').',
          data.user
        )
      })
    },
  },
}
