import axios from 'axios'
import ApiError from '/src/errors/api_error'

export default {
  namespaced: true,
  actions: {
    http({ dispatch, commit }) {
      const http = axios.create({
        timeout: 3600000,
        baseURL: import.meta.env.VITE_API_BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })

      http.interceptors.response.use(
        (response) => {
          return response
        },
        (error) => {
          const apiError = new ApiError(error)

          if (error?.response?.data?.location) {
            window.location = error.response.data.location
            return apiError
          } else if (error?.response?.status == 422) {
            dispatch(
              'app/errorAlert',
              { message: apiError.message },
              { root: true }
            )
            return apiError
          } else if (error?.response?.status == 401) {
            if (error?.response.data.error === 'Missing authentication') {
              commit('app/setShowTagboardLogin', true, { root: true })
            } else {
              dispatch(
                'app/errorAlert',
                { message: 'User session timed out. Please log in again.' },
                { root: true }
              )
              dispatch('auth/postLogout', false, { root: true })
            }
            return apiError
          } else if (error?.response?.status == 403) {            
            dispatch('auth/productNotAuthorized', false, { root: true })
            return apiError
          }

          dispatch(
            'app/errorAlert',
            {
              message: apiError.message,
            },
            { root: true }
          )

          return Promise.reject(error)
        }
      )

      return http
    },
  },
}
