<template>
  <span class="mdi" :class="`mdi-${icon}`" :style="{ fontSize: `${size}px` }"></span>
</template>

<script>
  export default {
    props: {
      icon: {
        type: String,
        required: true,
      },
      size: {
        type: String,
        default: '24',
      },
    },
  }
</script>
