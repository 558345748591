export default {
  namespaced: true,
  state: {
    billboard: null,
  },
  mutations: {
    reset(state) {
      state.billboard = null
    },
    setBillboard(state, billboard) {
      state.billboard = billboard
    },
  },
  actions: {
    async storeMedia({ state, dispatch }, { payload, successMessage = null }) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'post',
          endpoint: `/v1/billboards/${state.billboard.id}/media`,
          data: payload,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        { root: true }
      )
      if (res.status == 201 && res.data?.data) {
        if (successMessage) {
          dispatch(
            'app/showAlert',
            { message: successMessage, type: 'success' },
            { root: true }
          )
        }
        return res.data.data
      }

      return null
    },
    async updateMedia({ state, dispatch }, payload) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'put',
          endpoint: `/v1/billboards/${state.billboard.id}/media/${payload.id}`,
          data: payload,
        },
        { root: true }
      )

      return res.status == 200 && res.data?.data ? res.data.data : null
    },
    async deleteMedia({ state, dispatch }, id) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'delete',
          endpoint: `/v1/billboards/${state.billboard.id}/media/${id}`,
        },
        { root: true }
      )

      return res.status == 200
    },
    async reorderMedia({ state, dispatch }, order) {
      const res = await dispatch(
        'app/apiRequest',
        {
          method: 'post',
          endpoint: `/v1/billboards/${state.billboard.id}/media-reorder`,
          data: { order },
        },
        { root: true }
      )

      return res.status == 200
    },
  },
}
