<template>
  <a :href="url" @click="callDynamicMethod" :class="classTemplate">{{ text }}</a>
</template>

<script>
  export default {
    props: {
      url: {
        type: String,
        default: '',
      },
      text: {
        type: String,
        default: 'linkExample',
      },
      classTemplate: {
        type: String,
        default: 'text-hollywood hover:underline font-bold'
      },
      dynamicMethod: {
        type: Function,
        required: true
      },
    },

    methods: {
      callDynamicMethod() {
        this.dynamicMethod()
      },
    },
  }
</script>
