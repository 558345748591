<template>
  <app-layout>
    <section class="py-8 px-4 md:px-6 lg:px-8 xl:px-10 pb-24">
      <div class="mx-auto max-w-[304px] md:max-w-[640px] lg:max-w-[976px]">
        <div class="flex justify-between max-w-full mb-12 col-span-1 md:col-span-2 lg:col-span-3 px-3">
          <h1 class="text-2xl font-bold text-mine-300">All Displays</h1>
          <flat-icon-button class="ml-3" width-class="w-10" height-class="h-10" icon="account" v-tooltip="{
            delay: { show: 1000 },
            content: 'View my Displays',
            placement: 'bottom',
          }" @click="$router.push('/app')" />
        </div>

        <div v-if="loading" class="min-w-[336px] min-h-[258px] flex items-center justify-center">
          <loading-spinner color-classes="from-transparent to-cerise-600" />
        </div>

        <div v-if="!loading">
          <user-displays-panel v-for="(user, index) in users" :key="index" :user="user" :users="users" class="mb-3"
            @display-moved="onDisplayMoved" />
        </div>
      </div>
    </section>
  </app-layout>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {
      loading: true,
      displayToEdit: {},
      settingsModal: false,
      users: [],
    }
  },

  computed: {
    ...mapState('display', {
      displays: (state) => [...state.displays, { type: 'add' }],
    }),
    ...mapGetters('auth', ['isSuperadmin']),

    displaysCount() {
      return this.displays?.filter((e) => !!e.id)?.length
    },
  },

  async created() {
    if (!this.isSuperadmin) return this.$router.push('/app')

    await this.loadUsers()
    this.loading = false
  },

  methods: {
    async loadUsers() {
      const res = await this.$store.dispatch('app/apiRequest', {
        method: 'get',
        endpoint: '/v1/users',
      })
      this.users = res.data.data
    },

    async onDisplayMoved(payload) {
      this.loading = true
      await this.loadUsers()
      this.loading = false
    },
  },
}
</script>
