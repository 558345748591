<template>
  <button
    :class="`${widthClass} ${heightClass} flex-shrink-0 flex items-center justify-center text-white whitespace-nowrap bg-mine-700 rounded-sm transition enabled:hover:bg-mine-600 enabled:active:bg-mine-500 focus:outline-none appearance-none disabled:opacity-50 disabled:cursor-not-allowed `"
    :disabled="disabled"
  >
    <mdi-icon :icon="icon" :size="iconSize" />
  </button>
</template>

<script>
  export default {
    props: {
      icon: {
        type: String,
        required: true,
      },
      iconSize: {
        type: String,
        default: '16',
      },
      widthClass: {
        type: String,
        default: 'w-8',
      },
      heightClass: {
        type: String,
        default: 'h-8',
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
  }
</script>
