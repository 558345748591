<template>
  <div class="relative">
    <button
      @click="() => (active = !active)"
      class="flex items-center justify-between bg-mine-700 rounded-sm z-0 appearance-none w-full focus:outline-none text-sm text-left h-8 py-1 pl-3 pr-1 transition enabled:hover:bg-mine-600 enabled:active:bg-mine-500"
    >
      <span class="whitespace-nowrap overflow-hidden text-ellipsis">{{
        modelValueLabel
      }}</span>
      <mdi-icon icon="chevron-down" class="text-neutral-300" />
    </button>
    <div
      v-show="active"
      v-click-away="() => (active = false)"
      class="min-w-full absolute z-40 bg-mine-800 flex flex-col rounded-sm border border-mine-600 max-h-48 overflow-auto"
    >
      <div
        v-for="(option, index) in options"
        :key="index"
        class="text-mine-300 px-4 py-2 cursor-pointer text-sm hover:bg-mine-700"
        @click="
          () => {
            value = option.value
            active = false
          }
        "
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      modelValue: {
        type: [String, Number, Boolean],
        default: null,
      },
      options: {
        type: Array,
        required: true,
      },
    },
    emits: ['update:modelValue'],
    data() {
      return {
        active: false,
      }
    },
    computed: {
      value: {
        get() {
          return this.modelValue
        },
        set(value) {
          this.$emit('update:modelValue', value)
        },
      },
      modelValueLabel() {
        const option = this.options.find((v) => v.value == this.value)
        return option ? option.label : ''
      },
      modelValueIcon() {
        const option = this.options.find((v) => v.value == this.value)
        return option ? option.icon : null
      },
    },
  }
</script>
