<template>
  <div class="relative">
    <loading-display :loading=display.loading>
      <mdi-icon
        icon="drag-vertical"
        class="absolute left-0 top-0 text-mine-500 handle cursor-grab"
      />
      <div class="pl-7">
        <div class="h-48 w-full flex flex-col rounded bg-mine-900">
          <router-link
            :to="`/app/displays/${display.key}/controller`"
            class="rounded-t relative flex-1 flex items-center justify-center transition hover:ring-cerise hover:rounded-b hover:ring-2"
          >
            <div class="w-20 h-20">
              <loading-image
                v-if="display.event_logo"
                :image-url="display.event_logo"
                size-classes="h-20 w-20"
                background-class="object-contain"
                background-color="bg-mine-900"
              />
            </div>
          </router-link>
          <div class="rounded flex items-center bg-mine-800 p-1">
            <mdi-icon
              icon="circle"
              size="10"
              class="ml-2"
              :class="{
                'text-green': display.is_connected,
                'text-red': !display.is_connected,
              }"
            />
            <div
              class="flex-1 text-sm text-ellipsis overflow-hidden whitespace-nowrap px-3"
            >
              {{ display.name ?? 'New Display' }}
            </div>
            <flat-icon-button
              v-if="canMove"
              v-tooltip="{
                delay: { show: 1000 },
                content: 'Move Display to another account.',
              }"
              class="mr-1"
              icon="arrow-top-right"
              @click="move"
            />
            <flat-icon-button
              v-tooltip="{
                delay: { show: 1000 },
                content: 'Edit Display settings.',
              }"
              icon="pencil"
              @click="edit"
            />
          </div>
        </div>
      </div>
    </loading-display>
  </div>
</template>

<script>
  export default {
    props: {
      display: {
        type: Object,
        required: true,
      },
      canMove: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      edit() {
        this.$emit('edit', this.display)
      },
      move() {
        this.$emit('move', this.display)
      },
    },
  }
</script>
