<template>
  <div class="fixed z-20 inset-0 backdrop-blur flex items-center">
    <app-container class="py-16">
      <div class="max-w-[400px] w-full mx-auto bg-mine-1000 rounded shadow-xl" :class="modalCss">
        <div class="flex justify-between items-center bg-mine-800 p-1">
          <h1 class="text-white text-xs uppercase tracking-widest pl-3 py-2">
            {{ title }}
          </h1>
          <flat-icon-button icon="close" @click="close" v-if="showCancel" />
        </div>
        <div class="px-12 py-6">
          <slot />
        </div>
        <div :class="actionCss">
          <flat-button @click="close" v-if="showCancel">
            {{ closeText }}
          </flat-button>
          <slot name="actions" />
        </div>
      </div>
    </app-container>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: 'Confirm',
      },
      closeText: {
        type: String,
        default: 'Close',
      },
      showCancel: {
        type: Boolean,
        default: true,
      },
      modalCss: {
        type: String,
        default: '',
      },
      actionCss: {
        type: String,
        default: 'flex justify-end px-4 pb-4',
      }
    },
    methods: {
      close() {
        this.$emit('close')
      },
    },
  }
</script>
