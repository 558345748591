<template>
  <div class="fixed z-20 inset-0 backdrop-blur flex items-center">
    <app-container class="py-16">
      <div
        class="max-w-[400px] w-full mx-auto bg-mine-1000 rounded overflow-hidden shadow-xl"
      >
        <div class="flex justify-between items-center bg-mine-800 p-1">
          <h1 class="text-white text-xs uppercase tracking-widest pl-3">
            {{ title }}
          </h1>
          <flat-icon-button icon="close" :disabled="loading" @click="close" />
        </div>
        <div class="px-12 py-6">
          <p class="text-xs text-mine-300 text-center">{{ body }}</p>
          <template v-if="hasKeyword">
            <p class="mt-4 text-xs text-mine-300 text-center">
              Type “{{ keyword }}” to confirm.
            </p>
            <text-field
              v-model="input"
              class="mt-5 text-center !max-w-[400px]"
              placeholder="Type Here"
            />
          </template>
        </div>
        <div class="flex justify-between px-4 pb-4">
          <flat-button @click="close">Cancel</flat-button>
          <flat-button
            :loading="loading"
            :disabled="!canDelete"
            type="primary"
            @click="confirm"
          >
            {{ confirmText }}
          </flat-button>
        </div>
      </div>
    </app-container>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: 'Delete',
      },
      body: {
        type: String,
        default:
          'Are you sure you want to continue? This action cannot be undone.',
      },
      hasKeyword: {
        type: Boolean,
        default: false,
      },
      confirmText: {
        type: String,
        default: 'Delete',
      },
      keyword: {
        type: String,
        default: 'DELETE',
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        input: null,
      }
    },
    computed: {
      canDelete() {
        return !this.hasKeyword || this.input === this.keyword
      },
    },
    methods: {
      close() {
        this.$emit('close')
      },
      confirm() {
        this.$emit('confirmed')
      },
    },
  }
</script>
