<template>
  <div class="flex items-center space-x-4">
    <img v-if="thumb" :src="thumb" class="max-h-16 max-w-[280px] w-auto" />
    <flat-icon-button icon="folder" @click="() => $refs.input.click()" />
    <input ref="input" type="file" class="hidden" @change="onInputChange" />
  </div>
</template>

<script>
  export default {
    props: {
      initialValue: {
        type: [String, null],
        default: null,
      },
    },
    emits: ['updated:value'],
    data() {
      return {
        selectedImage: null,
      }
    },
    computed: {
      thumb() {
        return this.selectedImage ?? this.initialValue ?? null
      },
    },
    methods: {
      onInputChange($event) {
        const image = $event.target.files[0] ?? null

        if (image) {
          if (!image.size || image.size > 26214400) {
            this.$store.dispatch('app/errorAlert', {
              title: 'File too large',
              message: 'Please choose a file under 25MB.',
            })
            return
          }

          if (image.type != 'image/jpeg' && image.type != 'image/png') {
            this.$store.dispatch('app/errorAlert', {
              title: 'Invalid format',
              message: 'Please choose an image in JPG or PNG format.',
            })
            return
          }

          let reader = new FileReader()
          reader.onload = (e) => {
            this.selectedImage = e.target.result
          }
          reader.readAsDataURL(image)
          this.$emit('updated:value', image)
        }
      },
    },
  }
</script>
