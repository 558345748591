<template>
  <div class="flex flex-col items-center text-center justify-center">
    <div
      :class="`${widthClass} ${heightClass} ${bgColor} transition group rounded-sm flex items-center justify-center`"
    >
      <svg
        v-if="type == '3x1Square'"
        :class="class"
        width="50"
        height="16"
        viewBox="0 0 50 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.139282"
          y="0.796387"
          width="14.5218"
          height="14.5218"
          rx="3"
        />
        <rect
          x="17.5653"
          y="0.796387"
          width="14.5218"
          height="14.5218"
          rx="3"
        />
        <rect
          x="34.9915"
          y="0.796387"
          width="14.5218"
          height="14.5218"
          rx="3"
        />
      </svg>

      <svg
        v-if="type == '3x1Portrait'"
        :class="class"
        width="51"
        height="28"
        viewBox="0 0 51 28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.791748"
          y="0.987793"
          width="14.5218"
          height="26.1392"
          rx="3"
        />
        <rect
          x="18.2178"
          y="0.987793"
          width="14.5218"
          height="26.1392"
          rx="3"
        />
        <rect
          x="35.6439"
          y="0.987793"
          width="14.5218"
          height="26.1392"
          rx="3"
        />
      </svg>

      <svg
        v-if="type == '3x1Mixed'"
        :class="class"
        width="50"
        height="28"
        viewBox="0 0 50 28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.655884" y="6.4458" width="14.0538" height="14.9322" rx="3" />
        <rect
          x="17.3448"
          y="0.987793"
          width="14.5218"
          height="26.1392"
          rx="3"
        />
        <rect x="34.9121" y="6.4458" width="14.9322" height="14.9322" rx="3" />
      </svg>

      <svg
        v-if="type == '3x2Square'"
        :class="class"
        width="50"
        height="34"
        viewBox="0 0 50 34"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.139282"
          y="0.932129"
          width="14.5218"
          height="14.5218"
          rx="3"
        />
        <rect
          x="0.139282"
          y="19.0845"
          width="14.5218"
          height="14.5218"
          rx="3"
        />
        <rect
          x="17.5653"
          y="0.932129"
          width="14.5218"
          height="14.5218"
          rx="3"
        />
        <rect x="17.5653" y="19.0845" width="14.5218" height="14.5218" rx="3" />
        <rect
          x="34.9916"
          y="0.932129"
          width="14.5218"
          height="14.5218"
          rx="3"
        />
        <rect x="34.9916" y="19.0845" width="14.5218" height="14.5218" rx="3" />
      </svg>

      <svg
        v-if="type == '3x2Mixed'"
        :class="class"
        width="50"
        height="45"
        viewBox="0 0 50 45"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="18" width="14.5218" height="26.1392" rx="3" />
        <rect x="34.8522" y="18" width="14.5218" height="26.1392" rx="3" />
        <rect width="14.5218" height="14.5218" rx="3" />
        <rect x="34.8522" width="14.5218" height="14.5218" rx="3" />
        <rect x="17.426" y="29" width="14.5218" height="14.5218" rx="3" />
        <rect x="17.426" width="14.5218" height="26.1392" rx="3" />
      </svg>

      <svg
        v-if="type == '4x1Square'"
        :class="class"
        width="66"
        height="14"
        viewBox="0 0 66 14"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="17.1637" width="14" height="14" rx="3" />
        <rect x="0.109863" width="14" height="14" rx="3" />
        <rect x="34.2175" width="14" height="14" rx="3" />
        <rect x="51.2714" width="14" height="14" rx="3" />
      </svg>

      <svg
        v-if="type == '4x1Portrait'"
        :class="class"
        width="67"
        height="27"
        viewBox="0 0 67 27"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="17.1637"
          y="0.896973"
          width="14.0538"
          height="25.4725"
          rx="3"
        />
        <rect
          x="0.109863"
          y="0.896973"
          width="14.0538"
          height="25.4725"
          rx="3"
        />
        <rect
          x="34.2175"
          y="0.896973"
          width="14.0538"
          height="25.4725"
          rx="3"
        />
        <rect
          x="51.2714"
          y="0.896973"
          width="14.9322"
          height="25.4725"
          rx="3"
        />
      </svg>

      <svg
        v-if="type == '5x1Square'"
        :class="class"
        width="85"
        height="16"
        viewBox="0 0 85 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="18.1393"
          y="0.899414"
          width="14.5218"
          height="14.5218"
          rx="3"
        />
        <rect
          x="0.713135"
          y="0.899414"
          width="14.5218"
          height="14.5218"
          rx="3"
        />
        <rect
          x="35.5653"
          y="0.899414"
          width="14.5218"
          height="14.5218"
          rx="3"
        />
        <rect
          x="52.9915"
          y="0.899414"
          width="14.5218"
          height="14.5218"
          rx="3"
        />
        <rect
          x="70.4176"
          y="0.899414"
          width="14.5218"
          height="14.5218"
          rx="3"
        />
      </svg>

      <svg
        v-if="type == '5x1Mixed'"
        :class="class"
        width="82"
        height="26"
        viewBox="0 0 82 26"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="17" y="6" width="14" height="13.0667" rx="3" />
        <rect width="14" height="26" rx="3" />
        <rect x="34" width="14" height="26" rx="3" />
        <rect x="51" y="6" width="14" height="13.0667" rx="3" />
        <rect x="68" width="14" height="26" rx="3" />
      </svg>

      <svg
        v-if="type == 'Hero / CTA'"
        :class="class"
        width="46"
        height="26"
        viewBox="0 0 46 26"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="46" height="26" rx="2" />
      </svg>
      <svg
        v-if="type == 'Moderation'"
        :class="class"
        width="41"
        height="26"
        viewBox="0 0 41 26"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="10.876" height="26" rx="2" />
        <rect x="15" width="10.876" height="26" rx="2" />
        <rect x="29.8154" width="10.876" height="26" rx="2" />
      </svg>
      <svg
        v-if="type == 'Highlight Playlist'"
        :class="class"
        width="53"
        height="35"
        viewBox="0 0 53 35"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.922 0.674478L1.42199 8.0707C0.577688 8.32559 0 9.10342 0 9.98536V26.4597C0 27.3675 0.611474 28.1615 1.48922 28.3934L25.9892 34.8651C26.324 34.9535 26.676 34.9535 27.0108 34.8651L51.5108 28.3934C52.3885 28.1615 53 27.3675 53 26.4597V9.98536C53 9.10342 52.4223 8.32559 51.578 8.0707L27.078 0.674478C26.7011 0.560685 26.2989 0.560685 25.922 0.674478Z"
        />
      </svg>
      <svg
        v-if="type == 'Highlight Post'"
        :class="class"
        width="56"
        height="26"
        viewBox="0 0 56 26"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.175849 2.77928C-0.382051 1.46061 0.58595 0 2.01778 0H44.6745C45.478 0 46.2034 0.480781 46.5165 1.22072L55.8242 23.2207C56.382 24.5394 55.4141 26 53.9822 26H11.3255C10.522 26 9.79659 25.5192 9.48354 24.7793L0.175849 2.77928Z"
        />
      </svg>
    </div>

    <div
      class="text-xs text-mine-300 text-center mt-2"
      :class="{ '!text-cerise': active }"
    >
      {{ type }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        required: true,
      },
      active: {
        type: Boolean,
        default: false,
      },
      widthClass: {
        type: String,
        default: 'w-24',
      },
      heightClass: {
        type: String,
        default: 'h-14',
      },
    },
    computed: {
      class() {
        return this.active
          ? 'fill-cerise-600'
          : 'fill-mine-500 transition group-hover:fill-mine-400'
      },
      bgColor() {
        return this.active
          ? 'bg-cerise-500/10'
          : 'bg-mine-700 hover:bg-mine-600'
      },
    },
  }
</script>
