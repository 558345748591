<template>
  <div class="relative">
    <mdi-icon
      icon="help-circle-outline"
      class="text-white cursor-pointer hover:text-mine-200 h-6 w-6"
      @click="showHelp = !showHelp"
    />
    <Transition>
      <div
        v-if="showHelp"
        v-click-away="() => (showHelp = false)"
        class="fixed sm:absolute z-50 right-0 bg-mine-800 border border-mine-600 rounded-md shadow-md p-8 grid grid-cols-3 gap-4 min-w-max"
      >
        <div class="text-mine-400 text-xs text-right">HELP</div>
        <div class="col-span-2 flex flex-col space-y-2 pb-3">
          <a
            href="https://theexperiential.notion.site/Video-Tutorials-0ae1933ebd414386bdd007665ec08c87"
            target="_blank"
            class="whitespace-nowrap text-xs text-hollywood hover:underline"
            >Tutorials</a
          >
          <a
            href="https://theexperiential.notion.site/Documentation-8531e172d9bd4b1992d79b3483a8278b"
            target="_blank"
            class="whitespace-nowrap text-xs text-hollywood hover:underline"
            >Documentation</a
          >
        </div>
        <div class="text-mine-400 text-xs text-right">VERSION</div>
        <div class="col-span-2 flex flex-col space-y-2">
          <span class="text-xs">
            {{ version ?? '?' }}
          </span>
          <a
            :href="releaseNotes"
            target="_blank"
            class="text-xs text-hollywood hover:underline"
            >Release Notes</a
          >
        </div>
      </div>
    </Transition>
  </div>

  <div class="relative">
    <mdi-icon
      icon="apps"
      class="text-white cursor-pointer hover:text-mine-200"
      @click="showApps = !showApps"
    />
    <Transition>
      <div
        v-if="showApps"
        v-click-away="() => (showApps = false)"
        class="fixed sm:absolute z-50 right-0 bg-mine-800 border border-mine-600 rounded-md shadow-md p-8 grid grid-cols-3 gap-x-6 gap-y-8 min-w-max"
      >
        <div class="text-mine-400 text-xs text-right self-center">
          VIXI UNREAL<br />DESKTOP APP
        </div>
        <div class="col-span-2 flex flex-col space-y-2">
          <a
            :href="`https://vixi-social-ue.s3.amazonaws.com/Builds/${ueVersion}.zip`"
            class="text-xs text-hollywood hover:underline font-bold"
          >
            Download v{{ ueVersion }}
          </a>
          <a
            href="https://theexperiential.notion.site/Vixi-Social-UE-App-Changelog-0b6f4ca866254a22a4748bde0e1de34c"
            class="text-xs text-hollywood hover:underline"
            target="_blank"
          >
            Release Notes
          </a>
          <a
            href="https://theexperiential.notion.site/Previous-Versions-b36069169f6c4d39bfc966fb888bceb9?pvs=4"
            class="text-xs text-hollywood hover:underline"
            target="_blank"
          >
            Previous Versions
          </a>
        </div>
        <div class="text-mine-400 text-xs text-right self-center">
          TAGBOARD<br />APPS
        </div>
        <div class="col-span-2 flex flex-col space-y-2">
          <a
            href="https://account.tagboard.com"
            class="group flex items-center space-x-2"
            target="_blank"
          >
            <img
              src="/img/tgb_builders_logo.svg"
              alt="Tagboard Account"
              class="h-10 w-auto"
            />
            <span
              class="text-tagboard text-xs !tracking-[0.2em] group-hover:underline"
              >ACCOUNT</span
            >
          </a>
          <a
            href="https://producer.tagboard.com"
            class="group flex items-center space-x-2"
            target="_blank"
          >
            <img
              src="/img/tgb_producer_logo.svg"
              alt="Tagboard Producer"
              class="h-10 w-auto"
            />
            <span
              class="text-tagboard text-xs !tracking-[0.2em] group-hover:underline"
              >PRODUCER</span
            >
          </a>
          <a
            href="https://composer.tagboard.com"
            class="group flex items-center space-x-2"
            target="_blank"
          >
            <img
              src="/img/tgb_composer_logo.svg"
              alt="Tagboard Composer"
              class="h-10 w-auto"
            />
            <span
              class="text-tagboard text-xs !tracking-[0.2em] group-hover:underline"
              >COMPOSER</span
            >
          </a>
        </div>
        <div class="text-mine-400 text-xs text-right">
          CHROME<br />EXTENSION
        </div>
        <div class="col-span-2 flex flex-col space-y-2 self-center">
          <a
            href="https://chrome.google.com/webstore/detail/tagboard-curate/agmolhhbbencbnegdoifnnobajpemfan"
            class="text-tagboard text-xs !tracking-[0.2em] hover:underline"
            target="_blank"
            >TAGBOARD CURATE</a
          >
        </div>
      </div>
    </Transition>
  </div>

</template>

<script>
const { VITE_UE_VERSION, VITE_RELEASE_NOTES } = import.meta.env
  export default {
    data() {
      return {
        showHelp: false,
        showApps: false,
        ueVersion: VITE_UE_VERSION,
        releaseNotes: VITE_RELEASE_NOTES,
      }
    },
    computed: {
      version() {
        return __APP_VERSION__
      },
    }
  }
</script>