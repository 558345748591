<template>
  <div class="fixed z-20 inset-0 backdrop-blur flex items-center">
    <app-container class="py-16">
      <div
        class="max-w-[400px] w-full mx-auto bg-mine-1000 rounded overflow-hidden shadow-xl"
      >
        <div class="flex justify-between items-center bg-mine-800 p-1">
          <h1 class="text-white text-xs uppercase tracking-widest pl-3">
            {{ title }}
          </h1>
          <flat-icon-button icon="close" :disabled="loading" @click="close" />
        </div>
        <div class="px-12 py-6">
          <p class="text-xs text-mine-300 text-center">{{ body }}</p>
        </div>
        <div class="flex justify-between px-4 pb-4">
          <flat-button @click="close">Cancel</flat-button>
          <flat-button :loading="loading" @click="confirm">
            {{ confirmText }}
          </flat-button>
        </div>
      </div>
    </app-container>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: 'Confirm',
      },
      body: {
        type: String,
        default: 'Please confirm',
      },
      confirmText: {
        type: String,
        default: 'Confirm',
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        input: null,
      }
    },
    methods: {
      close() {
        this.$emit('close')
      },
      confirm() {
        this.$emit('confirmed')
      },
    },
  }
</script>
